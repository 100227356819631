export enum Types {
	CADASTRAR_FUNCIONALIDADE = '@SGU/CADASTRAR_FUNCIONALIDADE',
	CADASTRAR_FUNCIONALIDADE_SUCCESS = '@SGU/CADASTRAR_FUNCIONALIDADE_SUCCESS',
	CADASTRAR_FUNCIONALIDADE_FAILURE = '@SGU/CADASTRAR_FUNCIONALIDADE_FAILURE',
	CADASTRAR_FUNCIONALIDADE_CLEAR = '@SGU/CADASTRAR_FUNCIONALIDADE_CLEAR',
}

export interface CadastrarFuncionalidade {
	status: number;
	data: null | FuncionalidadeData;
}

interface FuncionalidadeData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarFuncionalidadeRequest {
	id?: number | string;
	descricao: string;
	localidades?: string[] | null;
	idUsuarioAutenticado?: number;
}
