import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarMenusRequest } from './types';

// ACTIONS
import { atualizarMenusSuccess, atualizarMenusFailure } from './action';

function* atualizarMenus(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: AtualizarMenusRequest } =
		request;

	const body = { ...payload };

	delete body.idMenu;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`menu/${payload.idMenu}`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(atualizarMenusSuccess(response));
	} else if (response.error) {
		yield put(atualizarMenusFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_MENUS, atualizarMenus)]);
