import { takeLatest, put, all, call } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerActionRotaSP } from 'store/modules/types';

// ACTIONS
import {
	atualizarAtendenteSuccess,
	atualizarAtendenteFailure,
} from './actions';

// TYPES
import { Types, RequestAtualizarAtendente } from './types';
import { ConsultaUsuarioPendente } from '../consultaUsuarioPendente/types';

function* atualizarAtendenteRequest(request: ReducerActionRotaSP) {
	const { payload } = request;

	const {
		atualizacao,
		resultado,
	}: {
		atualizacao: RequestAtualizarAtendente;
		resultado: ConsultaUsuarioPendente[];
	} = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/atualiza-atendente`,
		atualizacao,
	);

	if (response.status === 200) {
		yield put(atualizarAtendenteSuccess(response.data, resultado));
	} else if (response.error) {
		yield put(atualizarAtendenteFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_ATENDENTE, atualizarAtendenteRequest),
]);
