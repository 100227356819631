import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarMotivoRequest } from './types';

export function atualizarMotivoRequest(
	token: string,
	data: AtualizarMotivoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MOTIVO,
		token,
		payload: data,
	};
}
export function atualizarMotivoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MOTIVO_SUCCESS,
		payload,
	};
}
export function atualizarMotivoFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function atualizarMotivoClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MOTIVO_CLEAR,
		payload: null,
	};
}
