import { takeLatest, put, all } from 'redux-saga/effects';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { SalvarAtendimentoRequest, Types } from './types';

// ACTIONS
import { salvarAtendimentoSuccess } from './actions';
import { incluirEstatisticasSalvarAtendimentoRequest } from '../../estatisticas/incluir/actions';

// UTILS
import {
	treatIncluirEstatisticasSguRequest,
	treatResponseSalvarAtendimento,
} from './utils';

function* salvarAtendimentoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { payload: SalvarAtendimentoRequest; token?: string } = request;

	const responseData = treatResponseSalvarAtendimento(payload);
	const incluirEstatisticasPayload = treatIncluirEstatisticasSguRequest(
		payload,
		responseData,
	);

	incluirEstatisticasSalvarAtendimentoRequest(
		token || '',
		incluirEstatisticasPayload,
	);

	yield put(salvarAtendimentoSuccess({ ...responseData }));
}

export default all([
	takeLatest(Types.SALVAR_ATENDIMENTO_REQUEST, salvarAtendimentoRequest),
]);
