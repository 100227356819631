import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarPerfisRequest } from './types';

export function consultarPerfilsRequest(
	token: string,
	data: ConsultarPerfisRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PERFIS_REQUEST,
		token,
		payload: data,
	};
}
export function consultarPerfilsSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PERFIS_SUCCESS,
		payload,
	};
}
export function consultarPerfilsFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PERFIS_FAILURE,
		payload: null,
	};
}
export function consultarPerfilsClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PERFIS_CLEAR,
		payload: null,
	};
}
