import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, IRequestWithHeaderLocalidadeDelete } from './types';

export function excluirLocalidadeRequest(
	token: string,
	payload: IRequestWithHeaderLocalidadeDelete,
) {
	return {
		type: Types.EXCLUIR_LOCALIDADE,
		token,
		payload,
	};
}
export function excluirLocalidadeSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function excluirLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_LOCALIDADE_CLEAR,
		payload: null,
	};
}
