import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMensagemErroRequest } from './types';

export function cadastrarMensagemErroRequest(
	token: string,
	data: CadastrarMensagemErroRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_REQUEST,
		token,
		payload: data,
	};
}
export function cadastrarMensagemErroSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function cadastrarMensagemErroFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function cadastrarMensagemErroClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
