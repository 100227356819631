import produce from 'immer';

// TYPES
import { Types, ConsultarMenu } from './types';
import { ReducerActionRotaSP } from '../../../../../types';

export const INITIAL_STATE: ConsultarMenu = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): ConsultarMenu {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MENU: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTAR_MENU_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_MENU_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_MENU_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
