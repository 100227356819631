import { all } from 'redux-saga/effects';

import consultaAtestadoNominal from './consultaAtestadoNominal/saga';
import consultaAtestadoNumeral from './emissaoAtestadoAntecedentes/saga';
import emissaoNominal from './emissaoNominal/saga';
import emissaoNumeral from './emissaoNumeral/saga';

// PAINEL DE CONTROLE
import consultaUsuarioPendente from './novoPainelDeControle/consultaUsuarioPendente/saga';
import atualizarAtendente from './novoPainelDeControle/atualizarAtendente/saga';
import emissaoAtestado from './novoPainelDeControle/emissaoAtestadoNormal/saga';
import emissaoExigencias from './novoPainelDeControle/emissaoExigencias/saga';
import emissaoAtestados from './novoPainelDeControle/emissaoAtestados/saga';
import encerrarAtendimento from './novoPainelDeControle/encerrarAtendimento/saga';
import emissaoAtestadoLiberacaoPositiva from './novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/saga';

import painelDeControle from './painelDeControle/saga';
import pesquisaCITA from './pesquisaCITA/saga';

import enviarCentralNominal from './enviarCentralNominal/saga';
import enviarCentralNumeral from './enviarCentralNumeral/saga';

import consultaCpf from './consutaCpf/saga';

export default all([
	consultaCpf,
	consultaAtestadoNominal,
	consultaAtestadoNumeral,
	emissaoNominal,
	emissaoNumeral,
	consultaUsuarioPendente,
	atualizarAtendente,
	emissaoAtestado,
	emissaoExigencias,
	emissaoAtestados,
	encerrarAtendimento,
	painelDeControle,
	pesquisaCITA,
	enviarCentralNominal,
	enviarCentralNumeral,
	emissaoAtestadoLiberacaoPositiva,
]);
