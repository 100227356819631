import produce from 'immer';

// TYPES
import { Types, ConsultarSufixos } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ConsultarSufixos = {
	status: 0,
	data: null,
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarSufixos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_SUFIXO: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_SUFIXO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}

			case Types.CONSULTAR_SUFIXO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_SUFIXO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
