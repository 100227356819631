import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IncluirEstatisticas } from './types';

export const INITIAL_STATE: IncluirEstatisticas = {
	status: 0,
	data: null,
	dataFailure: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IncluirEstatisticas {
	return produce(state, draft => {
		switch (action.type) {
			case Types.INCLUIR_ESTATISTICAS_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.INCLUIR_ESTATISTICAS_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.INCLUIR_ESTATISTICAS_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.dataFailure = action.payload;
				break;
			}
			case Types.INCLUIR_ESTATISTICAS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataFailure = INITIAL_STATE.dataFailure;
				break;
			}

			default:
		}
		return draft;
	});
}
