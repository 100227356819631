export enum Types {
	ATUALIZAR_STATUS = '@SGU/ATUALIZAR_STATUS',
	ATUALIZAR_STATUS_SUCCESS = '@SGU/ATUALIZAR_STATUS_SUCCESS',
	ATUALIZAR_STATUS_FAILURE = '@SGU/ATUALIZAR_STATUS_FAILURE',
	ATUALIZAR_STATUS_CLEAR = '@SGU/ATUALIZAR_STATUS_CLEAR',
}

export interface AtualizarStatus {
	status: number;
	data: null | AtualizarStatusData;
}

export interface AtualizarStatusData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarStatusRequest {
	id?: number | string;
	descricao: string;
}
