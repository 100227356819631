import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMensagemRequest } from './types';

export function cadastrarMensagemRequest(
	token: string,
	data: CadastrarMensagemRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_REQUEST,
		token,
		payload: data,
	};
}
export function cadastrarMensagemSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function cadastrarMensagemFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function cadastrarMensagemClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENSAGEM_CLEAR,
		payload: null,
	};
}
