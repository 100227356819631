import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarOrgaoRequest } from './types';

export function atualizarOrgaoRequest(
	token: string,
	data: AtualizarOrgaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ORGAO,
		token,
		payload: data,
	};
}
export function atualizarOrgaoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ORGAO_SUCCESS,
		payload,
	};
}
export function atualizarOrgaoFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ORGAO_FAILURE,
		payload: null,
	};
}
export function atualizarOrgaoClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ORGAO_CLEAR,
		payload: null,
	};
}
