import { Row, Col } from 'antd';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import { impressaoTermoPaternidadeClear } from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import { limpaPreCadastroRequest } from 'store/modules/api/mp/preCadastro/actions';
import { uploadArquivoClear } from 'store/modules/api/utils/uploadArquivo/actions';

import {
	eventosPPTContabilizacaoClear,
	eventosPPTContabilizacaoRequest,
} from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { solicitarReconhecimentoPaternidadeRequest } from 'store/modules/api/ministerioPublico/solicitarReconhecimentoPaternidade/actions';
import { consultarDetalheSolicitacaoRequest } from 'store/modules/api/ministerioPublico/consultarDetalheSolicitacao/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import PDFViewer from 'components/Common/PDFViewer';
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { treatRequestStartSessionEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';

import { Container, Box } from './styled';
import {
	initialValues,
	ICadastroSolicitante,
	schemaInteressadoFilho,
	schemaInteressadoGenitor,
	schemaInteressadoRespLegal,
} from './Forms/form';
import CadastroSteps from './Steps';
import Selections from './Selections';

// FORMS
import DadosInteressado from './Forms/DadosInteressado';
import DadosGenitor from './Forms/DadosGenitor';
import Termos from './Forms/Termos';
import Documentos from './Forms/UploadDocumentos';
import DadosResponsavel from './Forms/DadosResponsavel';
import Detalhes from './Forms/Detalhes';
import Confirmacao from './Confirmacao';
import {
	treatEnviarSolicitacoesPortalRequest,
	treatInitialFormValues,
} from './form';

const NovoCadastro: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [activeStep, setActiveStep] = useState<number>(0);
	const [solicitante, setSolicitante] = useState<number>(0);
	const [descSolicitante, setDescSolicitante] = useState<string>('');
	const [formValues, setFormValues] = useState(initialValues);
	const [formAlreadySentToMp, setFormAlreadySentToMp] = useState(false);
	const [awaitingFormDataConfirmation, setAwaitingFormDataConfirmation] =
		useState(false);

	const { preCadastro, impressaoTermoPaternidade } = useSelector(
		(state: ApplicationState) => state.api.mp,
	);

	const {
		consultarAndamentoSolicitacoes,
		consultarDetalheSolicitacao,
		solicitarReconhecimentoPaternidade,
	} = useSelector((state: ApplicationState) => state.api.ministerioPublico);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { user } = loginUnico;

	useEffect(() => {
		if (solicitante !== 0 && solicitarReconhecimentoPaternidade.status === 0) {
			setActiveStep(1);
		} else if (
			solicitante === 0 &&
			solicitarReconhecimentoPaternidade.status === 0
		) {
			setActiveStep(0);
		}

		if (preCadastro?.data?.numeroMP !== '') {
			const formValuesTreated = treatInitialFormValues(
				preCadastro.data,
				solicitante,
			);

			setFormValues(formValuesTreated);
		}

		switch (solicitante) {
			case 1: {
				setDescSolicitante('Filho ou Filha');
				break;
			}
			case 3: {
				setDescSolicitante('Suposto Genitor');
				break;
			}
			case 2: {
				setDescSolicitante('Responsável Legal');
				break;
			}
			default:
		}

		if (
			consultarDetalheSolicitacao.status === 200 &&
			solicitarReconhecimentoPaternidade.status === 0
		) {
			setActiveStep(1);

			setFormAlreadySentToMp(true);
		}
	}, [
		solicitante,
		consultarDetalheSolicitacao,
		preCadastro,
		dispatch,
		solicitarReconhecimentoPaternidade.status,
	]);

	useEffect(() => {
		if (
			(solicitarReconhecimentoPaternidade.status === 200 ||
				solicitarReconhecimentoPaternidade.status === 201) &&
			solicitarReconhecimentoPaternidade.request
		) {
			let cpfDaConsulta = '';
			if (solicitante === 1) {
				cpfDaConsulta =
					solicitarReconhecimentoPaternidade.request.filho?.cpf?.numero.toString() ||
					'';
			} else if (solicitante === 2) {
				cpfDaConsulta =
					solicitarReconhecimentoPaternidade.request.responsavelLegal?.cpf?.numero.toString() ||
					'';
			} else if (solicitante === 3) {
				cpfDaConsulta =
					solicitarReconhecimentoPaternidade.request.supostoGenitor?.cpf?.numero.toString() ||
					'';
			}
			const resquestConsultaDetalheSolicitacao = {
				cpfUsuario: user.cpf,
				cpfCidadao: cpfDaConsulta,
				idSolicitacao:
					solicitarReconhecimentoPaternidade.data?.idSolicitacao || '',
			};
			dispatch(
				consultarDetalheSolicitacaoRequest(
					token,
					resquestConsultaDetalheSolicitacao,
				),
			);
			setActiveStep(3);

			// faz o redirecionamento ou mudança de bloco
		}
	}, [
		dispatch,
		solicitante,
		solicitarReconhecimentoPaternidade.data,
		solicitarReconhecimentoPaternidade.request,
		solicitarReconhecimentoPaternidade.status,
		token,
		user.cpf,
	]);

	const handleSubmit = useCallback(
		async (values: ICadastroSolicitante) => {
			const treatValuesSolicitacao = treatEnviarSolicitacoesPortalRequest(
				values,
				user.cpf,
				descSolicitante,
			);
			dispatch(
				solicitarReconhecimentoPaternidadeRequest(
					token,
					treatValuesSolicitacao,
				),
			);

			window.scrollTo(0, 0);
		},
		[descSolicitante, dispatch, token, user.cpf],
	);

	const handleStepPages = useCallback(() => {
		setActiveStep(activeStep - 1);

		window.scrollTo(0, 0);
		dispatch(eventosPPTContabilizacaoClear());
		dispatch(uploadArquivoClear());
		if (preCadastro.data.numeroMP !== '' && activeStep === 1) {
			setSolicitante(0);
			history.push('/ministerio-publico');
		} else if (activeStep === 1 && !awaitingFormDataConfirmation) {
			dispatch(limpaPreCadastroRequest());
			setSolicitante(0);
		} else if (activeStep === 0 || activeStep === 3) {
			history.push('/ministerio-publico/reconhecimento-de-paternidade');
		}
	}, [
		activeStep,
		dispatch,
		preCadastro.data.numeroMP,
		awaitingFormDataConfirmation,
		history,
	]);

	const handleBackToFixFormData = () => {
		dispatch(clearNotifications());
		dispatch(eventosPPTContabilizacaoClear());
		dispatch(uploadArquivoClear());
		setActiveStep(1);

		setAwaitingFormDataConfirmation(false);
		window.scrollTo(0, 0);
	};

	const handleToConfirmFormData = () => {
		setActiveStep(2);

		setAwaitingFormDataConfirmation(true);
		window.scrollTo(0, 0);
	};

	const registerContabilizacaoStartEventPPT = useCallback(() => {
		if (eventosPPTContabilizacao.status === 0) {
			const treatedEventsContabilizacaoPPTStartSessionRequest =
				treatRequestStartSessionEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					'',
					'MPSP',
					549,
					'inicio_MP',
					'inicio_MP',
				);
			dispatch(
				eventosPPTContabilizacaoRequest(
					token,
					treatedEventsContabilizacaoPPTStartSessionRequest,
				),
			);
		}
	}, [
		atendimento,
		dispatch,
		eventosPPTContabilizacao.status,
		loginUnico,
		token,
	]);

	const handleChooseSolicitante = (solicitanteChoosed: number) => {
		registerContabilizacaoStartEventPPT();
		setSolicitante(solicitanteChoosed);
	};

	const schemaByTypeOfRequest = (values: ICadastroSolicitante) => {
		switch (solicitante) {
			case 1:
				return getValidationsErrors(values, schemaInteressadoFilho);
			case 2:
				return getValidationsErrors(values, schemaInteressadoRespLegal);
			case 3:
				return getValidationsErrors(values, schemaInteressadoGenitor);
			default:
				return getValidationsErrors(values, false);
		}
	};

	return (
		<Container>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formValues}
				validate={values => schemaByTypeOfRequest(values)}
				onSubmit={() => {
					handleToConfirmFormData();
				}}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							<Section title="Cadastro">
								{formAlreadySentToMp &&
									consultarDetalheSolicitacao.data?.andamentoDaSolicitacao
										.situacaoAtual && (
										<>
											<Alert
												type="success"
												message="Status: Enviado"
												displayOnClose={false}
											/>
											<Alert
												type="info"
												message={`Situação Atual: ${consultarDetalheSolicitacao.data?.andamentoDaSolicitacao.situacaoAtual}`}
												displayOnClose={false}
											/>
										</>
									)}
								{activeStep === 3 && (
									<>
										<Alert
											type="success"
											message="Sua solicitação de reconhecimento de paternidade foi enviada."
											displayOnClose={false}
										/>
										<Alert
											type="info"
											message="O prazo para a identificacão da promotoria responsável pelo caso é de 60 dias após a abertura do pedido."
											displayOnClose={false}
										/>
									</>
								)}

								<CadastroSteps currentStep={activeStep} />
								{/* STEP 1 */}
								{consultarAndamentoSolicitacoes.status === 0 &&
									solicitante === 0 &&
									activeStep === 0 && (
										<>
											<Selections
												formik={formik}
												solicitante={(solicitanteChoosed: number) =>
													handleChooseSolicitante(solicitanteChoosed)
												}
											/>
										</>
									)}
								{solicitante !== 0 && activeStep !== 3 && (
									<>
										<Box>
											<strong> Solicitante: </strong>
											<span>{descSolicitante}</span>
										</Box>
									</>
								)}
								{/* STEP 2 e 3 */}
								{(activeStep === 1 || activeStep === 2) && (
									<>
										<Col span={24}>
											<DadosInteressado
												formik={formik}
												step={activeStep}
												formDisabled={
													formAlreadySentToMp || awaitingFormDataConfirmation
												}
											/>
											<DadosGenitor
												formik={formik}
												step={activeStep}
												formDisabled={
													formAlreadySentToMp || awaitingFormDataConfirmation
												}
											/>
											<DadosResponsavel
												formik={formik}
												step={activeStep}
												formDisabled={
													formAlreadySentToMp || awaitingFormDataConfirmation
												}
											/>
											{/* )} */}
											{(preCadastro.data.numeroMP ||
												formAlreadySentToMp ||
												awaitingFormDataConfirmation) && (
												<Detalhes
													formik={formik}
													step={activeStep}
													formDisabled={
														formAlreadySentToMp || awaitingFormDataConfirmation
													}
												/>
											)}
											{!formAlreadySentToMp && (
												<>
													<Termos
														formik={formik}
														step={activeStep}
														formDisabled={
															formAlreadySentToMp ||
															awaitingFormDataConfirmation
														}
													/>

													<Documentos
														formik={formik}
														step={activeStep}
														formDisabled={formAlreadySentToMp}
														descSolicitante={descSolicitante}
													/>
												</>
											)}
										</Col>
									</>
								)}
								{/* STEP 4 */}
								{activeStep === 3 && <Confirmacao />}
							</Section>

							{/* SUBMIT STEP 2 --> Confirmacao dos dados */}
							{solicitante !== 0 && activeStep === 1 && (
								<Row justify="center" style={{ margin: '20px 0' }}>
									<ButtonImage
										type="submit"
										src="avancar"
										disabled={formAlreadySentToMp}
									/>
								</Row>
							)}
							{/* SUBMIT STEP 3 --> Envia ao MP */}
							{solicitante !== 0 && activeStep === 2 && (
								<Row justify="center" style={{ margin: '20px 0' }}>
									<ButtonImage
										type="button"
										src="gerar-protocolo"
										onClick={() => {
											handleSubmit(formik.values);
										}}
										disabled={formAlreadySentToMp}
									/>
								</Row>
							)}
						</Form>
						{activeStep === 2 && (
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									handleBackToFixFormData();
								}}
							/>
						)}
						{activeStep !== 2 && (
							<ButtonVoltar
								navigate={preCadastro.status !== 200}
								route="/ministerio-publico"
								onClick={() => {
									handleStepPages();
									formik.handleReset();
									dispatch(clearNotifications());
								}}
							/>
						)}
					</>
				)}
			</Formik>
			{impressaoTermoPaternidade.data.arquivoPDF !== '' && (
				<PDFViewer
					title="Termo de Paternidade"
					source={impressaoTermoPaternidade.data.arquivoPDF}
					renderMode="popup"
					onClose={() => dispatch(impressaoTermoPaternidadeClear())}
				/>
			)}
		</Container>
	);
};

export default NovoCadastro;
