export enum Types {
	CONSULTAR_MUNICIPIOS_IBGE_REQUEST = '@SGU/CONSULTAR_MUNICIPIOS_IBGE_REQUEST',
	CONSULTAR_MUNICIPIOS_IBGE_SUCCESS = '@SGU/CONSULTAR_MUNICIPIOS_IBGE_SUCCESS',
	CONSULTAR_MUNICIPIOS_IBGE_FAILURE = '@SGU/CONSULTAR_MUNICIPIOS_IBGE_FAILURE',
	CONSULTAR_MUNICIPIOS_IBGE_CLEAR = '@SGU/CONSULTAR_MUNICIPIOS_IBGE_CLEAR',
}

export interface ConsultarMunicipiosIbge {
	status: number;
	data: null | MunicipiosIbge[];
	totalRegistrosConsulta: number;
}

export interface MunicipiosIbge {
	idNaturalidade: number;
	idIbge: number;
	uf: string | number;
	descricao: string;
}

export interface ConsultarMunicipiosIbgeRequest {
	page?: number;
	size?: number;
	idNaturalidade?: number;
	idIbge?: number;
	uf?: string | number;
	descricao?: string;
}

export interface ConsultarMunicipiosIbgeOdRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	idNaturalidade?: string | number;
	idIbge?: string | number;
	descricao?: string;
	uf?: string | number;
}
