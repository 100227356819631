import { all } from 'redux-saga/effects';

import solicitarReconhecimentoPaternidade from './solicitarReconhecimentoPaternidade/saga';
import consultarAndamentoSolicitacoes from './consultarAndamentoSolicitacoes/saga';
import consultarDetalheSolicitacao from './consultarDetalheSolicitacao/saga';

export default all([
	solicitarReconhecimentoPaternidade,
	consultarAndamentoSolicitacoes,
	consultarDetalheSolicitacao,
]);
