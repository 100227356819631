import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { RequestConsultarMenu, Types } from './types';

// ACTIONS
import { consultarMenuSuccess, consultarMenuFailure } from './action';

function* consultarMenu(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: RequestConsultarMenu } =
		request;
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`menu/${payload.id}`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMenuSuccess(response));
	} else if (response.error) {
		yield put(consultarMenuFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_MENU, consultarMenu)]);
