import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
// import {
// 	consultarCinClear,
// 	consultarCinRequest,
// } from 'store/modules/api/rfb/consultarCin/actions';
import {
	consultarCpfCinClear,
	consultarCpfCinRequest,
} from 'store/modules/api/iirgd/integrador/consultarCin/actions';

// COMPONENTS
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import Section from 'components/Common/Section';
// import CheckBox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import {
	salvarAtendimentoClear,
	salvarAtendimentoRequest,
} from 'store/modules/api/sgu/atendimento/salvar/actions';
import hasError from 'utils/getFormErrors';
import { IFormConsultarCpf, initialValues, schema, treatValues } from './form';
import ResultadoCpf from '../Resultado';

const ConsultarCpf: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { token } = useContext<IAuthContext>(AuthContext);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const userLogin = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { consultarCin } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const [novoAtendimento, setNovoAtendimento] = useState<boolean>(false);

	const [cpfValue, setCpfValue] = useState<string>('');

	useEffect(() => {
		if (salvarAtendimento.status === 0 && !novoAtendimento) {
			setNovoAtendimento(true);
			dispatch(
				salvarAtendimentoRequest(token, {
					cpfUsuario: login.user.cpf,
					motivoJustificativa: 'CIDADAO_SEM_SENHA',
					justificativa: 'SERVIÇO NÃO POSSUI RECUPERAÇÃO DE ATENDIMENTO',
					codLocalidade: login.user.idLocal,
					situacao: 'SEM_DADOS',
					descricaoServico: '',
					atendimento: {
						idCidadao: null,
						idAtendimento: null,
						cpf: null,
						msg: '',
					},
					orgao: {
						id: login.user.idOrgao ? login.user.idOrgao.toString() : '',
						nome: 'IIRGD',
					},
					userIdLogin: Number(userLogin.idUsuario) || 0,
				}),
			);
		}
	}, [
		dispatch,
		login.user.cpf,
		login.user.idLocal,
		login.user.idOrgao,
		novoAtendimento,
		salvarAtendimento.status,
		token,
		userLogin.idUsuario,
	]);

	useEffect(() => {
		if (consultarCin.status === 204 || consultarCin.status === 400) {
			addNotifications({
				errors: [`CPF ${cpfValue} não cadastrado`],
			});
		} else if (consultarCin.status !== 0) {
			setNovoAtendimento(false);
			dispatch(salvarAtendimentoClear());
		}
	}, [consultarCin.status, cpfValue, dispatch]);

	// useEffect(() => {
	// 	if (consultarCin.data?.codRetorno === '500') {
	// 		addNotifications({
	// 			errors: ['Dados não consta na base da Receita Federal'],
	// 		});
	// 	}
	// }, [consultarCin.data]);

	const handleValidate = useCallback((formValues: IFormConsultarCpf) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IFormConsultarCpf) => {
			const values = treatValues(formValues, login, salvarAtendimento);

			setCpfValue(values.cpf);

			dispatch(consultarCpfCinRequest(token, values.cpf));
		},
		[login, dispatch, salvarAtendimento, token],
	);

	return (
		<Row>
			<Col span={24}>
				<Section title="Consultar">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={16}>
										<Field
											as={InputMask}
											title="CPF"
											subtitle="(Apenas números. Ex: 999.999.999-99)"
											titleSize="lg"
											name="cpf"
											mask="999.999.999-99"
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue('cpf', e.target.value);
												dispatch(clearNotifications());
											}}
											autoFocus
											required
											error={hasError(formik.errors, 'cpf')}
										/>
									</Col>

									{/* <Col offset={1} span={5}>
										<Field
											as={CheckBox}
											name="cpfEmpty"
											checked={formik.values.cpfEmpty}
											subtitle="Cidadão não sabe o CPF"
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;
												dispatch(consultarCinClear());

												formik.setFieldValue('cpfEmpty', checked);

												if (checked) {
													formik.setFieldValue('cpf', '');

													return;
												}
												formik.handleReset();
											}}
										/>
									</Col> */}
								</Row>

								{formik.values.cpfEmpty && (
									<>
										<Row gutter={[0, 10]}>
											<Col span={16}>
												<Field
													as={Input}
													title="Nome"
													titleSize="lg"
													name="nome"
													maxLength={60}
													required
													error={!!formik.errors.nome}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={16}>
												<Field
													as={Input}
													title="Nome da Mãe"
													titleSize="lg"
													name="nomeMae"
													maxLength={60}
													required
													error={!!formik.errors.nomeMae}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={16}>
												<Field
													as={InputMask}
													title="Data de Nascimento"
													titleSize="lg"
													name="dataNascimento"
													mask="99/99/9999"
													subtitle="(DD/MM/AAAA)"
													required
													size={25}
													error={!!formik.errors.dataNascimento}
												/>
											</Col>
										</Row>
									</>
								)}

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => {
												formik.handleReset();
												dispatch(clearNotifications());
												dispatch(consultarCpfCinClear());
											}}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
					{consultarCin.data && <ResultadoCpf value={cpfValue} />}
				</Section>

				<ButtonVoltar
					navigate={false}
					onClick={() => {
						dispatch(consultarCpfCinClear());
						history.push(ROUTE_IIRGD);
					}}
				/>
			</Col>
		</Row>
	);
};

export default ConsultarCpf;
