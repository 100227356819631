import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarMensagemRequest } from './types';

// ACTIONS
import { atualizarMensagemSuccess, atualizarMensagemFailure } from './actions';

function* atualizarMensagem(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarMensagemRequest } = request;

	const body = { ...payload };

	delete body.id;
	delete body.search;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMensagemSuccess(response));
	} else {
		yield put(atualizarMensagemFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_MENSAGEM_REQUEST, atualizarMensagem),
]);
