import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_AAC_API } from 'services/rotasp/path';

// STORE
import { ReducerActionRotaSP } from 'store/modules/types';

// ACTIONS
import { emissaoExigenciasSuccess, emissaoExigenciasFailure } from './actions';

// TYPES
import { Types, RequestEmissaoExigencia } from './types';

function* emissaoExigenciasRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: RequestEmissaoExigencia } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_AAC_API,
		`v2/central-liberacao/atendimentos/atestados`,
		payload,
	);

	if (response.status === 200) {
		yield put(emissaoExigenciasSuccess(response.data));
	} else if (response.error) {
		yield put(emissaoExigenciasFailure());
	}
}

export default all([
	takeLatest(Types.EMISSAO_EXIGENCIAS_REQUEST, emissaoExigenciasRequest),
]);
