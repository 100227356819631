import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarMensagemRequest } from './types';

export function atualizarMensagemRequest(
	token: string,
	data: AtualizarMensagemRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_REQUEST,
		token,
		payload: data,
	};
}
export function atualizarMensagemSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function atualizarMensagemFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function atualizarMensagemClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_CLEAR,
		payload: null,
	};
}
