import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarStatusRequest } from './types';

export function consultarStatusRequest(
	token: string,
	data: ConsultarStatusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS,
		token,
		payload: data,
	};
}
export function consultarStatusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_SUCCESS,
		payload,
	};
}
export function consultarStatusFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_FAILURE,
		payload: null,
	};
}
export function consultarStatusClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_CLEAR,
		payload: null,
	};
}
