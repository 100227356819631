export enum Types {
	ATUALIZAR_ORGAO = '@SGU/ATUALIZAR_ORGAO',
	ATUALIZAR_ORGAO_SUCCESS = '@SGU/ATUALIZAR_ORGAO_SUCCESS',
	ATUALIZAR_ORGAO_FAILURE = '@SGU/ATUALIZAR_ORGAO_FAILURE',
	ATUALIZAR_ORGAO_CLEAR = '@SGU/ATUALIZAR_ORGAO_CLEAR',
}

export interface AtualizarOrgao {
	status: number;
	data: null | OrgaoData;
}

interface OrgaoData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarOrgaoRequest {
	id?: number | string;
	descricao: string;
	codigoSefaz: number | string;
	mainframe: boolean;
	flHabilitado: boolean;
}
