// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultaCpfAacRequest, Types } from './types';

export function consultaCpfAacRequest(
	token: string,
	payload: string,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CPF_AAC_REQUEST,
		token,
		payload,
	};
}

export function consultaCpfAacSuccess(
	payload: ConsultaCpfAacRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CPF_AAC_SUCCESS,
		payload,
	};
}

export function consultaCpfAacFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CPF_AAC_FAILURE,
		payload: null,
	};
}

export function consultaCpfAacClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CPF_AAC_CLEAR,
		payload: null,
	};
}
