import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col, Upload, Modal, Divider } from 'antd';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import { InboxOutlined } from '@ant-design/icons';

// FORM
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';
import hasError from 'utils/getFormErrors';
import Error from 'components/Common/Notifications/FormError/index';
import { useDispatch, useSelector } from 'react-redux';
import {
	uploadArquivoClear,
	uploadArquivoRequest,
} from 'store/modules/api/utils/uploadArquivo/actions';
import { ApplicationState } from 'store';
import { ICadastroSolicitante } from '../form';
import { Container, TEXT, TEXTBOLD } from './styled';

enum SolicitanteEnum {
	Filho = 1,
	ResponsavelLegal = 2,
	SupostoGenitor = 3,
}
interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
	descSolicitante: string;
}

const Documentos: React.FC<Props> = ({
	formik,
	step,
	formDisabled,
	descSolicitante,
}) => {
	const { Dragger } = Upload;
	const solicitante: number = formik.values.tipoSolicitante;

	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { uploadArquivo } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const [tableData, setTableData] = useState<any>(
		formik.values.documentosAnexos,
	);

	const [modalFile, setModalFile] = useState<boolean>(false);
	const [errors, setErros] = useState<any>([]);

	const baseField =
		(solicitante === 1 && 'filho') ||
		(solicitante === 2 && 'responsavelLegal') ||
		'supostoGenitor';

	const documentosPessoaisPath = `${baseField}.documentosPessoais`;

	const fileToBinary = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsArrayBuffer(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(reader.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			dispatch(uploadArquivoClear());
			const limitesValidacaoSolicitante: Record<SolicitanteEnum, number> = {
				[SolicitanteEnum.Filho]: 11,
				[SolicitanteEnum.SupostoGenitor]: 11,
				[SolicitanteEnum.ResponsavelLegal]: 12,
			};

			const limitesEspecificoParaCadaSolicitante: Record<
				SolicitanteEnum,
				{ value: string; label: string; quantidade: number }[]
			> = {
				[SolicitanteEnum.Filho]: [
					{
						value: 'DOCUMENTO_PESSOAL_RG_RESPONSAVEL',
						label: 'RG do responsável legal',
						quantidade: 2,
					},
					{
						value: 'DOCUMENTO_PESSOAL_CERTIDAO',
						label: 'Certidão de nascimento ou RG do filho ou da filha',
						quantidade: 4,
					},
					{
						value: 'DOCUMENTO_PESSOAL_RG_GENITOR',
						label: 'RG ou outro documento de identificação do suposto genitor',
						quantidade: 2,
					},
					{
						value: 'OUTROS_DOCUMENTOS',
						label: 'Outros documentos',
						quantidade: 3,
					},
				],
				[SolicitanteEnum.ResponsavelLegal]: [
					{
						value: 'DOCUMENTO_PESSOAL_RG_RESPONSAVEL',
						label: 'RG do responsável legal',
						quantidade: 2,
					},
					{
						value: 'DOCUMENTO_PESSOAL_CERTIDAO',
						label: 'Certidão de nascimento ou RG do filho ou da filha',
						quantidade: 4,
					},
					{
						value: 'DOCUMENTO_PESSOAL_TERMO',
						label: 'Termo de guarda, tutela ou curatela',
						quantidade: 1,
					},
					{
						value: 'DOCUMENTO_PESSOAL_RG_GENITOR',
						label: 'RG ou outro documento de identificação do suposto genitor',
						quantidade: 2,
					},
					{
						value: 'OUTROS_DOCUMENTOS',
						label: 'Outros documentos',
						quantidade: 3,
					},
				],
				[SolicitanteEnum.SupostoGenitor]: [
					{
						value: 'DOCUMENTO_PESSOAL_RG_RESPONSAVEL',
						label: 'RG do responsável legal',
						quantidade: 2,
					},
					{
						value: 'DOCUMENTO_PESSOAL_CERTIDAO',
						label: 'Certidão de nascimento ou RG do filho ou da filha',
						quantidade: 4,
					},
					{
						value: 'DOCUMENTO_PESSOAL_RG_GENITOR',
						label: 'RG ou outro documento de identificação do suposto genitor',
						quantidade: 2,
					},
					{
						value: 'OUTROS_DOCUMENTOS',
						label: 'Outros documentos',
						quantidade: 3,
					},
				],
			};

			const tipoAnexo = formik.values.documentoAnexo;
			const regrasSolicitante =
				limitesEspecificoParaCadaSolicitante[solicitante as SolicitanteEnum];
			const regraAnexo = regrasSolicitante.find(
				item => item.value === tipoAnexo,
			);

			if (!regraAnexo) {
				setErros(['Tipo de anexo inválido.']);
				return;
			}

			const { quantidade: maxQuantidade } = regraAnexo;
			const totalAnexosTipo = tableData.filter(
				(item: any) => item.tipoDoDocumento === tipoAnexo,
			).length;
			const totalAnexos = tableData.length;
			const tamanhoMaximo = 2000000; // 2MB
			const tipoPermitido = 'application/pdf';

			if (
				totalAnexos >=
				limitesValidacaoSolicitante[solicitante as SolicitanteEnum]
			) {
				setErros([
					`O número total de anexos excede o limite de ${
						limitesValidacaoSolicitante[solicitante as SolicitanteEnum]
					} documentos.`,
				]);
			} else if (info.file.size > tamanhoMaximo) {
				setErros(['Tamanho máximo permitido para o documento é de 2 MB.']);
			} else if (info.file.type !== tipoPermitido) {
				setErros([
					'Tipo de arquivo inválido. Apenas arquivos PDF são aceitos.',
				]);
			} else if (maxQuantidade && totalAnexosTipo >= maxQuantidade) {
				setErros([
					`O número de anexos para o tipo "${regraAnexo.label}" excede o limite de ${maxQuantidade}.`,
				]);
			} else {
				try {
					const fileBinary = await fileToBinary(info.file).then(result => {
						return result;
					});
					const fileData = info.file;
					const dataFile = fileBinary as string;
					const editedName = fileData.name;
					// const editedExtension = fileData.type.split('/')[1];

					const alreadyExistFile =
						tableData.filter((file: any) => {
							return file.nomeArquivo === editedName;
						}).length > 0;

					if (!alreadyExistFile && dataFile) {
						dispatch(
							uploadArquivoRequest(token, {
								dataPayload: dataFile,
								detailsFile: {
									name: editedName,
									size: info.file.size,
									// type: editedExtension,
								},
							}),
						);
					} else {
						setErros(['Já existe um arquivo com o mesmo nome.']);
					}
				} catch {
					setErros('Ocorreu um erro com o upload do arquivo.');
				}
			}
		},
		[dispatch, formik.values.documentoAnexo, solicitante, tableData, token],
	);

	const props = {
		name: 'file',
		multiple: true,
		beforeUpload: () => {
			return false;
		},
		onChange: (info: any) => handleChange(info),
	};

	const handleRemove = useCallback(
		index => {
			const allFiles = [...tableData];
			allFiles.splice(index, 1);
			formik.setFieldValue(documentosPessoaisPath, allFiles);
			setTableData(allFiles);
		},
		[documentosPessoaisPath, formik, tableData],
	);

	const renderHeaderByStep = (stepParams: number) => {
		if (stepParams === 2) {
			return [
				{
					key: '1',
					title: 'Nº',
					dataIndex: 'contador',
					render: (text: any, record: any, index: any) => index + 1,
				},
				{
					key: '2',
					title: 'Nome',
					dataIndex: 'nomeArquivo',
				},
				{
					key: '3',
					title: 'Tipo',
					dataIndex: 'tipoDoDocumento',
				},
			];
		}
		return [
			{
				key: '1',
				title: 'Nº',
				dataIndex: 'contador',
				render: (text: any, record: any, index: any) => index + 1,
			},
			{
				key: '2',
				title: 'Nome',
				dataIndex: 'nomeArquivo',
			},
			{
				key: '3',
				title: 'Tipo',
				dataIndex: 'tipoDoDocumento',
			},
			{
				key: '4',
				title: '',
				dataIndex: 'type',
				render: (text: any, record: any, index: any) =>
					step === 2 ? (
						''
					) : (
						<Button
							// disabled={step === 2}
							onClick={() => handleRemove(index)}
							// style={{ cursor: 'not-allowed' }}
						>
							Excluir
						</Button>
					),
			},
		];
	};

	const enumAnexosResponsavelLegal = [
		{
			value: 'DOCUMENTO_PESSOAL_RG_RESPONSAVEL',
			label: 'RG do responsável legal',
			quantidade: 2,
		},
		{
			value: 'DOCUMENTO_PESSOAL_CERTIDAO',
			label: 'Certidão de nascimento ou RG do filho ou da filha',
			quantidade: 4,
		},
		{
			value: 'DOCUMENTO_PESSOAL_TERMO',
			label: 'Termo de guarda, tutela ou curatela',
			quantidade: 1,
		},
		{
			value: 'DOCUMENTO_PESSOAL_RG_GENITOR',
			label: 'RG ou outro documento de identificação do suposto genitor',
			quantidade: 2,
		},
		{
			value: 'OUTROS_DOCUMENTOS',
			label: 'Outros documentos',
			quantidade: 3,
		},
	];

	const enumAnexosFilhoOuSupostoGenitor = [
		{
			value: 'DOCUMENTO_PESSOAL_RG_RESPONSAVEL',
			label: 'RG do responsável legal',
			quantidade: 2,
		},
		{
			value: 'DOCUMENTO_PESSOAL_CERTIDAO',
			label: 'Certidão de nascimento ou RG do filho ou da filha',
			quantidade: 4,
		},
		{
			value: 'DOCUMENTO_PESSOAL_RG_GENITOR',
			label: 'RG ou outro documento de identificação do suposto genitor',
			quantidade: 2,
		},
		{
			value: 'OUTROS_DOCUMENTOS',
			label: 'Outros documentos',
			quantidade: 3,
		},
	];

	useEffect(() => {
		if (
			uploadArquivo.data &&
			uploadArquivo.detailsFile &&
			uploadArquivo.status === 200
		) {
			const tipoAnexo = formik.values.documentoAnexo;

			const { data, detailsFile } = uploadArquivo;

			const valuesFormatt = {
				nomeArquivo: detailsFile.name,
				// extensaoArquivo: detailsFile.type,
				tipoDoDocumento:
					tipoAnexo === 'OUTROS_DOCUMENTOS' ? 'Outros' : tipoAnexo,
				urlArquivo: data.url,
			};

			const alreadyExistFile =
				tableData.filter((file: any) => {
					return file.nomeArquivo === detailsFile.name;
				}).length > 0;

			if (!alreadyExistFile) {
				if (valuesFormatt.tipoDoDocumento === 'OUTROS_DOCUMENTOS') {
					formik.setFieldValue('documentosAnexos', [
						...formik.values.documentosAnexos,
						valuesFormatt,
					]);
				}

				// Adicionar o novo documento, se válido
				if (tipoAnexo === 'DOCUMENTO_PESSOAL_CERTIDAO') {
					if (
						valuesFormatt.nomeArquivo &&
						// valuesFormatt.extensaoArquivo &&
						valuesFormatt.urlArquivo &&
						valuesFormatt.tipoDoDocumento
					) {
						formik.setFieldValue('filho.documentosPessoais', [
							...formik.values[baseField].documentosPessoais,
							valuesFormatt,
						]);
					}
				}

				if (
					valuesFormatt.nomeArquivo &&
					// valuesFormatt.extensaoArquivo &&
					valuesFormatt.urlArquivo &&
					valuesFormatt.tipoDoDocumento
				) {
					formik.setFieldValue(documentosPessoaisPath, [
						...formik.values[baseField].documentosPessoais,
						valuesFormatt,
					]);
				}

				setTableData([...tableData, valuesFormatt]);
				setModalFile(false);
				dispatch(uploadArquivoClear());
			}
		} else if (uploadArquivo.status === 400) {
			setErros(['Erro ao fazer upload do arquivo.']);
		}
	}, [
		baseField,
		dispatch,
		documentosPessoaisPath,
		formik,
		tableData,
		uploadArquivo,
	]);

	return (
		<Container>
			<FormBox title="Documentos">
				<Row gutter={[0, 10]}>
					{solicitante === 1 && (
						<>
							<TEXTBOLD>
								Atenção: Para completar a solicitação, é necessário anexar os
								documentos obrigatórios e seguir as regras de quantidade. Quanto
								mais informações fornecer, maior a chance de localizar o suposto
								genitor.
							</TEXTBOLD>
							<TEXTBOLD>Documentos obrigatórios:</TEXTBOLD>
							<TEXT>
								Certidão de nascimento ou RG do filho ou da filha: máximo de 4
								anexos, cada um com até 20MB.
							</TEXT>
							<TEXTBOLD>Outros documentos permitidos (opcionais):</TEXTBOLD>
							<TEXT>
								RG do responsável legal: máximo de 2 anexos, cada um com até
								20MB.
							</TEXT>
							<TEXT>
								RG ou outro documento de identificação do suposto genitor:
								máximo de 2 anexos, cada um com até 20MB.
							</TEXT>
							<TEXT>
								Outros documentos: máximo de 3 anexos, cada um com até 20MB.
							</TEXT>
						</>
					)}
					{solicitante === 2 && (
						<>
							<TEXTBOLD>
								Atenção: Para completar a solicitação, é necessário anexar os
								documentos obrigatórios e seguir as regras de quantidade. Quanto
								mais informações fornecer, maior a chance de localizar o suposto
								genitor.
							</TEXTBOLD>
							<TEXTBOLD>Documentos obrigatórios:</TEXTBOLD>
							<TEXT>
								RG do responsável legal: obrigatório (máximo de 2 anexos, cada
								um com até 20MB).
							</TEXT>
							<TEXT>
								Certidão de nascimento ou RG do filho(a): obrigatório (máximo de
								4 anexos, cada um com até 20MB).
							</TEXT>
							<TEXTBOLD>Outros documentos permitidos (opcionais)</TEXTBOLD>
							<TEXT>
								Termo de guarda, tutela ou curatela: máximo de 2 anexos, cada um
								com até 20MB.
							</TEXT>
							<TEXT>
								RG ou outro documento de identificação do suposto genitor:
								máximo de 3 anexos, cada um com até 20MB.
							</TEXT>
							<TEXT>
								Outros documentos: máximo de 1 anexo, cada um com até 20MB.
							</TEXT>
						</>
					)}
					{solicitante === 3 && (
						<>
							<TEXTBOLD>
								Atenção: Para completar a solicitação, é necessário anexar os
								documentos obrigatórios e seguir as regras de quantidade. Quanto
								mais informações fornecer, maior a chance de localizar o filho
								ou filha.
							</TEXTBOLD>
							<TEXTBOLD>Documentos obrigatórios</TEXTBOLD>
							<TEXT>
								RG ou outro documento de identificação do suposto genitor:
								obrigatório (máximo de 2 anexos, cada um com até 20MB).
							</TEXT>
							<TEXTBOLD>Outros documentos permitidos (opcionais)</TEXTBOLD>
							<TEXT>
								RG do responsável legal: máximo de 2 anexos, cada um com até
								20MB.
							</TEXT>
							<TEXT>
								Certidão de nascimento ou RG do filho ou da filha: máximo de 4
								anexos, cada um com até 20MB.
							</TEXT>
							<TEXT>
								Outros documentos: máximo de 3 anexos, cada um com até 20MB.
							</TEXT>
						</>
					)}
					<Col span={24}>
						<Section
							title={`Limite total de anexos: até ${
								solicitante === 2 ? '12' : '11'
							} documentos por ${descSolicitante}.`}
							size="sm"
						>
							<Row gutter={[0, 20]} justify="center">
								<Col>
									{step !== 2 && (
										<Button
											disabled={formDisabled}
											className="addButton"
											onClick={() => {
												setModalFile(true);
												dispatch(uploadArquivoClear());
											}}
										>
											+ {'           '} Incluir anexo
										</Button>
									)}
								</Col>
								<Col span={24}>
									<SimpleTable
										pageSize={10}
										headers={renderHeaderByStep(step)}
										body={tableData}
										messageNoResults="Nenhum registro encontrado"
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			</FormBox>

			<Modal
				title="Selecione o Documento"
				visible={modalFile}
				onCancel={() => {
					setModalFile(false);
					setErros([]);
				}}
			>
				{errors.length > 0 && (
					<Error errors={errors} onClose={() => setErros([])} />
				)}
				<Field
					as={Select}
					required
					title="Tipo de Documento"
					name="anexosTipo"
					options={
						solicitante === 2
							? enumAnexosResponsavelLegal
							: enumAnexosFilhoOuSupostoGenitor
					}
					onChange={(v: string) => formik.setFieldValue('documentoAnexo', v)}
					error={hasError(formik.errors, 'documentoAnexo')}
				/>
				<Divider />

				<Dragger
					{...props}
					disabled={formik.values.documentoAnexo === ''}
					accept="application/pdf"
					showUploadList={false}
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						{formik.values.documentoAnexo === ''
							? 'Selecione o tipo de Documento'
							: 'Clique ou arraste o arquivo para incluir o Anexo.'}
					</p>
				</Dragger>
			</Modal>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</Container>
	);
};

export default Documentos;
