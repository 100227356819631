import { takeLatest, call, put, all } from 'redux-saga/effects';

// GENERICS
import { cleanEmpty } from 'utils/genericFunctions';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultarPerfisRequest } from './types';

// ACTIONS
import { consultarPerfilsSuccess, consultarPerfilsFailure } from './action';

function* consultarPerfisRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarPerfisRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarPerfisRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`perfis`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarPerfilsSuccess(response));
	} else {
		yield put(consultarPerfilsFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_PERFIS_REQUEST, consultarPerfisRequest),
]);
