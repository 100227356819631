import { lazy } from 'react';

// ROUTES
import funcionalidadesRoutes from '../Funcionalidade/routes/routes';

// PATHS
import {
	ROUTE_SGU_ADMIN_FUNCIONALIDADE,
	ROUTE_SGU_ADMIN_LOCALIDADE,
	ROUTE_SGU_ADMIN_LOCALIDADE_ANTIGA,
	ROUTE_SGU_ADMIN_MENSAGEM,
	ROUTE_SGU_ADMIN_MENSAGEM_ERRO,
	ROUTE_SGU_ADMIN_MENUS,
	ROUTE_SGU_ADMIN_MOTIVO,
	ROUTE_SGU_ADMIN_MUNICIPIO,
	ROUTE_SGU_ADMIN_MUNICIPIO_IBGE,
	ROUTE_SGU_ADMIN_NATURALIDADE,
	ROUTE_SGU_ADMIN_ORGAO,
	ROUTE_SGU_ADMIN_PARAMETROS_LOCALIDADE,
	ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA,
	// ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA_REDIS,
	ROUTE_SGU_ADMIN_PERFIL,
	ROUTE_SGU_ADMIN_SIGLA,
	ROUTE_SGU_ADMIN_STATUS,
	ROUTE_SGU_ADMIN_SUFIXO,
	ROUTE_SGU_ADMIN_UNIDADE,
} from './paths';

// COMPONENTS
const Funcionalidade = lazy(() => import('../Funcionalidade'));
const Naturalidade = lazy(() => import('../Naturalidade'));
const Motivo = lazy(() => import('../Motivo'));
const Orgao = lazy(() => import('../Orgao'));
const Perfis = lazy(() => import('../Perfis'));
const Status = lazy(() => import('../Status'));
const Sigla = lazy(() => import('../Sigla'));
const Localidade = lazy(() => import('../Localidade'));
const Sufixo = lazy(() => import('../Sufixo'));
const Menu = lazy(() => import('../Menu'));
const Unidade = lazy(() => import('../Unidade'));
const Municipio = lazy(() => import('../Municipios/Municipio'));
const MunicipiosIBGE = lazy(() => import('../Municipios/MunicipioIBGE'));
const Mensagem = lazy(() => import('../Mensagem/Mensagem'));
const MensagemErro = lazy(() => import('../Mensagem/Erro'));
const ParametrosLocalidade = lazy(() => import('../Parametros/Localidade'));
const ParametrosSistema = lazy(() => import('../Parametros/Sistema'));
// const ParametrosSistemaRedis = lazy(() => import('../Parametros/Redis'));

export const SGURoute = {
	name: 'Poupatempo - SGU',
	route: '/poupatempo',
};

const usuarioRoutes = [
	{
		path: ROUTE_SGU_ADMIN_FUNCIONALIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Funcionalidades ',
			},
		],
		Component: Funcionalidade,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_LOCALIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Localidades - Localidade ',
			},
		],
		Component: Localidade,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_LOCALIDADE_ANTIGA,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Localidades - Localidade (Antiga) ',
			},
		],
		Component: Funcionalidade,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_MENSAGEM_ERRO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Mensagens - Mensagem de Erro ',
			},
		],
		Component: MensagemErro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_MENSAGEM,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Mensagens - Mensagem De/Para ',
			},
		],
		Component: Mensagem,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_MUNICIPIO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Municípios - Município ',
			},
		],
		Component: Municipio,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_MUNICIPIO_IBGE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Municípios - Município IBGE ',
			},
		],
		Component: MunicipiosIBGE,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_PARAMETROS_LOCALIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Parâmetro - Parâmetros da Localidade ',
			},
		],
		Component: ParametrosLocalidade,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Parâmetro - Parâmetros do Sistema ',
			},
		],
		Component: ParametrosSistema,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA_REDIS,
	// 	breadcrumb: [
	// 		SGURoute,
	// 		{
	// 			name: 'Administrar - Parâmetro - Parâmetros do Sistema (Redis) ',
	// 		},
	// 	],
	// 	Component: ParametrosSistemaRedis,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_SGU_ADMIN_MENUS,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Menus ',
			},
		],
		Component: Menu,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_MOTIVO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Motivo ',
			},
		],
		Component: Motivo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_NATURALIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Naturalidade ',
			},
		],
		Component: Naturalidade,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_ORGAO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Órgão ',
			},
		],
		Component: Orgao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_PERFIL,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Perfil ',
			},
		],
		Component: Perfis,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_SIGLA,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Sigla ',
			},
		],
		Component: Sigla,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_STATUS,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Status ',
			},
		],
		Component: Status,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_SUFIXO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Sufixo ',
			},
		],
		Component: Sufixo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_UNIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Administrar - Unidade ',
			},
		],
		Component: Unidade,
		isAuthenticated: true,
		exact: true,
	},
].concat(funcionalidadesRoutes);

export default usuarioRoutes;
