import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarSufixoRequest } from './types';

export function cadastrarSufixoRequest(
	token: string,
	data: CadastrarSufixoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SUFIXO,
		token,
		payload: data,
	};
}
export function cadastrarSufixoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SUFIXO_SUCCESS,
		payload,
	};
}
export function cadastrarSufixoFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SUFIXO_FAILURE,
		payload: null,
	};
}
export function cadastrarSufixoClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SUFIXO_CLEAR,
		payload: null,
	};
}
