import React, { useCallback, useContext } from 'react';

// UUID
import { v4 } from 'uuid';

// ROUTES
import { Link } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { aacClear } from 'store/modules/api/aac/actions';
import { consultaUsuarioPendenteRequest } from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/actions';

import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import routes from './routes';

// STYLED
import { Container } from './styled';

interface NavRoute {
	path: string;
	serviceName: string;
}

const NavMenu: React.FC = () => {
	const dispatch = useDispatch();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { token } = useContext<IAuthContext>(AuthContext);

	const handleClick = useCallback(
		(route: NavRoute) => {
			dispatch(aacClear());

			if (route.serviceName === 'Painel de Controle') {
				dispatch(
					consultaUsuarioPendenteRequest(token, {
						identifUsuario: login.mainframe.idUsuario,
						numeroPosto: login.user.posto,
					}),
				);
			}
		},
		[dispatch, login.mainframe.idUsuario, login.user.posto, token],
	);

	return (
		<Container>
			{routes.map(route => (
				<Link key={v4()} to={route.path} onClick={() => handleClick(route)}>
					{route.serviceName}
				</Link>
			))}
		</Container>
	);
};

export default NavMenu;
