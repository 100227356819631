import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarDetalheSolicitacaoRequest, Types } from './types';

export function consultarDetalheSolicitacaoRequest(
	token: string,
	payload: ConsultarDetalheSolicitacaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_DETALHE_SOLICITACAO_REQUEST,
		token,
		payload,
	};
}
export function consultarDetalheSolicitacaoSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_DETALHE_SOLICITACAO_SUCCESS,
		payload,
	};
}
export function consultarDetalheSolicitacaoFailure(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_DETALHE_SOLICITACAO_FAILURE,
		payload,
	};
}

export function consultarDetalheSolicitacaoClear(): any {
	return {
		type: Types.CONSULTAR_DETALHE_SOLICITACAO_CLEAR,
	};
}
