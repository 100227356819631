import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarPerfilRequest } from './types';

export function atualizarPerfilRequest(
	token: string,
	data: AtualizarPerfilRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PERFIL,
		token,
		payload: data,
	};
}
export function atualizarPerfilSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PERFIL_SUCCESS,
		payload,
	};
}
export function atualizarPerfilFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PERFIL_FAILURE,
		payload: null,
	};
}
export function atualizarPerfilClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PERFIL_CLEAR,
		payload: null,
	};
}
