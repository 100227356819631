import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMensagemRequest } from './types';

export function excluirMensagemRequest(
	token: string,
	data: ExcluirMensagemRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_REQUEST,
		token,
		payload: data,
	};
}
export function excluirMensagemSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function excluirMensagemFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function excluirMensagemClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_CLEAR,
		payload: null,
	};
}
