import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	Types,
	ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
} from './types';

// ACTIONS
import {
	excluirFuncionalidadesRelacionadasLocalidadeSuccess,
	excluirFuncionalidadesRelacionadasLocalidadeFailure,
} from './actions';

function* excluirFuncionalidadesRelacionadasLocalidade(
	request: ReducerActionRotaSP,
) {
	const {
		token,
		payload,
	}: {
		token?: string;
		payload: ExcluirFuncionalidadesRelacionadasLocalidadeRequest;
	} = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`localidades-funcionalidades/${payload.id}`,
	);

	if (response.status === 200) {
		response.data = {
			id: payload.id,
		};
		yield put(excluirFuncionalidadesRelacionadasLocalidadeSuccess(response));
	} else {
		yield put(excluirFuncionalidadesRelacionadasLocalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE,
		excluirFuncionalidadesRelacionadasLocalidade,
	),
]);
