import { takeLatest, call, put, all } from 'redux-saga/effects';

// GENERICS
import { cleanEmpty } from 'utils/genericFunctions';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMensagensNewRequest } from './types';

// ACTIONS
import {
	consultarMensagensSuccess,
	consultarMensagensFailure,
} from './actions';

function* consultarMensagens(request: ReducerAction) {
	const {
		payload,
		token,
	}: { token?: string; payload: ConsultarMensagensNewRequest } = request;

	let body = { ...payload };

	body = cleanEmpty(body) as ConsultarMensagensNewRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMensagensSuccess(response));
	} else {
		yield put(consultarMensagensFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MENSAGENS_REQUEST, consultarMensagens),
]);
