import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarMunicipiosIbgeRequest, Types } from './types';

// ACTIONS
import {
	consultarMunicipiosIbgeSuccess,
	consultarMunicipiosIbgeFailure,
} from './actions';

function* consultarMunicipiosIbgeRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarMunicipiosIbgeRequest } = request;

	let body = { ...payload };

	body = cleanEmpty(body) as ConsultarMunicipiosIbgeRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`municipios-ibge`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarMunicipiosIbgeSuccess(response));
	} else {
		yield put(consultarMunicipiosIbgeFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_MUNICIPIOS_IBGE_REQUEST,
		consultarMunicipiosIbgeRequest,
	),
]);
