import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

import data from 'store/modules/api/mp/enum';

import FormBox from 'components/Common/Form/FormBox';
import Radio from 'components/Common/Form/Radio';
import Input from 'components/Common/Form/Input/Input';
import hasError from 'utils/getFormErrors';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import InputMask from 'components/Common/Form/Input/InputMask';
import { onlyLetters } from 'utils/genericFunctions';
import DadosSolicitante from '../DadosSolicitante';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const DadosGenitor: React.FC<Props> = ({ formik, step, formDisabled }) => {
	return (
		<>
			<FormBox
				title={`Dados do Genitor ${
					formik.values.tipoSolicitante === 3 ? ' - Solicitante' : ''
				}`}
			>
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Field
							required={
								formik.values.tipoSolicitante === 1 ||
								formik.values.tipoSolicitante === 2 ||
								formik.values.tipoSolicitante === 3
							}
							maxLength={60}
							value={onlyLetters(formik.values.supostoGenitor.nome)}
							as={Input}
							title="Nome Completo"
							name="supostoGenitor.nome"
							error={hasError(formik.errors, 'supostoGenitor.nome')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={10}>
						<Field
							title="Data de Nascimento"
							name="supostoGenitor.dataDeNascimento"
							type="text"
							mask="99/99/9999"
							subtitle={step === 2 ? '' : '(DD/MM/AAAA)'}
							size={60}
							as={ValidDataInput}
							error={hasError(formik.errors, 'supostoGenitor.dataDeNascimento')}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('supostoGenitor.dataDeNascimento', v)
							}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>

					<Col span={6}>
						{step === 1 && (
							<Field
								required={
									formik.values.tipoSolicitante === 1 ||
									formik.values.tipoSolicitante === 2 ||
									formik.values.tipoSolicitante === 3
								}
								as={Radio}
								titleSize="sm"
								options={data.sexo}
								title="Sexo"
								defaultValue={formik.values.supostoGenitor.sexo}
								name="supostoGenitor.sexo"
								error={hasError(formik.errors, 'supostoGenitor.sexo')}
								disabled={formDisabled}
							/>
						)}
						{step === 2 && (
							<Field
								required={
									formik.values.tipoSolicitante === 1 ||
									formik.values.tipoSolicitante === 2 ||
									formik.values.tipoSolicitante === 3
								}
								as={Input}
								title="Sexo"
								titleSize="sm"
								name="supostoGenitor.sexo"
								disabled={formDisabled}
								value={
									formik.values.supostoGenitor.sexo === 'Masculino'
										? 'MASCULINO'
										: 'FEMININO'
								}
								readOnly={step === 2}
							/>
						)}
					</Col>
					<Col span={8}>
						<Field
							title="CPF"
							name="supostoGenitor.cpf.numero"
							as={InputMask}
							mask="999.999.999-99"
							error={hasError(formik.errors, 'supostoGenitor.cpf.numero')}
							readOnly={step === 2}
							// required
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				<DadosSolicitante
					formDisabled={formDisabled}
					formik={formik}
					step={step}
					solicitante="supostoGenitor"
				/>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosGenitor;
