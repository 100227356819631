import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultarSufixosRequest } from './types';

// ACTIONS
import { consultarSulfixosSuccess, consultarSulfixosFailure } from './action';

function* buscarSufixos(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarSufixosRequest } = request;

	const body = { ...payload };

	delete body.current;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`sufixos`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarSulfixosSuccess(response));
	} else {
		yield put(consultarSulfixosFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_SUFIXO, buscarSufixos)]);
