import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// FORM
import Section from 'components/Common/Section';
import CheckBox from 'components/Common/Form/Checkbox';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const Termos: React.FC<Props> = ({ formik }) => {
	return (
		<>
			<Section title="Termos" size="sm">
				<Field
					as={CheckBox}
					// disabled
					name="podeArcarComCustosDoProcesso"
					subtitle="Declarou não ter condições financeiras para pagar o procedimento e as despesas da averbação no registro de nascimento, de modo a não prejudicar seu sustento e o da sua família, nos termos da lei."
					checked={formik.values.podeArcarComCustosDoProcesso}
					error={!formik.errors.podeArcarComCustosDoProcesso}
				/>
			</Section>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default Termos;
