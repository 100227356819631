import { IConfrontarDigitaisState } from './confrontarDigitais/types';
import { ConsultarDigitaisState } from './consultarDigitais/types';
import { IConfrontarDigitaisCnhState } from './confrontarDigitaisCnh/types';
import { ListaCep } from './endereco/lista-cep/types';
import { Pais } from './endereco/pais/types';
import { EnderecoIpState } from './enderecoIp/types';
import { ConsultaCidadesUFState } from './consultaCidadesUF/types';
import { ConsultarFotosRgState } from './consultarFotosRg/types';
import { Enderecos } from './enderecos/types';
import { BaixarArquivos } from './arquivos/baixarArquivos/types';
import { ConsultarBCadastroState } from './bcadastro/types';
import { EventosPPTContabilizacao } from './eventosPPT/contabilizacao/types';
import { UploadArquivo } from './uploadArquivo/types';

export enum Types {
	UTILS_CLEAR = '@utils/UTILS_CLEAR',
}

export interface UtilsState {
	listaCep: ListaCep;
	pais: Pais;
	consultarDigitais: ConsultarDigitaisState;
	confrontarDigitais: IConfrontarDigitaisState;
	confrontarDigitaisCnh: IConfrontarDigitaisCnhState;
	enderecoIp: EnderecoIpState;
	consultaCidadesUF: ConsultaCidadesUFState;
	consultarFotosRg: ConsultarFotosRgState;
	enderecos: Enderecos;
	baixarArquivos: BaixarArquivos;
	bcadastro: ConsultarBCadastroState;
	eventosPPTContabilizacao: EventosPPTContabilizacao;
	uploadArquivo: UploadArquivo;
}
