// import { ICadastroSolicitante } from 'pages/ministerioPublico/ReconhecimentoPaternidade/NovoCadastro/Forms/form';
import { Types } from './types';
import { SolicitarReconhecimentoPaternidadeRequest } from '../../ministerioPublico/solicitarReconhecimentoPaternidade/types';

export function preCadastroRequest(
	payload: SolicitarReconhecimentoPaternidadeRequest,
) {
	return {
		type: Types.PRE_CADASTRO_REQUEST,
		payload,
	};
}

export function limpaPreCadastroRequest() {
	return {
		type: Types.LIMPA_PRE_CADASTRO_REQUEST,
	};
}
