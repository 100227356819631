import produce from 'immer';

// TYPES
import { Types, AtualizarFuncionalidade } from './types';
import { ReducerActionRotaSP } from '../../../../../types';

export const INITIAL_STATE: AtualizarFuncionalidade = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): AtualizarFuncionalidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_FUNCIONALIDADE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.ATUALIZAR_FUNCIONALIDADE_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.ATUALIZAR_FUNCIONALIDADE_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.ATUALIZAR_FUNCIONALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
