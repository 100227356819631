import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMensagensErroRequest } from './types';

export function consultarMensagensErroRequest(
	token: string,
	data: ConsultarMensagensErroRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_REQUEST,
		token,
		payload: data,
	};
}
export function consultarMensagensErroSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_SUCCESS,
		payload,
	};
}
export function consultarMensagensErroFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_FAILURE,
		payload: null,
	};
}
export function consultarMensagensErroClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_CLEAR,
		payload: null,
	};
}
