import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarUmaFuncionalidadeRequest } from './types';

export function consultarUmaFuncionalidadeRequest(
	token: string,
	data: ConsultarUmaFuncionalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE,
		token,
		payload: data,
	};
}
export function consultarUmaFuncionalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function consultarUmaFuncionalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarUmaFuncionalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
