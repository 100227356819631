import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMunicipiosIbgeOdRequest } from './types';

export function consultarMunicipiosIbgeRequest(
	token: string,
	data: ConsultarMunicipiosIbgeOdRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_REQUEST,
		token,
		payload: {
			page: data.current && data.current - 1,
			size: data.limite,
			idNaturalidade: data.idNaturalidade,
			idIbge: data.idIbge,
			uf: data.uf,
			descricao: data.descricao,
		},
	};
}
export function consultarMunicipiosIbgeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_SUCCESS,
		payload,
	};
}
export function consultarMunicipiosIbgeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_FAILURE,
		payload: null,
	};
}
export function consultarMunicipiosIbgeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_CLEAR,
		payload: null,
	};
}
