export enum Types {
	UPLOAD_ARQUIVO_REQUEST = '@utils/UPLOAD_ARQUIVO_REQUEST',
	UPLOAD_ARQUIVO_SUCCESS = '@utils/UPLOAD_ARQUIVO_SUCCESS',
	UPLOAD_ARQUIVO_FAILURE = '@utils/UPLOAD_ARQUIVO_FAILURE',
	UPLOAD_ARQUIVO_CLEAR = '@utils/UPLOAD_ARQUIVO_CLEAR',
}

export interface UploadArquivo {
	status: number;
	data: UploadArquivoData | null;
	detailsFile: UploadArquivoDetailsFile | null;
}

interface UploadArquivoData {
	id: string;
	pathId: string;
	localId: string;
	relativePath: string;
	url: string;
}

export interface UploadArquivoRequest {
	dataPayload: string | ArrayBuffer;
	detailsFile: UploadArquivoDetailsFile;
}

interface UploadArquivoDetailsFile {
	name: string;
	size: string;
	// type: string;
}
