import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarMensagemRequest } from './types';

// ACTIONS
import { cadastrarMensagemSuccess, cadastrarMensagemFailure } from './actions';

function* cadastrarMensagem(request: ReducerAction) {
	const {
		payload,
		token,
	}: { token?: string; payload: CadastrarMensagemRequest } = request;

	const body = { ...payload };

	if (body.id) {
		body.id = Number(body.id);
	}

	delete body.search;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarMensagemSuccess(response));
	} else {
		yield put(cadastrarMensagemFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_MENSAGEM_REQUEST, cadastrarMensagem),
]);
