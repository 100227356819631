import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_AAC_API } from 'services/rotasp/path';

// STORE
import { ReducerActionRotaSP } from 'store/modules/types';

// ACTIONS
import { consultaCpfAacSuccess, consultaCpfAacFailure } from './actions';

// TYPES
import { ConsultaCpfAacRequest, Types } from './types';

function* consultaCpfAacRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: ConsultaCpfAacRequest } =
		request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_AAC_API,
		`consultas/cpf`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCpfAacSuccess(response.data));
	} else if (response.error) {
		yield put(consultaCpfAacFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_CPF_AAC_REQUEST, consultaCpfAacRequest),
]);
