import produce from 'immer';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, emissaoExigencias } from './types';

export const INITIAL_STATE: emissaoExigencias = {
	status: 0,
	data: { pdfBase64: '', nomeSocial: '' },
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): emissaoExigencias {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EMISSAO_EXIGENCIAS_REQUEST: {
				break;
			}

			case Types.EMISSAO_EXIGENCIAS_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.EMISSAO_EXIGENCIAS_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EMISSAO_EXIGENCIAS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
