export enum Types {
	CONSULTA_CPF_AAC_REQUEST = '@aac/CONSULTA_CPF_AAC_REQUEST',
	CONSULTA_CPF_AAC_SUCCESS = '@aac/CONSULTA_CPF_AAC_SUCCESS',
	CONSULTA_CPF_AAC_FAILURE = '@aac/CONSULTA_CPF_AAC_FAILURE',
	CONSULTA_CPF_AAC_CLEAR = '@aac/CONSULTA_CPF_AAC_CLEAR',
}

export interface consultaCpf {
	status: number;
	data: null | ConsultaCpfAac;
}

export interface ConsultaCpfAac {
	numeroRg: string;
	dcRg: string;
	cpf: string;
	dcCpf: string;
	seqRg: string;
	tipoIdentidade: string;
	localizadoNaBaseCivil: boolean;
	localizadoComCinEmitida: boolean;
}

export interface ConsultaCpfAacRequest {
	cpf: string;
	dcCpf: string;
}
