import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarParametrosSistemaRequest, Types } from './types';

export function consultarParametrosSistemaRequest(
	token: string,
	data: ConsultarParametrosSistemaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA,
		token,
		payload: data,
	};
}
export function consultarParametrosSistemaSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_SUCCESS,
		payload,
	};
}
export function consultarParametrosSistemaFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_FAILURE,
		payload: null,
	};
}
export function consultarParametrosSistemaClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_CLEAR,
		payload: null,
	};
}
