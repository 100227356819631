import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarStatusRequest } from './types';

// ACTIONS
import { atualizarStatusSuccess, atualizarStatusFailure } from './actions';

function* atualizarStatus(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarStatusRequest } = request;

	const body = { ...payload };

	// delete body.codigo;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`status/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarStatusSuccess(response));
	} else {
		yield put(atualizarStatusFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_STATUS, atualizarStatus)]);
