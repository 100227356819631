import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarFuncionalidadesRequest, Types } from './types';

export function consultarFuncionalidadeRequest(
	token: string,
	data: ConsultarFuncionalidadesRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_REQUEST,
		token,
		payload: {
			page: data.current && data.current - 1,
			size: data.limite,
			id: data.id,
			descricao: data.descricao,
		},
	};
}
export function consultarFuncionalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_SUCCESS,
		payload,
	};
}
export function consultarFuncionalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarFuncionalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_CLEAR,
		payload: null,
	};
}
