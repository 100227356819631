import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, IRequestWithHeaderLocalidadeEdit } from './types';

export function atualizarLocalidadeRequest(
	token: string,
	payload: IRequestWithHeaderLocalidadeEdit,
) {
	return {
		type: Types.ATUALIZAR_LOCALIDADE_REQUEST,
		token,
		payload,
	};
}
export function atualizarLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_LOCALIDADE_CLEAR,
		payload: null,
	};
}
