import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import ButtonImage from 'components/Common/ButtonImage';

import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// STYLES
import { formatDateToString, toTitleCase } from 'utils/genericFunctions';
import {
	BoxConfirmation,
	Container,
	BoldText,
	StyledUl,
	QRCodeBox,
	QRCodeImg,
	BoxConfirmationQRCode,
	LogoMPImg,
	LogoMPBox,
} from './styled';

interface DataProtocolo {
	nome: string;
	email: string;
}

const Confirmacao = () => {
	const history = useHistory();

	const { nomePosto } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const { solicitarReconhecimentoPaternidade, consultarDetalheSolicitacao } =
		useSelector((state: ApplicationState) => state.api.ministerioPublico);

	const [dataProtocolo, setDataProtocolo] = useState<DataProtocolo>({
		nome: '',
		email: '',
	});

	const getClock = (date: string) => {
		const fullDate = new Date(date);
		const hours = fullDate.getHours() || 0;
		const minutes = fullDate.getMinutes() || 0;

		const treatedHours =
			(hours < 10 && hours.toString().padStart(2, '0')) || hours.toString();

		const minutesTreated =
			(minutes < 10 && minutes.toString().padStart(2, '0')) ||
			minutes.toString();

		const clock = `${treatedHours}:${minutesTreated}`;
		return clock;
	};

	const getDate = (date: string) => {
		const fullDate = new Date(date);
		const formattedDate = formatDateToString(fullDate);
		return formattedDate;
	};

	useEffect(() => {
		const dadosProtocoloDeAcordoComSolicitante =
			solicitarReconhecimentoPaternidade.request?.quemFezOPedido;
		if (dadosProtocoloDeAcordoComSolicitante === 'ResponsavelLegal') {
			setDataProtocolo({
				nome:
					solicitarReconhecimentoPaternidade.request?.responsavelLegal.nome ||
					'',
				email:
					solicitarReconhecimentoPaternidade.request?.responsavelLegal.email ||
					'',
			});
		} else if (
			dadosProtocoloDeAcordoComSolicitante === 'FilhoCivilmenteCapaz'
		) {
			setDataProtocolo({
				nome: solicitarReconhecimentoPaternidade.request?.filho.nome || '',
				email: solicitarReconhecimentoPaternidade.request?.filho.email || '',
			});
		} else if (dadosProtocoloDeAcordoComSolicitante === 'SupostoGenitor') {
			setDataProtocolo({
				nome:
					solicitarReconhecimentoPaternidade.request?.supostoGenitor.nome || '',
				email:
					solicitarReconhecimentoPaternidade.request?.supostoGenitor.email ||
					'',
			});
		}
	}, [solicitarReconhecimentoPaternidade.request]);

	return (
		<Container>
			<Row align="middle" justify="center">
				<Col span={24}>
					<BoxConfirmation>
						<Col span={24} style={{ padding: '0' }}>
							<Row>
								<Col span={12}>
									<LogoMPBox>
										<LogoMPImg />
									</LogoMPBox>
								</Col>
								<Col span={12}>
									<span>
										<p>Protocolo de solicitação</p>
										<BoldText size="1.2">
											{consultarDetalheSolicitacao.data?.numeroMP}
										</BoldText>
									</span>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									{' '}
									<span>
										<BoldText>Solicitante</BoldText>
										<p>
											{dataProtocolo.nome && toTitleCase(dataProtocolo.nome)}
										</p>
									</span>
								</Col>
								<Col span={12}>
									{' '}
									<span>
										<BoldText>Solicitação</BoldText>
										<p>Encontre seu Pai Aqui: Reconhecimento de Paternidade</p>
									</span>
								</Col>
							</Row>
							<Row>
								<Col span={6}>
									{' '}
									<span>
										<BoldText>Posto Poupatempo</BoldText>
										<p>{nomePosto}</p>
									</span>
								</Col>
								<Col span={6}>
									{' '}
									<span>
										<BoldText>E-mail</BoldText>
										<p>{dataProtocolo.email}</p>
									</span>
								</Col>
								<Col span={6}>
									{' '}
									<span>
										<BoldText>Data de solicitação</BoldText>
										<p>
											{solicitarReconhecimentoPaternidade.request
												?.dataDaSolicitacao &&
												getDate(
													solicitarReconhecimentoPaternidade.request
														?.dataDaSolicitacao,
												)}
										</p>
									</span>
								</Col>
								<Col span={6}>
									{' '}
									<span>
										<BoldText>Horário</BoldText>
										<p>
											{' '}
											{solicitarReconhecimentoPaternidade.request
												?.dataDaSolicitacao &&
												getClock(
													solicitarReconhecimentoPaternidade.request
														?.dataDaSolicitacao,
												)}
										</p>
									</span>
								</Col>
							</Row>
						</Col>
					</BoxConfirmation>
					<BoxConfirmationQRCode>
						<Col span={22}>
							<BoldText>Acompanhe sua solicitação pelos nossos canais</BoldText>
							<StyledUl>
								<li>
									Para acessar, aponte a camera ao QR Code utilizando seu
									celular ou tablet.
								</li>
								<li>
									Se não conseguir escanear, acesse{' '}
									<BoldText>Acompanhar reconhecimento de paternidade</BoldText>{' '}
									pelo nosso site www.poupatempo.sp.gov.br, aplicativo do
									Poupatempo.SP.GOV.BR ou WhatsApp (11) 95220-2974.{' '}
								</li>
							</StyledUl>
						</Col>
						<Col span={2}>
							<QRCodeBox>
								<QRCodeImg />
							</QRCodeBox>
						</Col>
					</BoxConfirmationQRCode>
					<Row justify="center">
						<Col style={{ textAlign: 'center' }}>
							<ButtonImage
								src="imprimir"
								onClick={() => {
									const divs: any = document.querySelectorAll('section > div');

									divs.forEach((div: any, index: any) => {
										if (index !== divs.length - 1) {
											div.style.display = 'none';
										}
									});

									window.print();

									divs.forEach((div: any, index: any) => {
										if (index !== divs.length - 1) {
											div.style.display = 'block';
										}
									});

									const divStep = document.querySelectorAll(
										'section > div.ant-steps',
									);

									divStep.forEach((div: any) => {
										div.style.display = 'flex';
									});
								}}
							/>
						</Col>
						<Col offset={1}>
							<ButtonImage
								type="button"
								src="finalizar-processo"
								onClick={() => history.push('/ministerio-publico')}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Confirmacao;
