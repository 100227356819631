import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarParametroSistemaRequest } from './types';

export function cadastrarParametroSistemaRequest(
	token: string,
	data: CadastrarParametroSistemaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA,
		token,
		payload: data,
	};
}
export function cadastrarParametroSistemaSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function cadastrarParametroSistemaFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function cadastrarParametroSistemaClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
