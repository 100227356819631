import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarMenusRequest } from './types';

export function atualizarMenusRequest(
	token: string,
	data: AtualizarMenusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENUS,
		token,
		payload: data,
	};
}
export function atualizarMenusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENUS_SUCCESS,
		payload,
	};
}
export function atualizarMenusFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENUS_FAILURE,
		payload: null,
	};
}
export function atualizarMenusClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENUS_CLEAR,
		payload: null,
	};
}
