import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ExcluirMensagemErroRequest } from './types';

// ACTIONS
import {
	excluirMensagemErroSuccess,
	excluirMensagemErroFailure,
} from './actions';

function* excluirMensagemErro(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ExcluirMensagemErroRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens-erro/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirMensagemErroSuccess(response));
	} else {
		yield put(excluirMensagemErroFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_MENSAGEM_ERRO_REQUEST, excluirMensagemErro),
]);
