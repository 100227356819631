import { combineReducers } from 'redux';

import admin from './admin/combineReducers';
import administrar from './administrar/combineReducers';
import loginUnico from './loginUnico/reducer';
import usuarios from './usuarios/combineReducers';
import atendimento from './atendimento/combineReducers';
import estatistica from './estatistica/combineReducers';
import relatorios from './relatorios/combineReducers';
import estatisticas from './estatisticas/combineReducers';

export default combineReducers({
	admin,
	administrar,
	loginUnico,
	usuarios,
	atendimento,
	estatistica,
	relatorios,
	estatisticas,
});
