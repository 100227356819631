import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarParametroSistemaRequest } from './types';

// ACTIONS
import {
	atualizarParametroSistemaSuccess,
	atualizarParametroSistemaFailure,
} from './actions';

function* atualizarParametroSistema(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarParametroSistemaRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`parametros-sistema/${payload.id}`,
		body,
	);

	console.log('payload>>>', payload);

	if (response.status === 200) {
		yield put(atualizarParametroSistemaSuccess(response));
	} else {
		yield put(atualizarParametroSistemaFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_PARAMETRO_SISTEMA, atualizarParametroSistema),
]);
