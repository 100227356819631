import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarSufixoRequest } from './types';

// ACTIONS
import { cadastrarSufixoSuccess, cadastrarSufixoFailure } from './action';

function* cadastrarSufixo(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarSufixoRequest } = request;

	const body = { ...payload };

	delete body.codigo;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`sufixos`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarSufixoSuccess(response));
	} else {
		yield put(cadastrarSufixoFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_SUFIXO, cadastrarSufixo)]);
