import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirFuncionalidadeRequest } from './types';

export function excluirFuncionalidadeRequest(
	token: string,
	data: ExcluirFuncionalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE,
		token,
		payload: data,
	};
}
export function excluirFuncionalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function excluirFuncionalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirFuncionalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
