export enum Types {
	EXCLUIR_LOCALIDADE = '@SGU/EXCLUIR_LOCALIDADE',
	EXCLUIR_LOCALIDADE_SUCCESS = '@SGU/EXCLUIR_LOCALIDADE_SUCCESS',
	EXCLUIR_LOCALIDADE_FAILURE = '@SGU/EXCLUIR_LOCALIDADE_FAILURE',
	EXCLUIR_LOCALIDADE_CLEAR = '@SGU/EXCLUIR_LOCALIDADE_CLEAR',
}

export interface ExcluirLocalidade {
	status: number;
	data: null | LocalidadeData;
}

export interface IRequestWithHeaderLocalidadeDelete {
	body: ExcluirLocalidadeRequest;
	headers?: any;
}
interface LocalidadeData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirLocalidadeRequest {
	idUsuario?: number | string;
	id?: number | string;
	codigo: number | string;
	digito: number | string;
	nome: string;
	idUnidade: number | string;
	idOrgao: number | string;
	tipo: number | string;
	funcionalidades: number[];
}
