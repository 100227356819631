import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AlteraSenhaRequest } from './types';

export function alteraSenhaRequest(
	token: string,
	data: AlteraSenhaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_SENHA_REQUEST,
		token,
		payload: data,
	};
}
export function alteraSenhaSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_SENHA_SUCCESS,
		payload,
	};
}
export function alteraSenhaFailure(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_SENHA_FAILURE,
		payload,
	};
}

export function alteraSenhaClear(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_SENHA_CLEAR,
		payload,
	};
}
