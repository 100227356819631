import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarMensagemErroRequest } from './types';

// ACTIONS
import {
	atualizarMensagemErroSuccess,
	atualizarMensagemErroFailure,
} from './actions';

function* atualizarMensagemErro(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarMensagemErroRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens-erro/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMensagemErroSuccess(response));
	} else {
		yield put(atualizarMensagemErroFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_MENSAGEM_ERRO_REQUEST, atualizarMensagemErro),
]);
