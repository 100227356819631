import React from 'react';
// ROUTING
import ReactDOM from 'react-dom';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
// REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// ANT DESIGN
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { store, persistor } from './store';
import App from './App';

const testMode =
	process.env.REACT_APP_ENVIRONMENT !== undefined &&
	process.env.REACT_APP_ENVIRONMENT === 'TEST';

const { origin } = window.location;

let domain = '';

if (
	origin === 'https://www.poupatempo4g.des.sp.gov.br' ||
	origin === 'https://www.homologa.poupatempo4g.sp.gov.br' ||
	origin === 'http://localhost:3000' ||
	origin === 'https://10.200.13.36' ||
	origin === 'https://10.200.13.37'
) {
	domain = 'https://rhsso.idp-hml.sp.gov.br';
} else {
	domain = 'https://idp.sp.gov.br';
}

const authConfig: TAuthConfig = {
	clientId: 'poupatempo-balcaounico4.0',
	authorizationEndpoint: `${domain}/auth/realms/idpsp/protocol/openid-connect/auth`,
	tokenEndpoint: `${domain}/auth/realms/idpsp/protocol/openid-connect/token`,
	logoutEndpoint: `${domain}/auth/realms/idpsp/protocol/openid-connect/logout`,
	redirectUri: `${origin}`,
	scope:
		'openid email profile govbr_confiabilidades api:mp.paternidade api:mp.paternidade.portal api:integrador.seduc-responsaveis.consultas api:integrador.seduc-responsaveis.atualizacoes api:integrador.seduc-dominios.consultas api:integrador.seduc-inscricoes.consultas api:integrador.seduc-inscricoes.atualizacoes api:integrador.seduc-fichas.consultas api:integrador.seduc-alunos.consultas api:integrador.seduc-fichas.atualizacoes api:cdesp.bcadastro.fisica.cpf.search api:cdesp.bcadastro.juridica.cnpj.search api:integrador.poupafila.consultas api:integrador.bu-ppt-dominios.read api:integrador.bu-ppt-dominios.upsert api:cdhu.mutuarios api:integrador.iirgd-bu-ppt.consultas api:integrador.iirgd-bu-ppt.atualizacao.senha',
	autoLogin: false,
	onRefreshTokenExpire: () => null,
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ConfigProvider locale={ptBR}>
				{testMode ? (
					<App />
				) : (
					<AuthProvider authConfig={authConfig}>
						<App />
					</AuthProvider>
				)}
			</ConfigProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);
