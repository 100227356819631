import produce from 'immer';

// TYPES
import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarLocalidades } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ConsultarLocalidades = {
	status: 0,
	data: null,
	enum: [],
	totalRegistrosConsulta: 0,
};

export default function consultarLocalidadesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarLocalidades {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_LOCALIDADES_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_LOCALIDADES_SUCCESS: {
				draft.enum = toSelect(action.payload.data, 'nome', 'id');
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}

			case Types.CONSULTAR_LOCALIDADES_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_LOCALIDADES_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
