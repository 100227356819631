import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMotivoRequest } from './types';

export function cadastrarMotivoRequest(
	token: string,
	data: CadastrarMotivoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MOTIVO,
		token,
		payload: data,
	};
}
export function cadastrarMotivoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MOTIVO_SUCCESS,
		payload,
	};
}
export function cadastrarMotivoFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function cadastrarMotivoClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MOTIVO_CLEAR,
		payload: null,
	};
}
