export enum Types {
	ATUALIZAR_LOCALIDADE_REQUEST = '@SGU/ATUALIZAR_LOCALIDADE_REQUEST',
	ATUALIZAR_LOCALIDADE_SUCCESS = '@SGU/ATUALIZAR_LOCALIDADE_SUCCESS',
	ATUALIZAR_LOCALIDADE_FAILURE = '@SGU/ATUALIZAR_LOCALIDADE_FAILURE',
	ATUALIZAR_LOCALIDADE_CLEAR = '@SGU/ATUALIZAR_LOCALIDADE_CLEAR',
}

export interface AtualizarLocalidade {
	status: number;
	data: null | LocalidadeData;
}

export interface IRequestWithHeaderLocalidadeEdit {
	body: AtualizarLocalidadeRequest;
	headers?: any;
}

interface LocalidadeData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarLocalidadeRequest {
	idUsuario?: number | string;
	id?: number | string;
	codigo: number | string;
	digito: number | string;
	nome: string;
	idUnidade: number | string;
	idOrgao: number | string;
	tipo: number | string;
	funcionalidades: number[];
}
