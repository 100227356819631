import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMenusRequest } from './types';

export function cadastrarMenusRequest(
	token: string,
	data: CadastrarMenusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENUS,
		token,
		payload: data,
	};
}
export function cadastrarMenusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENUS_SUCCESS,
		payload,
	};
}
export function cadastrarMenusFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENUS_FAILURE,
		payload: null,
	};
}
export function cadastrarMenusClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MENUS_CLEAR,
		payload: null,
	};
}
