import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMotivoRequest } from './types';

export function excluirMotivoRequest(
	token: string,
	data: ExcluirMotivoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MOTIVO,
		token,
		payload: data,
	};
}
export function excluirMotivoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MOTIVO_SUCCESS,
		payload,
	};
}
export function excluirMotivoFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function excluirMotivoClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MOTIVO_CLEAR,
		payload: null,
	};
}
