import { takeLatest, call, put, all } from 'redux-saga/effects';

// GENERICS
import { cleanEmpty } from 'utils/genericFunctions';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultarMensagensErroRequest } from './types';

// ACTIONS
import {
	consultarMensagensErroSuccess,
	consultarMensagensErroFailure,
} from './actions';

function* consultarMensagensErro(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarMensagensErroRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMensagensErroRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens-erro`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMensagensErroSuccess(response));
	} else {
		yield put(consultarMensagensErroFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MENSAGENS_ERRO_REQUEST, consultarMensagensErro),
]);
