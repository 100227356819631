import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarLocalidadesAssociadasRequest, Types } from './types';

export function consultarLocalidadesAssociadaRequest(
	token: string,
	payload: ConsultarLocalidadesAssociadasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST,
		token,
		payload,
	};
}
export function consultarLocalidadesAssociadaSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_SUCCESS,
		payload,
	};
}
export function consultarLocalidadesAssociadaFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadesAssociadaClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_CLEAR,
		payload: null,
	};
}
