import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarParametrosLocalidadeRequest, Types } from './types';

export function consultarParametrosLocalidadeRequest(
	token: string,
	payload: ConsultarParametrosLocalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE,
		token,
		payload,
	};
}
export function consultarParametrosLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function consultarParametrosLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarParametrosLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_CLEAR,
		payload: null,
	};
}
