import { takeLatest, call, put, all } from 'redux-saga/effects';
import { v4 } from 'uuid';
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { ReducerActionRotaSP } from 'store/modules/types';
import { AtualizarPortalSolicitacaoRequest, Types } from './types';
import {
	atualizarSolicitacaoPortalSuccess,
	atualizarSolicitacaoPortalFailure,
} from './actions';

function* atualizarSolicitacaoPortalRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarPortalSolicitacaoRequest } = request;

	const body = { ...payload, idAtendimento: v4() };
	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		`portal/solicitacoes/${payload.quemFezOPedido}`,
		body,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(atualizarSolicitacaoPortalSuccess(response));
	} else if (response) {
		yield put(atualizarSolicitacaoPortalFailure(response));
	}
}

export default all([
	takeLatest(
		Types.ATUALIZAR_SOLICITACAO_PORTAL_REQUEST,
		atualizarSolicitacaoPortalRequest,
	),
]);
