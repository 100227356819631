import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_IIRGD_BU_PPT } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { Types, LogonRequest } from './types';

// ACTIONS
import { logonSuccess, logonFailure } from './actions';

function* logonRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: LogonRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_IIRGD_BU_PPT,
		`login`,
		undefined,
		undefined,
		undefined,
		undefined,
		payload,
		undefined,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(logonSuccess(response.data));
	} else if (
		response.data.status === 400 &&
		response.data.messages[0] === 'NOVAPASSWORD'
	) {
		yield put(logonFailure(response));
		yield put(clearNotifications());
	} else {
		yield put(logonFailure(response));
	}
}

export default all([takeLatest(Types.LOGON_REQUEST, logonRequest)]);
