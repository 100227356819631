import React, { useCallback, useContext, useEffect } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_AAC_RESULTADO_NUMERAL,
	ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
} from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { limpaAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/actions';
import { pesquisaCITAAACRequest } from 'store/modules/api/aac/pesquisaCITA/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import RG from 'components/Common/Form/Fields/RG';
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import Divider from 'components/Common/Divider';

// utils
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { limparMascara } from 'utils/genericFunctions';
import InputMask from 'components/Common/Form/Input/InputMask';
import hasError from 'utils/getFormErrors';
import {
	consultaCpfAacRequest,
	consultaCpfAacClear,
} from 'store/modules/api/aac/consutaCpf/actions';
import { SubTitle } from 'components/Common/Form/Input/styled';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import NavMenu from '../../components/NavMenu';
import { initialValues, schema } from './form';

// STYLED
import { ContentButton } from './styled';

const Numeral: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { pesquisaCitaAAC, consultaCpf } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { token } = useContext<IAuthContext>(AuthContext);

	useEffect(() => {
		dispatch(consultaCpfAacClear());
	}, [dispatch]);

	useEffect(() => {
		dispatch(limpaAtestadoAntecedentes());
		if (pesquisaCitaAAC.status === 200) {
			history.push(ROUTE_AAC_RESULTADO_NUMERAL);
		}
	}, [pesquisaCitaAAC, history, dispatch]);

	useEffect(() => {
		if (consultaCpf.status === 200 && consultaCpf.data) {
			// 	if (consultaCpf.data.codigoRetorno === 'C18') {
			// 		addNotifications({
			// 			errors: [
			// 				'Não possuí CIN emitida. Realize a pesquisa informando o RG do cidadão',
			// 			],
			// 		});
			// 	} else if (consultaCpf.data.codigoRetorno === 'C19') {
			// 		addNotifications({
			// 			errors: [
			// 				'CPF não localizado na base civil. Realize a pesquisa informando o RG do cidadão.',
			// 			],
			// 		});
			// 	} else
			if (consultaCpf.data.localizadoComCinEmitida) {
				const payload = {
					numeroRg: `${consultaCpf.data.numeroRg}-${consultaCpf.data.dcRg}`,
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				};

				dispatch(pesquisaCITAAACRequest(payload));
			}
		}
	}, [consultaCpf, dispatch, login.mainframe]);

	const handleResetForm = useCallback(
		async (setFieldValue, handleReset) => {
			await setFieldValue('RGempty', true);
			handleReset();
			dispatch(clearNotifications());
		},
		[dispatch],
	);

	const handleChange = (rg: { digitoRg: string; numeroRg: string }) => {
		if (errors.length && rg.numeroRg.length < 8 && !rg.digitoRg.length) {
			dispatch(clearNotifications());
		}
		if (
			pesquisaCitaAAC.rgPesquisado &&
			limparMascara(pesquisaCitaAAC.rgPesquisado) !==
				`${rg.numeroRg}${rg.digitoRg.length}`
		) {
			dispatch(clearNotifications());
		}
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Pesquisar Numeral">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						values.numeroRg = values.numeroRg.replace(/[_.-]+/g, '');

						values.cpf = values.cpf.replace(/[_.]+/g, '');

						if (values.numeroRg !== '') {
							const payload = {
								numeroRg: `${values.numeroRg}-${values.digitoRg}`,
								usuario: login.mainframe.idUsuario,
								senha: login.mainframe.senhaMainFrame,
							};

							dispatch(pesquisaCITAAACRequest(payload));
						} else {
							dispatch(consultaCpfAacRequest(token, values.cpf));
						}
					}}
				>
					{(formik: FormikProps<any>) => {
						const { handleReset, setFieldValue } = formik;
						return (
							<Form>
								<Row gutter={[0, 10]}>
									<Col span={10}>
										<Field
											titleAlign="start"
											as={InputMask}
											title="Nº da CIN de SP (CPF)"
											titleSize="lg"
											name="cpf"
											mask="999.999.999-99"
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue('cpf', e.target.value);
												dispatch(clearNotifications());

												if (formik.values.numeroRg !== '') {
													formik.setFieldValue('numeroRg', '');
												}
											}}
											disabled={formik.values.numeroRg !== ''}
											autoFocus
											error={hasError(formik.errors, 'cpf')}
										/>
									</Col>

									<Col span={9}>
										<div style={{ display: 'flex', height: '100%' }}>
											<SubTitle>
												<span>(Apenas números. Ex: 999.999.999-99)</span>
											</SubTitle>
										</div>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<RG
											titleAlign="start"
											title="RG de SP	"
											subtitle="(Apenas números e sem dígito. Ex: 99999999)"
											titleSize="lg"
											numero="numeroRg"
											digito="digitoRg"
											formik={formik}
											reset={!!formik.values.RGempty}
											result={rg => {
												setFieldValue('numeroRg', rg.numeroRg);
												setFieldValue('digitoRg', rg.digitoRg);
												handleChange(rg);

												if (formik.values.cpf !== '') {
													formik.setFieldValue('cpf', '');
												}
											}}
											disabled={formik.values.cpf !== ''}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<ContentButton justify="center">
											<ButtonImage
												onClick={() =>
													handleResetForm(setFieldValue, handleReset)
												}
												type="button"
												src="limpar"
											/>
											<ButtonImage type="submit" src="pesquisar" />
										</ContentButton>
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_IIRGD_PESQUISA_ANTECEDENTE} />
		</>
	);
};

export default Numeral;
