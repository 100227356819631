import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarFuncionalidadeRequest } from './types';

export function atualizarFuncionalidadeRequest(
	token: string,
	data: AtualizarFuncionalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE,
		token,
		payload: data,
	};
}
export function atualizarFuncionalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarFuncionalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarFuncionalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
