import styled from 'styled-components';

export const TEXT = styled.span`
	text-align: justify;
	line-height: initial;
	text-indent: 2em;
	font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
`;

export const TEXTBOLD = styled(TEXT)`
	font-weight: bold;
	display: block;
	width: 100%;
	margin-top: 10px;
`;

export const Container = styled.div`
	.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
		border-color: red;
	}

	.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
		color: red;
	}
`;
