import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_IIRGD_BU_PPT } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AlteraSenhaRequest } from './types';

// ACTIONS
import { alteraSenhaSuccess, alteraSenhaFailure } from './actions';

function* alteraSenhaRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: AlteraSenhaRequest } =
		request;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_IIRGD_BU_PPT,
		`senha`,
		undefined,
		payload,
		undefined,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(alteraSenhaSuccess(response.data));
	} else {
		yield put(alteraSenhaFailure(response));
	}
}

export default all([
	takeLatest(Types.ALTERA_SENHA_REQUEST, alteraSenhaRequest),
]);
