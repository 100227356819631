import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { ConsultarAndamentoSolicitacoesRequest, Types } from './types';
import {
	consultarAndamentoSolicitacoesSuccess,
	consultarAndamentoSolicitacoesFailure,
} from './actions';

function* consultarAndamentoSolicitacaoRequest(request: any) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarAndamentoSolicitacoesRequest } =
		request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		`v3/solicitacoes`,
		{ cpfCidadao: payload.cpfCidadao },
		undefined,
		undefined,
		undefined,
		{ cpfUsuario: payload.cpfUsuario },
	);

	if (response.status === 200) {
		yield put(consultarAndamentoSolicitacoesSuccess(response));
	} else {
		yield put(consultarAndamentoSolicitacoesFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_ANDAMENTO_PATERNIDADE_REQUEST,
		consultarAndamentoSolicitacaoRequest,
	),
]);
