export enum Types {
	EXCLUIR_ORGAO = '@SGU/EXCLUIR_ORGAO',
	EXCLUIR_ORGAO_SUCCESS = '@SGU/EXCLUIR_ORGAO_SUCCESS',
	EXCLUIR_ORGAO_FAILURE = '@SGU/EXCLUIR_ORGAO_FAILURE',
	EXCLUIR_ORGAO_CLEAR = '@SGU/EXCLUIR_ORGAO_CLEAR',
}

export interface ExcluirOrgao {
	status: number;
	data: null | IExcluirOrgao;
}

export interface IExcluirOrgao {
	status: string;
	statusMensagem: string;
}

export interface ExcluirOrgaoRequest {
	id?: number | string;
	descricao: string;
	codigoSefaz: number | string;
	mainframe: boolean;
}
