import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarLocalidadesAssociadasRequest, Types } from './types';

// ACTIONS
import {
	consultarLocalidadesAssociadaSuccess,
	consultarLocalidadesAssociadaFailure,
} from './actions';

function* consultarLocalidadesAssociadas(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarLocalidadesAssociadasRequest } =
		request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`localidades-associadas/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(
			consultarLocalidadesAssociadaSuccess({ response, type: payload.type }),
		);
	} else {
		yield put(consultarLocalidadesAssociadaFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST,
		consultarLocalidadesAssociadas,
	),
]);
