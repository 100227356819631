import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_BU_PPT_SGU_API } from 'services/rotasp/path';
import { IncluirEstatisticasRequest, Types } from './types';

// ACTIONS
import {
	incluirEstatisticasSuccess,
	incluirEstatisticasFailure,
} from './actions';

function* incluirEstatisticasRequest(request: ReducerAction) {
	const {
		payload,
		token,
	}: { payload: IncluirEstatisticasRequest; token?: string } = request;

	const { userIdLogin } = payload;

	delete payload.userIdLogin;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_BU_PPT_SGU_API,
		`estatisticas`,
		payload,
		{
			idUsuarioAutenticado: userIdLogin,
		},
	);

	if (response.status === 200 || response.status === 201) {
		yield put(incluirEstatisticasSuccess(response.data));
	} else {
		yield put(incluirEstatisticasFailure());
	}
}

export default all([
	takeLatest(Types.INCLUIR_ESTATISTICAS_REQUEST, incluirEstatisticasRequest),
]);
