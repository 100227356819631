import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarMenusRequest } from './types';

// ACTIONS
import { cadastrarMenusSuccess, cadastrarMenusFailure } from './action';

function* cadastrarMenus(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: CadastrarMenusRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`menu`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarMenusSuccess(response));
	} else if (response.error) {
		yield put(cadastrarMenusFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_MENUS, cadastrarMenus)]);
