import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarMensagemErroRequest } from './types';

export function atualizarMensagemErroRequest(
	token: string,
	data: AtualizarMensagemErroRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_REQUEST,
		token,
		payload: data,
	};
}
export function atualizarMensagemErroSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function atualizarMensagemErroFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function atualizarMensagemErroClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
