export enum Types {
	CONSULTAR_ANDAMENTO_PATERNIDADE_REQUEST = '@MP/CONSULTAR_ANDAMENTO_PATERNIDADE_REQUEST',
	CONSULTAR_ANDAMENTO_PATERNIDADE_SUCCESS = '@MP/CONSULTAR_ANDAMENTO_PATERNIDADE_SUCCESS',
	CONSULTAR_ANDAMENTO_PATERNIDADE_FAILURE = '@MP/CONSULTAR_ANDAMENTO_PATERNIDADE_FAILURE',
	CONSULTAR_ANDAMENTO_PATERNIDADE_CLEAR = '@MP/CONSULTAR_ANDAMENTO_PATERNIDADE_CLEAR',
}

export interface consultarAndamentoSolicitacoes {
	status: number;
	data: ConsultarAndamentoSolicitacoesData[] | null;
}

export interface ConsultarAndamentoSolicitacoesRequest {
	cpfUsuario: string;
	cpfCidadao: string;
}

export interface ConsultarAndamentoSolicitacoesData {
	idSolicitacao: string;
	numeroMP: string;
	situacaoAtual: string;
	localizacaoAtual: string;
}
