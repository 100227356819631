import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IRequestWithHeaderLocalidadeCreate } from './types';

// ACTIONS
import {
	cadastrarLocalidadeSuccess,
	cadastrarLocalidadeFailure,
} from './actions';

function* cadastrarLocalidade(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRequestWithHeaderLocalidadeCreate } = request;

	const { body, headers } = { ...payload };

	delete body.id;
	delete body.idUnidade;

	// ID USUÁRIO MOCADO
	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`localidades`,
		body,
		headers,
		undefined,
		undefined,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarLocalidadeSuccess(response));
	} else {
		yield put(cadastrarLocalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_LOCALIDADE_REQUEST, cadastrarLocalidade),
]);
