// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	RequestAtualizarAtendente,
	ConsultaUsuarioPendente,
	Types,
} from './types';

export function atualizarAtendenteRequest(
	atualizacao: RequestAtualizarAtendente,
	resultado: ConsultaUsuarioPendente[],
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ATENDENTE,
		payload: {
			atualizacao,
			resultado,
		},
	};
}

export function atualizarAtendenteSuccess(
	response: ConsultaUsuarioPendente,
	resultado: ConsultaUsuarioPendente[],
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ATENDENTE_SUCCESS,
		payload: {
			response,
			resultado,
		},
	};
}

export function atualizarAtendenteFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_ATENDENTE_FAILURE,
		payload: null,
	};
}

export function limpaAtualizarAtendente(): ReducerActionRotaSP {
	return {
		type: Types.LIMPA_ATUALIZAR_ATENDENTE,
		payload: null,
	};
}
