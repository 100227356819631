import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, LogonRequest } from './types';

export function logonRequest(
	token: string,
	payload: LogonRequest,
): ReducerActionRotaSP {
	return {
		type: Types.LOGON_REQUEST,
		token,
		payload,
	};
}
export function logonSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.LOGON_SUCCESS,
		payload,
	};
}
export function logonFailure(payload: object): ReducerActionRotaSP {
	return {
		type: Types.LOGON_FAILURE,
		payload,
	};
}

export function logonClear(payload: object): ReducerActionRotaSP {
	return {
		type: Types.LOGON_CLEAR,
		payload,
	};
}
