import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, SalvarAtendimento } from './types';

export const INITIAL_STATE: SalvarAtendimento = {
	status: 0,
	data: null,
	tipo: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SalvarAtendimento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SALVAR_ATENDIMENTO_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.tipo = action.payload.situacao;
				break;
			}

			case Types.SALVAR_ATENDIMENTO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.SALVAR_ATENDIMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.tipo = INITIAL_STATE.tipo;
				break;
			}

			default:
		}
		return draft;
	});
}
