import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirParametroSistemaRequest } from './types';

export function excluirParametroSistemaRequest(
	token: string,
	data: ExcluirParametroSistemaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA,
		token,
		payload: data,
	};
}
export function excluirParametroSistemaSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function excluirParametroSistemaFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function excluirParametroSistemaClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
