// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { SalvarAtendimentoRequest, Types } from './types';

export function salvarAtendimentoRequest(
	token: string,
	payload: SalvarAtendimentoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.SALVAR_ATENDIMENTO_REQUEST,
		token,
		payload,
	};
}
export function salvarAtendimentoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.SALVAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function salvarAtendimentoFailure(): ReducerActionRotaSP {
	return {
		type: Types.SALVAR_ATENDIMENTO_FAILURE,
		payload: null,
	};
}
export function salvarAtendimentoClear(): ReducerActionRotaSP {
	return {
		type: Types.SALVAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
