import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Modal, Alert, Row, Col } from 'antd';

// ROUTES
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL } from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { atendimentoEncerrado } from 'store/modules/api/aac/painelDeControle/actions';
import { ApplicationState } from 'store';
import {
	ConsultaUsuarioPendente,
	UsuarioPendente,
} from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';
import { emissaoAtestadoPainelRequest } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoNormal/actions';
import { emissaoExigenciasRequest } from 'store/modules/api/aac/novoPainelDeControle/emissaoExigencias/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Divider from 'components/Common/Divider';

// UTILS
import { unformatDate } from 'utils/genericFunctions';

// COMPONENTS
import { RequestEmissaoAtestado } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoNormal/types';
import { encerrarAtendimentoRequest } from 'store/modules/api/aac/novoPainelDeControle/encerrarAtendimento/actions';
import { RequestEmissaoAtestadoLiberacaoPositiva } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/types';
import { emissaoAtestadoLiberacaoPositivaRequest } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/actions';
import { RequestEmissaoExigencia } from 'store/modules/api/aac/novoPainelDeControle/emissaoExigencias/types';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import LiberacaoNormal from './LiberacaoNormal';
import LiberacaoPositiva from './LiberacaoPositiva';
import Exigencia from './Exigencia';

// STYLED
import { Content } from './styled';
import NavMenu from '../../components/NavMenu';

const PainelDeControleResultado: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { params }: any = useRouteMatch();
	const { id }: { id: string } = params;

	const { encerrarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);

	const {
		emissaoAtestadoPainel,
		emissaoExigencias,
		usuarioPendente,
		emissaoAtestadoLiberacaoPositiva,
	} = useSelector((state: ApplicationState) => state.api.aac);

	const [protocoloBase64, setProtocoloBase64] = useState<{
		file: string;
		message: string;
	}>({
		file: '',
		message: '',
	});

	const [results, setResults] = useState<UsuarioPendente>({
		status: 0,
		resultado: null,
	});

	const { token } = useContext<IAuthContext>(AuthContext);

	useEffect(() => {
		if (usuarioPendente.status === 200) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setResults(usuarioPendente);
		} else if (usuarioPendente.status === 0) {
			history.push(ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL);
		}

		if (encerrarAtendimento?.mensagem) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [encerrarAtendimento, history, usuarioPendente]);

	// useEffect(() => {
	// 	if (
	// 		emissaoAtestadoPainel.status === 200 &&
	// 		emissaoAtestadoPainel.data.arquivoPDF !== ''
	// 	) {
	// 		setProtocoloBase64({
	// 			file: emissaoAtestadoPainel.data.arquivoPDF,
	// 			message: 'Atestado de Antecedentes',
	// 		});
	// 		dispatch(limpaAtestadoPainel());
	// 	}
	// }, [emissaoAtestadoPainel, dispatch]);

	useEffect(() => {
		if (
			emissaoAtestadoLiberacaoPositiva.status === 200 &&
			emissaoAtestadoLiberacaoPositiva.data.pdfBase64 !== ''
		) {
			setProtocoloBase64({
				file: emissaoAtestadoLiberacaoPositiva.data.pdfBase64,
				message: 'Atestado de Antecedentes - Liberação Positiva',
			});
		}
	}, [emissaoAtestadoLiberacaoPositiva, dispatch]);

	useEffect(() => {
		if (
			emissaoAtestadoPainel.status === 200 &&
			emissaoAtestadoPainel.data.pdfBase64 !== ''
		) {
			setProtocoloBase64({
				file: emissaoAtestadoPainel.data.pdfBase64,
				message: 'Atestado de Antecedentes - Liberação Normal',
			});
		}
	}, [emissaoAtestadoPainel, dispatch]);

	useEffect(() => {
		if (
			emissaoExigencias.status === 200 &&
			emissaoExigencias.data.pdfBase64 !== ''
		) {
			setProtocoloBase64({
				file: emissaoExigencias.data.pdfBase64 || '',
				message: 'Pendências para o Atestado',
			});
		}
	}, [emissaoExigencias, dispatch]);

	const handleShutDown = useCallback(() => {
		if (results.resultado && results.resultado[Number(id)]) {
			const result = results.resultado[Number(id)];

			Modal.confirm({
				title: 'Confirma realmente o encerramento?',
				okText: 'Confirmar',
				cancelText: 'Cancelar',
				onOk() {
					dispatch(
						encerrarAtendimentoRequest(token, {
							idIdentificacao: result.id?.trim(),
						}),
					);
				},
				onCancel() {},
			});
		}
	}, [results.resultado, id, dispatch, token]);

	const handleServiceClosed = useCallback(
		(valueId: string, message: string) => {
			if (message) {
				dispatch(atendimentoEncerrado(valueId));
			}
		},
		[dispatch],
	);

	const converterData = (data: string | number) => {
		if (!data) {
			return '';
		}
		if (typeof data === 'string' && data.indexOf('/') !== -1) {
			return unformatDate(data);
		}
		if (typeof data === 'number') {
			const dataTimeStamp = new Date(data).toLocaleDateString('pt-BR');
			return unformatDate(dataTimeStamp);
		}
		if (typeof data === 'string' && data.indexOf('-') !== -1) {
			const verificaData = data.split('-');
			if (
				verificaData[0] &&
				verificaData[0].length === 2 &&
				verificaData[2] &&
				verificaData[2].length === 4
			) {
				const returnData = data.replaceAll('-', '/');
				return unformatDate(returnData);
			}
			if (
				verificaData[0] &&
				verificaData[0].length === 4 &&
				verificaData[2] &&
				verificaData[2].length === 2
			) {
				return data;
			}
		}
		return '';
	};

	const getValidacaoDocumento = (valores: ConsultaUsuarioPendente) => {
		const numeroRg = valores.numeroRg?.toString().trim() || '';
		const expReg = new RegExp('[a-zA-Z]');
		const temLetra = expReg.test(numeroRg);

		if (temLetra) {
			return {
				numeroDocumento: numeroRg,
				numeroRg: 0,
				digitoRg: '',
			};
		}

		return {
			numeroDocumento: '',
			numeroRg,
			digitoRg: valores.dc?.toString().trim() || '',
		};
	};

	const handlePrintLiberacaoNormal = useCallback(() => {
		if (
			results.resultado &&
			results.resultado[Number(id)].tipoLiberacao === 'N'
		) {
			const camposSeNominalOuNumeral: {
				numeroDocumento: string;
				numeroRg: string | number;
				digitoRg: string;
			} = getValidacaoDocumento(results.resultado[Number(id)]);
			const payload: RequestEmissaoAtestado = {
				cpf: results.resultado[Number(id)].cpf,
				dcCpf: results.resultado[Number(id)].dcCpf,
				dataNascimento: null,
				dataNascimentoTs: `${
					results.resultado[Number(id)].dataNascimento
				} 12:00:00`,
				// digitoRg: results.resultado[Number(id)].dc,
				nome: results.resultado[Number(id)].nome,
				nomePai: results.resultado[Number(id)].nomePai,
				nomeMae: results.resultado[Number(id)].nomeMae,
				nomeSocial: results.resultado[Number(id)].nomeSocial,
				docProcurador: results.resultado[Number(id)].docProcurador,
				nomeProcurador: results.resultado[Number(id)].nomeProcurador,
				tipoDocApresentado: results.resultado[Number(id)].tipoDocApresentado,
				// numeroDocumento: `${results.resultado[Number(id)].numeroRg} ${
				// 	results.resultado[Number(id)].dc
				// }`,
				// numeroRg: results.resultado[Number(id)].numeroRg,
				sexo:
					results.resultado[Number(id)].sexo === 'M' ? 'MASCULINO' : 'FEMININO',
				resultado: results.resultado[Number(id)].resultado?.trim(),
				codigoValidacao:
					results.resultado[Number(id)].codigoValidacao?.trim() ?? '',
				codigoRetorno: results.resultado[Number(id)].retorno,
				tipoLiberacao: results.resultado[Number(id)].tipoLiberacao,
				origem: 'Balcao Unico',
				...camposSeNominalOuNumeral,
			};
			dispatch(emissaoAtestadoPainelRequest(payload));
		}
	}, [results.resultado, id, dispatch]);

	const handlePrintLiberacaoPositiva = useCallback(() => {
		if (
			results.resultado &&
			results.resultado[Number(id)].tipoLiberacao === 'P'
		) {
			const camposSeNominalOuNumeral: {
				numeroDocumento: string;
				numeroRg: string | number;
				digitoRg: string;
			} = getValidacaoDocumento(results.resultado[Number(id)]);
			const payload: RequestEmissaoAtestadoLiberacaoPositiva = {
				cpf: results.resultado[Number(id)].cpf,
				dcCpf: results.resultado[Number(id)].dcCpf,
				nome: results.resultado[Number(id)].nome,
				nomePai: results.resultado[Number(id)].nomePai,
				nomeMae: results.resultado[Number(id)].nomeMae,
				nomeSocial: results.resultado[Number(id)].nomeSocial,
				docProcurador: results.resultado[Number(id)].docProcurador,
				nomeProcurador: results.resultado[Number(id)].nomeProcurador,
				tipoDocApresentado: results.resultado[Number(id)].tipoDocApresentado,
				dataNascimento: null,
				dataNascimentoTs: results.resultado[Number(id)].dataNascimento
					? `${results.resultado[Number(id)].dataNascimento} 12:00:00`
					: null,
				sexo: results.resultado[Number(id)].sexo,
				resultado: results.resultado[Number(id)].resultado.trim(),
				codigoValidacao: results.resultado[Number(id)].codigoValidacao?.trim(),
				codigoRetorno: results.resultado[Number(id)].retorno,
				tipoLiberacao: results.resultado[Number(id)].tipoLiberacao,
				positivaExecucao: results.resultado[Number(id)].positivaExecucao,
				positivaInicio: converterData(
					results.resultado[Number(id)].positivaInicio,
				),
				positivaTermino: converterData(
					results.resultado[Number(id)].positivaTermino,
				),
				positivaObservacao01:
					results.resultado[Number(id)].positivaObservacao01,
				positivaObservacao02:
					results.resultado[Number(id)].positivaObservacao02,
				positivaRegime: results.resultado[Number(id)].positivaRegime,
				origem: 'Balcao Unico',
				naoPgtoNumeroCertidao:
					usuarioPendente.resultado && usuarioPendente.resultado[Number(id)]
						? usuarioPendente.resultado[Number(id)].naoPgtoNumeroCertidao
						: undefined,
				naoPgtoDataCertidao:
					usuarioPendente.resultado && usuarioPendente.resultado[Number(id)]
						? usuarioPendente.resultado[Number(id)].naoPgtoDataCertidao
						: undefined,
				...camposSeNominalOuNumeral,
			};

			dispatch(emissaoAtestadoLiberacaoPositivaRequest(payload));
		}
	}, [results.resultado, id, usuarioPendente, dispatch]);

	const handlePrintExigencia = useCallback(() => {
		if (
			results.resultado &&
			results.resultado[Number(id)].tipoLiberacao === 'E'
		) {
			const camposSeNominalOuNumeral: {
				numeroDocumento: string;
				numeroRg: string | number;
				digitoRg: string;
			} = getValidacaoDocumento(results.resultado[Number(id)]);
			const payload: RequestEmissaoExigencia = {
				cpf: results.resultado[Number(id)].cpf,
				dcCpf: results.resultado[Number(id)].dcCpf,
				dataNascimento: null,
				dataNascimentoTs: `${
					results.resultado[Number(id)].dataNascimento
				} 12:00:00`,
				exigBO: results.resultado[Number(id)].exigBO,
				exigBODelegacia: results.resultado[Number(id)].exigBODelegacia,
				exigBONumero: results.resultado[Number(id)].exigBONumero,
				exigBaixaDesaparecido:
					results.resultado[Number(id)].exigBaixaDesaparecido?.trim(),
				exigCertidaoCriminais:
					results.resultado[Number(id)].exigCertidaoCriminais,
				exigCertidaoCriminais01:
					results.resultado[Number(id)].exigCertidaoCriminais01,
				exigCertidaoCriminais02:
					results.resultado[Number(id)].exigCertidaoCriminais02,
				exigCertidaoDistribuidor:
					results.resultado[Number(id)].exigCertidaoDistribuidor,
				exigCertidaoDistribuidor01:
					results.resultado[Number(id)].exigCertidaoDistribuidor01,
				exigCertidaoObjeto: results.resultado[Number(id)].exigCertidaoObjeto,
				exigCopiaApresentado:
					results.resultado[Number(id)].exigCopiaApresentado?.trim(),
				exigDelegacia01: results.resultado[Number(id)].exigDelegacia01,
				exigDelegacia02: results.resultado[Number(id)].exigDelegacia02,
				exigDelegacia03: results.resultado[Number(id)].exigDelegacia03,
				exigDelegacia04: results.resultado[Number(id)].exigDelegacia04,
				exigDelegacia05: results.resultado[Number(id)].exigDelegacia05,
				exigDelegacia06: results.resultado[Number(id)].exigDelegacia06,
				exigDelegacia07: results.resultado[Number(id)].exigDelegacia07,
				exigDelegacia08: results.resultado[Number(id)].exigDelegacia08,
				exigDelegacia09: results.resultado[Number(id)].exigDelegacia09,
				exigDelegacia10: results.resultado[Number(id)].exigDelegacia10,
				exigInstauracao01: results.resultado[Number(id)].exigInstauracao01,
				exigInstauracao02: results.resultado[Number(id)].exigInstauracao02,
				exigInstauracao03: results.resultado[Number(id)].exigInstauracao03,
				exigInstauracao04: results.resultado[Number(id)].exigInstauracao04,
				exigInstauracao05: results.resultado[Number(id)].exigInstauracao05,
				exigInstauracao06: results.resultado[Number(id)].exigInstauracao06,
				exigInstauracao07: results.resultado[Number(id)].exigInstauracao07,
				exigInstauracao08: results.resultado[Number(id)].exigInstauracao08,
				exigInstauracao09: results.resultado[Number(id)].exigInstauracao09,
				exigInstauracao10: results.resultado[Number(id)].exigInstauracao10,
				exigObservacao: results.resultado[Number(id)].exigObservacao,
				exigObservacao01: results.resultado[Number(id)].exigObservacao01,
				exigObservacao02: results.resultado[Number(id)].exigObservacao02,
				exigObservacao03: results.resultado[Number(id)].exigObservacao03,
				exigObservacao04: results.resultado[Number(id)].exigObservacao04,
				exigObservacao05: results.resultado[Number(id)].exigObservacao05,
				exigObservacao06: results.resultado[Number(id)].exigObservacao06,
				exigObservacao07: results.resultado[Number(id)].exigObservacao07,
				exigObservacao08: results.resultado[Number(id)].exigObservacao08,
				exigObservacao09: results.resultado[Number(id)].exigObservacao09,
				exigObservacao10: results.resultado[Number(id)].exigObservacao10,
				exigProcesso01: results.resultado[Number(id)].exigProcesso01,
				exigProcesso02: results.resultado[Number(id)].exigProcesso02,
				exigProcesso03: results.resultado[Number(id)].exigProcesso03,
				exigProcesso04: results.resultado[Number(id)].exigProcesso04,
				exigProcesso05: results.resultado[Number(id)].exigProcesso05,
				exigProcesso06: results.resultado[Number(id)].exigProcesso06,
				exigProcesso07: results.resultado[Number(id)].exigProcesso07,
				exigProcesso08: results.resultado[Number(id)].exigProcesso08,
				exigProcesso09: results.resultado[Number(id)].exigProcesso09,
				exigProcesso10: results.resultado[Number(id)].exigProcesso10,
				exigVara01: results.resultado[Number(id)].exigVara01,
				exigVara02: results.resultado[Number(id)].exigVara02,
				exigVara03: results.resultado[Number(id)].exigVara03,
				exigVara04: results.resultado[Number(id)].exigVara04,
				exigVara05: results.resultado[Number(id)].exigVara05,
				exigVara06: results.resultado[Number(id)].exigVara06,
				exigVara07: results.resultado[Number(id)].exigVara07,
				exigVara08: results.resultado[Number(id)].exigVara08,
				exigVara09: results.resultado[Number(id)].exigVara09,
				exigVara10: results.resultado[Number(id)].exigVara10,
				exigenciaIP01: results.resultado[Number(id)].exigenciaIP01,
				exigenciaIP02: results.resultado[Number(id)].exigenciaIP02,
				exigenciaIP03: results.resultado[Number(id)].exigenciaIP03,
				exigenciaIP04: results.resultado[Number(id)].exigenciaIP04,
				exigenciaIP05: results.resultado[Number(id)].exigenciaIP05,
				exigenciaIP06: results.resultado[Number(id)].exigenciaIP06,
				exigenciaIP07: results.resultado[Number(id)].exigenciaIP07,
				exigenciaIP08: results.resultado[Number(id)].exigenciaIP08,
				exigenciaIP09: results.resultado[Number(id)].exigenciaIP09,
				exigenciaIP10: results.resultado[Number(id)].exigenciaIP10,
				nome: results.resultado[Number(id)].nome,
				nomeMae: results.resultado[Number(id)].nomeMae,
				nomePai: results.resultado[Number(id)].nomePai,
				// numeroRg: Number(results.resultado[Number(id)].numeroRg),
				// NOVOS CAMPOS
				origem: 'Balcao Unico',
				codigoRetorno: results.resultado[Number(id)].retorno,
				codigoValidacao: results.resultado[Number(id)].codigoValidacao?.trim(),
				// digitoRg: results.resultado[Number(id)].dc,
				// numeroDocumento: results.resultado[Number(id)].numeroRg,
				exigenciaEvadido:
					results.resultado[Number(id)].exigenciaEvadido?.trim(),
				exigenciaPreso: results.resultado[Number(id)].exigenciaPreso,
				exigenciaProcurado: results.resultado[Number(id)].exigenciaProcurado,
				id: results.resultado[Number(id)].id?.trim(),
				nomeSocial: results.resultado[Number(id)].nomeSocial,
				tipoDocApresentado: results.resultado[Number(id)].tipoDocApresentado,
				// positivaExecucao: results.resultado[Number(id)].positivaExecucao,
				// positivaInicio: results.resultado[Number(id)].positivaInicio,
				// positivaObservacao01:
				// 	results.resultado[Number(id)].positivaObservacao01,
				// positivaObservacao02:
				// 	results.resultado[Number(id)].positivaObservacao02,
				// positivaRegime: results.resultado[Number(id)].positivaRegime,
				// positivaTermino: results.resultado[Number(id)].positivaTermino,
				resultado: results.resultado[Number(id)].resultado?.trim(),
				retorno: results.resultado[Number(id)].retorno,
				sexo:
					results.resultado[Number(id)].sexo === 'M' ? 'MASCULINO' : 'FEMININO',
				status: results.resultado[Number(id)].status,
				tipoAA: results.resultado[Number(id)].tipoAA,
				tipoDoc: results.resultado[Number(id)].tipoDoc,
				tipoLiberacao: results.resultado[Number(id)].tipoLiberacao,
				usuarioCentral: results.resultado[Number(id)].usuarioCentral,
				usuarioPosto: results.resultado[Number(id)].usuarioPosto,
				...camposSeNominalOuNumeral,
				docProcurador: results.resultado[Number(id)].docProcurador,
				nomeProcurador: results.resultado[Number(id)].nomeProcurador,
			};

			dispatch(emissaoExigenciasRequest(payload));
		}
	}, [results.resultado, id, dispatch]);

	const handlePrint = useCallback(() => {
		Modal.confirm({
			title: 'Confirma a impressão?',
			okText: 'Confirmar',
			cancelText: 'Cancelar',
			onOk() {
				if (results.resultado) {
					if (results.resultado[Number(id)].tipoLiberacao === 'N') {
						handlePrintLiberacaoNormal();
					} else if (results.resultado[Number(id)].tipoLiberacao === 'P') {
						handlePrintLiberacaoPositiva();
					} else if (results.resultado[Number(id)].tipoLiberacao === 'E') {
						handlePrintExigencia();
					}
				}
			},
			onCancel() {},
		});
	}, [
		results.resultado,
		id,
		handlePrintLiberacaoNormal,
		handlePrintLiberacaoPositiva,
		handlePrintExigencia,
	]);

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			{encerrarAtendimento?.mensagem?.length > 0 && (
				<Alert
					style={{ marginBottom: '50px' }}
					description={encerrarAtendimento.mensagem}
					message=""
					type="info"
					closable
					showIcon
				/>
			)}
			<Section title="Painel de Controle">
				{results.resultado && results.resultado[Number(id)] && (
					<>
						{results.resultado[Number(id)].tipoLiberacao === 'N' && (
							<LiberacaoNormal data={results.resultado[Number(id)]} />
						)}

						{results.resultado[Number(id)].tipoLiberacao === 'P' && (
							<LiberacaoPositiva data={results.resultado[Number(id)]} />
						)}

						{results.resultado[Number(id)].tipoLiberacao === 'E' && (
							<Exigencia data={results.resultado[Number(id)]} />
						)}

						<Content>
							<ButtonImage
								type="button"
								src="retornar"
								onClick={history.goBack}
							/>

							<ButtonImage
								type="button"
								src="imprimir"
								disabled={
									!!results.resultado[Number(id)]?.exigenciaProcurado?.trim() ||
									!!results.resultado[Number(id)]?.exigenciaPreso?.trim() ||
									!!results.resultado[Number(id)]?.exigenciaEvadido?.trim() ||
									encerrarAtendimento?.status === 200
								}
								onClick={handlePrint}
							/>

							<ButtonImage
								type="button"
								src="encerrar"
								disabled={encerrarAtendimento?.status === 200}
								onClick={handleShutDown}
							/>
						</Content>
					</>
				)}
			</Section>
			<ButtonVoltar
				onClick={() => handleServiceClosed(id, encerrarAtendimento.mensagem)}
			/>
			{protocoloBase64 && (
				<PDFViewer
					title={protocoloBase64.message}
					source={protocoloBase64.file}
					onClose={() =>
						setProtocoloBase64({
							file: '',
							message: '',
						})
					}
				/>
			)}
		</>
	);
};

export default PainelDeControleResultado;
