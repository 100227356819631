// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	DataAtestadoAntecedentes,
	RequestEmissaoAtestadoAntecedentes,
	Types,
} from './types';

export function emissaoAtestadoAntecedentesRequest(
	token: string,
	payload: RequestEmissaoAtestadoAntecedentes,
): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADO_ANTECEDENTES_REQUEST,
		token,
		payload,
	};
}

export function emissaoAtestadoAntecedentesSuccess(
	payload: DataAtestadoAntecedentes,
): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADO_ANTECEDENTES_SUCCESS,
		payload,
	};
}

export function emissaoAtestadoAntecedentesFailure(): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADO_ANTECEDENTES_FAILURE,
		payload: null,
	};
}

export function limpaAtestadoAntecedentes(): ReducerActionRotaSP {
	return {
		type: Types.LIMPA_ATESTADO_ANTECEDENTES,
		payload: null,
	};
}
