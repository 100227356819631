import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_ARQUIVOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { v4 } from 'uuid';
import { UploadArquivoRequest, Types } from './types';

// ACTIONS
import { uploadArquivoSuccess, uploadArquivoFailure } from './actions';

function* baixarArquivos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: UploadArquivoRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_ARQUIVOS_API,
		`ds/${v4()}`,
		payload.dataPayload,
		{
			'Content-Type': 'application/octet-stream',
		},
	);

	if (response.status === 200 || response.status === 201) {
		yield put(
			uploadArquivoSuccess({
				response: response.data,
				request: payload.detailsFile,
			}),
		);
	} else {
		yield put(uploadArquivoFailure(response.data));
	}
}

export default all([takeLatest(Types.UPLOAD_ARQUIVO_REQUEST, baixarArquivos)]);
