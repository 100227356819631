import produce from 'immer';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizaAtendente } from './types';

export const INITIAL_STATE: AtualizaAtendente = {
	status: 0,
	resultado: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): AtualizaAtendente {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_ATENDENTE: {
				break;
			}

			case Types.ATUALIZAR_ATENDENTE_SUCCESS: {
				draft.status = 200;
				draft.resultado = [
					...action.payload.resultado,
					...action.payload.response,
				];
				break;
			}

			case Types.ATUALIZAR_ATENDENTE_FAILURE: {
				draft.status = 400;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			case Types.LIMPA_ATUALIZAR_ATENDENTE: {
				draft.status = INITIAL_STATE.status;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			default:
		}

		return draft;
	});
}
