export enum Types {
	SOLICITAR_RECONHECIMENTO_PATERNIDADE_REQUEST = '@MP/SOLICITAR_RECONHECIMENTO_PATERNIDADE_REQUEST',
	SOLICITAR_RECONHECIMENTO_PATERNIDADE_SUCCESS = '@MP/SOLICITAR_RECONHECIMENTO_PATERNIDADE_SUCCESS',
	SOLICITAR_RECONHECIMENTO_PATERNIDADE_FAILURE = '@MP/SOLICITAR_RECONHECIMENTO_PATERNIDADE_FAILURE',
	SOLICITAR_RECONHECIMENTO_PATERNIDADE_CLEAR = '@MP/SOLICITAR_RECONHECIMENTO_PATERNIDADE_CLEAR',
}

export interface solicitarReconhecimentoPaternidade {
	status: number;
	data: SolicitarReconhecimentoPaternidadeData | null;
	request: SolicitarReconhecimentoPaternidadeRequest | null;
}

export interface SolicitarReconhecimentoPaternidadeRequest {
	filho: {
		nome?: string;
		dataDeNascimento?: string;
		sexo?: string;
		cpf?: {
			numero: string;
		};
		rg?: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia?: EnderecoResidencia;
		telefones?: Telefones[];
		email?: string;
		documentosPessoais?: DocumentosPessoais[];
	};
	supostoGenitor: {
		nome?: string;
		dataDeNascimento?: string;
		cidadeDeNascimento?: string;
		siglaUfDeNascimento?: string;
		sexo?: string;
		cpf?: {
			numero: string;
		};
		rg?: {
			numero: string;
			siglaUF: string;
		};
		nomeDaMae?: string;
		enderecoDeResidencia?: EnderecoResidencia;
		telefones?: Telefones[];
		email?: string;
		documentosPessoais?: DocumentosPessoais[];
	};
	responsavelLegal: {
		nome?: string;
		dataDeNascimento?: string;
		sexo?: string;
		cpf?: {
			numero: string;
		};
		rg?: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia?: EnderecoResidencia;
		telefones?: Telefones[];
		email?: string;
		documentosPessoais?: DocumentosPessoais[];
	};
	documentosAnexos?: DocumentosAnexos[];
	podeArcarComCustosDoProcesso?: string | boolean;
	comoFezOPedido?: string;
	quemFezOPedido?: string;
	dataDaSolicitacao?: string;
	outrasInformacoesParaLocalizacaoDoSupostoGenitor?: string;
	cpfUsuario?: string;
}

interface EnderecoResidencia {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	siglaUF: string;
}

export interface Telefones {
	tipo: string;
	ddd: string | number;
	numero: string | number;
}

interface DocumentosPessoais {
	nomeArquivo: string;
	// extensaoArquivo: string;
	urlArquivo: string;
	tipoDoDocumento: string;
}

interface DocumentosAnexos {
	nomeArquivo: string;
	// extensaoArquivo: string;
	urlArquivo: string;
	tipoDoDocumento: string;
}

export interface SolicitarReconhecimentoPaternidadeData {
	idSolicitacao: string;
}
