import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IRequestWithHeaderLocalidadeEdit } from './types';

// ACTIONS
import {
	atualizarLocalidadeSuccess,
	atualizarLocalidadeFailure,
} from './actions';

function* atualizarLocalidade(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRequestWithHeaderLocalidadeEdit } = request;

	const { body, headers } = { ...payload };

	const idLocalidade = body.id;

	delete body.id;
	delete body.idUsuario;

	// ID USUÁRIO MOCADO
	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`localidades/${idLocalidade}`,
		body,
		headers,
	);

	if (response.status === 200) {
		yield put(atualizarLocalidadeSuccess(response));
	} else {
		yield put(atualizarLocalidadeFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_LOCALIDADE_REQUEST, atualizarLocalidade),
]);
