import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarLocalidadesRequest, Types } from './types';

// ACTIONS
import {
	consultarLocalidadesSuccess,
	consultarLocalidadesFailure,
} from './actions';

function* consultarLocalidades(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarLocalidadesRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarLocalidadesRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`localidades`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarLocalidadesSuccess(response));
	} else {
		yield put(consultarLocalidadesFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_LOCALIDADES_REQUEST, consultarLocalidades),
]);
