import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarAndamentoSolicitacoesRequest, Types } from './types';

export function consultarAndamentoSolicitacoesRequest(
	token: string,
	payload: ConsultarAndamentoSolicitacoesRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ANDAMENTO_PATERNIDADE_REQUEST,
		token,
		payload,
	};
}
export function consultarAndamentoSolicitacoesSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ANDAMENTO_PATERNIDADE_SUCCESS,
		payload,
	};
}
export function consultarAndamentoSolicitacoesFailure(payload: object): any {
	return {
		type: Types.CONSULTAR_ANDAMENTO_PATERNIDADE_FAILURE,
		payload,
	};
}

export function consultarAndamentoSolicitacoesClear(): any {
	return {
		type: Types.CONSULTAR_ANDAMENTO_PATERNIDADE_CLEAR,
	};
}
