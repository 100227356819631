import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ExcluirOrgaoRequest } from './types';

// ACTIONS
import { excluirOrgaoSuccess, excluirOrgaoFailure } from './actions';

function* excluirOrgao(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: ExcluirOrgaoRequest } =
		request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`orgaos/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirOrgaoSuccess(response));
	} else {
		yield put(excluirOrgaoFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_ORGAO, excluirOrgao)]);
