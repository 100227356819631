export enum Types {
	INCLUIR_ESTATISTICAS_REQUEST = '@estatisticas/INCLUIR_ESTATISTICAS_REQUEST',
	INCLUIR_ESTATISTICAS_SUCCESS = '@estatisticas/INCLUIR_ESTATISTICAS_SUCCESS',
	INCLUIR_ESTATISTICAS_FAILURE = '@estatisticas/INCLUIR_ESTATISTICAS_FAILURE',
	INCLUIR_ESTATISTICAS_CLEAR = '@estatisticas/INCLUIR_ESTATISTICAS_CLEAR',
}

export interface IncluirEstatisticas {
	status: number;
	data: IncluirEstatisticasData | null;
	dataFailure: IncluirEstatisticasFailure | null;
}

interface IncluirEstatisticasData {
	id: number;
	descricaoSistemaOrigem: string;
	numeroProcesso: string;
	tipoDocumento: string;
	numeroDocumento: string;
	descricaoLocalidade: string;
	usuario: string;
	descricaoServico: string;
	flagConfronto: string;
	descricaoDedosEnviados: string;
	motivo: string;
	numeroVia: number;
	observacao: string;
	dataCadastro: string;
	totalizador: string;
}

export interface IncluirEstatisticasRequest {
	idCidadao: string;
	descricaoSistemaOrigem: string;
	tipoDocumento: string;
	numeroDocumento: string;
	codigoLocalidade: number;
	idOrgao: number;
	cpf: string;
	descricaoServico: string;
	flagConfronto: string;
	descricaoDedosEnviados: string;
	idMotivo: number;
	observacao: string;
	numeroVia: number;
	numeroProcesso: string;
	totalizador: string;
	userIdLogin: number;
}

export interface IncluirEstatisticasFailure {
	status: number;
	trace: string;
	messages: string[];
}
