import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMensagensRequest } from './types';

export function consultarMensagensRequest(
	token: string,
	data: ConsultarMensagensRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_REQUEST,
		token,
		payload: {
			page: data.current && data.current - 1,
			size: data.limite,
			search: data.search,
		},
	};
}
export function consultarMensagensSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_SUCCESS,
		payload,
	};
}
export function consultarMensagensFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_FAILURE,
		payload: null,
	};
}
export function consultarMensagensClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENSAGENS_CLEAR,
		payload: null,
	};
}
