import { takeLatest, put, all, call } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_AAC_API } from 'services/rotasp/path';

// STORE
import { ReducerActionRotaSP } from 'store/modules/types';

// ACTIONS
import {
	consultaUsuarioPendenteSuccess,
	consultaUsuarioPendenteFailure,
} from './actions';

// TYPES
import { Types, RequestConsultaUsuarioPendente } from './types';

function* consultaUsuarioPendenteRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: RequestConsultaUsuarioPendente } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_AAC_API,
		`central-liberacao/atendimentos`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaUsuarioPendenteSuccess(response.data));
	} else if (response.error) {
		yield put(consultaUsuarioPendenteFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_USUARIO_PENDENTE, consultaUsuarioPendenteRequest),
]);
