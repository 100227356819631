import { combineReducers } from 'redux';

import solicitarReconhecimentoPaternidade from './solicitarReconhecimentoPaternidade/reducer';
import consultarAndamentoSolicitacoes from './consultarAndamentoSolicitacoes/reducer';
import consultarDetalheSolicitacao from './consultarDetalheSolicitacao/reducer';

export default combineReducers({
	solicitarReconhecimentoPaternidade,
	consultarAndamentoSolicitacoes,
	consultarDetalheSolicitacao,
});
