import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMensagemErroRequest } from './types';

export function excluirMensagemErroRequest(
	token: string,
	data: ExcluirMensagemErroRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_REQUEST,
		token,
		payload: data,
	};
}
export function excluirMensagemErroSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function excluirMensagemErroFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function excluirMensagemErroClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
