import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarParametroLocalidadeRequest } from './types';

export function atualizarParametroLocalidadeRequest(
	token: string,
	data: AtualizarParametroLocalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE,
		token,
		payload: data,
	};
}
export function atualizarParametroLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarParametroLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarParametroLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_CLEAR,
		payload: null,
	};
}
