import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarMunicipiosRequest, Types } from './types';

// ACTIONS
import {
	consultarMunicipiosSuccess,
	consultarMunicipiosFailure,
} from './actions';

function* consultarMunicipiosRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarMunicipiosRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMunicipiosRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`municipios`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarMunicipiosSuccess(response));
	} else {
		yield put(consultarMunicipiosFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MUNICIPIOS_REQUEST, consultarMunicipiosRequest),
]);
