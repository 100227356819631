import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarFuncionalidadeRequest } from './types';

export function cadastrarFuncionalidadeRequest(
	token: string,
	data: CadastrarFuncionalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE,
		token,
		payload: data,
	};
}
export function cadastrarFuncionalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarFuncionalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarFuncionalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
