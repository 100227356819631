import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirParametroLocalidadeRequest } from './types';

export function excluirParametroLocalidadeRequest(
	token: string,
	data: ExcluirParametroLocalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE,
		token,
		payload: data,
	};
}
export function excluirParametroLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function excluirParametroLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirParametroLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_CLEAR,
		payload: null,
	};
}
