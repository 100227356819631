export enum Types {
	EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE = '@SGU/EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE',
	EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_SUCCESS = '@SGU/EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_SUCCESS',
	EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_FAILURE = '@SGU/EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_FAILURE',
	EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_CLEAR = '@SGU/EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_CLEAR',
}

export interface ExcluirFuncionalidadesRelacionadasLocalidade {
	status: number;
	data: null | ExcluirFuncionalidadesRelacionadasLocalidadeRequest;
}

export interface IExcluirFuncionalidadesRelacionadasLocalidade {
	status: string;
	statusMensagem: string;
}

export interface ExcluirFuncionalidadesRelacionadasLocalidadeRequest {
	id?: number | string;
}
