export enum Types {
	ATUALIZAR_SOLICITACAO_PORTAL_REQUEST = '@MP/ATUALIZAR_SOLICITACAO_PORTAL_REQUEST',
	ATUALIZAR_SOLICITACAO_PORTAL_SUCCESS = '@MP/ATUALIZAR_SOLICITACAO_PORTAL_SUCCESS',
	ATUALIZAR_SOLICITACAO_PORTAL_FAILURE = '@MP/ATUALIZAR_SOLICITACAO_PORTAL_FAILURE',
	LIMPAR_ATUALIZAR_SOLICITACAO_PORTAL = '@MP/LIMPAR_ATUALIZAR_SOLICITACAO_PORTAL',
}

export interface AtualizarSolicitacaoPortal {
	status: number;
	data: AtualizarPortalSolicitacaoData | null;
	dataRequest: AtualizarPortalSolicitacaoRequest | null;
}

interface EnderecoResidencia {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	siglaUF: string;
}

interface Telefones {
	tipo: string;
	ddd: string | number;
	numero: string | number;
}

interface DocumentosPessoais {
	idArquivo: string;
	nomeDoDocumento: string;
	tipoDoDocumento: string;
}

interface DocumentosAnexos {
	idArquivo: string;
	nomeDoDocumento: string;
	tipoDoDocumento: string;
}

export interface AtualizarPortalSolicitacaoRequest {
	filho: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
		documentosPessoais: DocumentosPessoais[];
	};
	supostoGenitor: {
		nome: string;
		dataDeNascimento: string;
		cidadeDeNascimento: string;
		siglaUfDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		nomeDaMae: string;
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
		documentosPessoais: DocumentosPessoais[];
	};
	responsavelLegal: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
		documentosPessoais: DocumentosPessoais[];
	};
	documentosAnexos: DocumentosAnexos[];
	podeArcarComCustosDoProcesso: string;
	comoFezOPedido: string;
	quemFezOPedido: string;
	dataDaSolicitacao: string;
	outrasInformacoesParaLocalizacaoDoSupostoGenitor: string;
}

export interface AtualizarPortalSolicitacaoData {
	id: number;
	filho: {
		nome: string;
		dataNascimento: string;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
		genitor: {
			nome: string;
			dataNascimento: string;
			cpf: string;
			email: string;
			sexo: string;
			endereco: {
				bairro: string;
				cep: string;
				cidade: string;
				complementoEndereco: string;
				logradouro: string;
				numero: string;
				uf: string;
			};
			rg: {
				numero: string;
				digito: string;
				uf: string;
			};
			telefones: [
				{
					ddd: string;
					numero: string;
					tipo: number;
				},
			];
			nomeMae: string;
			cidadeNascimento: string;
			ufNascimento: string;
			outrasInformacoes: string;
		};
	};
	responsavel: {
		nome: string;
		dataNascimento: string;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
	};
	destinatarioSolicitacao: string;
	aceiteTermo: boolean;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteAcrescNomePai: boolean;
	idAtendente: string;
	dataCriacao: string;
	dataModificacao: string;
	status: string;
	origem: string;
	protocolo: string;
	idCidadao: string;
	idAtendimento: string;
	emailContato: string;
	idPosto: number;
	nomePosto: string;
}
