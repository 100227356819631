import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarLocalidadeRequest, Types } from './types';

export function consultarLocalidadeRequest(
	token: string,
	payload: ConsultarLocalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADE_REQUEST,
		token,
		payload,
	};
}
export function consultarLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function consultarLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADE_CLEAR,
		payload: null,
	};
}
