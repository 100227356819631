import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarOrgaoRequest } from './types';

// ACTIONS
import { cadastrarOrgaoSuccess, cadastrarOrgaoFailure } from './actions';

function* cadastrarOrgao(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: CadastrarOrgaoRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`orgaos`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarOrgaoSuccess(response));
	} else {
		yield put(cadastrarOrgaoFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_ORGAO, cadastrarOrgao)]);
