export enum Types {
	SALVAR_ATENDIMENTO_REQUEST = '@atendimento/SALVAR_ATENDIMENTO_REQUEST',
	SALVAR_ATENDIMENTO_SUCCESS = '@atendimento/SALVAR_ATENDIMENTO_SUCCESS',
	SALVAR_ATENDIMENTO_FAILURE = '@atendimento/SALVAR_ATENDIMENTO_FAILURE',
	SALVAR_ATENDIMENTO_CLEAR = '@atendimento/SALVAR_ATENDIMENTO_CLEAR',
}

export interface SalvarAtendimento {
	status: number;
	data: SalvarAtendimentoData | null;
	tipo: null | 'OK' | 'RECUSADO';
}

export interface SalvarAtendimentoData {
	idCidadao: string;
	idAtendimento: string;
	cpf: string;
	msg: string;
	rg: string;
}

export interface SalvarAtendimentoRequest {
	cpfUsuario: string;
	motivoJustificativa: string | null;
	justificativa: string | null;
	codLocalidade: number;
	situacao: 'OK' | 'RECUSADO' | 'SEM_DADOS';
	descricaoServico: string;
	atendimento: {
		idCidadao: string | null;
		idAtendimento: string | null;
		cpf: string | null;
		msg: string | null;
		rg?: string | null;
	};
	orgao: {
		id: string;
		nome: string;
	};
	userIdLogin: number;
}
