import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { ConsultarDetalheSolicitacaoRequest, Types } from './types';
import {
	consultarDetalheSolicitacaoSuccess,
	consultarDetalheSolicitacaoFailure,
} from './actions';

function* consultarDetalheSolicitacaoRequest(request: any) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarDetalheSolicitacaoRequest } = request;
	const statusAllowed = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		`v3/solicitacoes/${payload.idSolicitacao}`,
		{ cpfCidadao: payload.cpfCidadao },
		undefined,
		undefined,
		undefined,
		{ cpfUsuario: payload.cpfUsuario },
		statusAllowed,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultarDetalheSolicitacaoSuccess(response));
	} else {
		yield put(consultarDetalheSolicitacaoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_DETALHE_SOLICITACAO_REQUEST,
		consultarDetalheSolicitacaoRequest,
	),
]);
