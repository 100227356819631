import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarPerfilRequest } from './types';

// ACTIONS
import { atualizarPerfilSuccess, atualizarPerfilFailure } from './action';

function* atualizarPerfil(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarPerfilRequest } = request;

	const body = { ...payload };

	delete body.id;
	delete body.idUsuarioAutenticado;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`perfis/${payload.id}`,
		body,
		{ idUsuarioAutenticado: payload.idUsuarioAutenticado },
	);

	if (response.status === 200) {
		yield put(atualizarPerfilSuccess(response));
	} else {
		yield put(atualizarPerfilFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_PERFIL, atualizarPerfil)]);
