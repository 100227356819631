import { ReducerActionRotaSP } from 'store/modules/types';

import { RequestConsultarMenus, Types } from './types';

export function consultarMenusRequest(
	token: string,
	data: RequestConsultarMenus,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENUS,
		token,
		payload: {
			page: data.current && data.current - 1,
			size: data.limite,
			nrOrdem: data.nrOrdem,
			id: data.idMenu,
			descricao: data.descricao,
			siglaMenu: data.siglaMenu,
		},
	};
}
export function consultarMenusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENUS_SUCCESS,
		payload,
	};
}
export function consultarMenusFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENUS_FAILURE,
		payload: null,
	};
}

export function consultarMenusClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENUS_CLEAR,
		payload: null,
	};
}
