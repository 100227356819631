import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_LOCALIDADES_REQUEST = '@SGU/CONSULTAR_LOCALIDADES_REQUEST',
	CONSULTAR_LOCALIDADES_SUCCESS = '@SGU/CONSULTAR_LOCALIDADES_SUCCESS',
	CONSULTAR_LOCALIDADES_FAILURE = '@SGU/CONSULTAR_LOCALIDADES_FAILURE',
	CONSULTAR_LOCALIDADES_CLEAR = '@SGU/CONSULTAR_LOCALIDADES_CLEAR',
}

export interface ConsultarLocalidades {
	status: number;
	data: LocalidadeData[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface LocalidadeData {
	id: number;
	codigo: number;
	digito: number;
	nome: string;
	tipo: string;
	unidade: string;
}

export interface Funcionalidade {
	id: number;
	descricao: string;
}

export interface ConsultarLocalidadesRequest {
	registroInicial?: number;
	limite?: number;
	page?: number;
	size?: number;
	id?: string | number | undefined;
	tipo?: string;
	current?: number;
	idOrgao?: number | string;
	codigo?: number | string;
	digito?: number | string;
	nome?: string;
}
