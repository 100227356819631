import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, AtualizarMunicipioRequest } from './types';

// ACTIONS
import {
	atualizarMunicipioSuccess,
	atualizarMunicipioFailure,
} from './actions';

function* atualizarMunicipioRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarMunicipioRequest } = request;

	let body = { ...payload };

	delete body.id;

	body = cleanEmpty(body) as AtualizarMunicipioRequest;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`municipios/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMunicipioSuccess(response));
	} else {
		yield put(atualizarMunicipioFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_MUNICIPIO_REQUEST, atualizarMunicipioRequest),
]);
