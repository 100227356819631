export enum Types {
	CADASTRAR_LOCALIDADE_REQUEST = '@SGU/CADASTRAR_LOCALIDADE_REQUEST',
	CADASTRAR_LOCALIDADE_SUCCESS = '@SGU/CADASTRAR_LOCALIDADE_SUCCESS',
	CADASTRAR_LOCALIDADE_FAILURE = '@SGU/CADASTRAR_LOCALIDADE_FAILURE',
	CADASTRAR_LOCALIDADE_CLEAR = '@SGU/CADASTRAR_LOCALIDADE_CLEAR',
}

export interface CadastrarLocalidade {
	status: number;
	data: null | LocalidadeData;
}

export interface IRequestWithHeaderLocalidadeCreate {
	body: CadastrarLocalidadeRequest;
	headers?: any;
}

interface LocalidadeData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarLocalidadeRequest {
	idUsuario?: number | string;
	id?: number | string;
	codigo: number | string;
	digito: number | string;
	nome: string;
	idUnidade?: number | string;
	idOrgao: number | string;
	tipo: number | string;
	funcionalidades: number[];
}
