import * as Yup from 'yup';

// REDUX
import data from 'store/modules/api/cdhu/enum';

// TYPES
import { ConsultarMutuarioData } from 'store/modules/api/cdhu/consultarMutuario/types';
import { ConsultarSolicitanteData } from 'store/modules/api/cdhu/consultarSolicitante/types';
import { GerarOcorrenciaRequest } from 'store/modules/api/cdhu/gerarOcorrencia/types';

import { IFormDadosSolicitante } from '../form';
import {
	INFORMACAO_INSCRICOES_PGMS_HABITACIONAIS,
	INFORMACAO_SALDO_DEVEDOR,
	INFORMACAO_SEGURO_HABITACIONAL,
	INFORMACAO_SITUACAO_FINANCEIRA,
	INFORMACAO_TRANSF_TITULARIDADE,
} from '../utils/tiposEventos';

export interface IFormServicosMutuario {
	servico: string;
	subservico: string;
	motivoBoletoAgrupado: string;
	motivo: string;
}

export const initialValues: IFormServicosMutuario = {
	servico: '',
	subservico: '',
	motivoBoletoAgrupado: '',
	motivo: '',
};

export const schema = Yup.object<IFormServicosMutuario>().shape({
	servico: Yup.string().required('Campo Obrigatório: Serviço.'),
	boletoAgrupado: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
	subservico: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
	motivo: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
});

function handleServicoEnum(servico: string): string {
	switch (servico) {
		case 'boletoAgrupado':
			return 'BOLETO_AGRUPADO';

		case 'acordo':
			return 'ACORDO';

		case 'transfTitularidade':
			return 'INFO_TRANSFERENCIA_TITULARIDADE';

		case 'seguroHabitacional':
			return 'INFO_SEGURO_HABITACIONAL';

		case 'inscricoesProgHabit':
			return 'INFO_INSCRICOES_PROGRAMAS_HABITACIONAIS';

		case 'situacaoFinanceira':
			return 'INFO_SITUACAO_FINANCEIRA';

		case 'saldoDevedor':
			return 'INFO_SALDO_DEVEDOR';

		default:
			return '';
	}
}

function handleServicoDescricao(servico: string): string {
	const descricao = data.servicos.filter(item => item.value === servico);

	return descricao[0].label;
}

function handleDadosSolicitante(
	dadosSolicitante: ConsultarSolicitanteData | null,
) {
	if (dadosSolicitante) {
		const {
			telefoneCelular,
			telefoneResidencial,
			telefoneComercial,
			telefoneDeContato,
		} = dadosSolicitante;

		const solicitanteId = dadosSolicitante.id;
		const emailSolicitante = dadosSolicitante.email;
		const telCelularSolicitante = telefoneCelular;
		const telResidencialSolicitante = telefoneResidencial;
		const telComercialSolicitante = telefoneComercial;
		const telContatoSolicitante = telefoneDeContato;

		return {
			solicitanteId,
			emailSolicitante,
			telCelularSolicitante,
			telResidencialSolicitante,
			telComercialSolicitante,
			telContatoSolicitante,
		};
	}

	return {
		solicitanteId: null,
		emailSolicitante: null,
		telCelularSolicitante: null,
		telResidencialSolicitante: null,
		telComercialSolicitante: null,
		telContatoSolicitante: null,
	};
}

const convertTipoVinculo = (valor: number | string): string | null => {
	const tipoVinculo = data.tipoVinculo.find(
		item => item.value.toString() === valor.toString(),
	);

	if (tipoVinculo) {
		const removerAcentos = (texto: string): string => {
			return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		};

		const textoSemAcento = removerAcentos(tipoVinculo.label);
		return textoSemAcento.toUpperCase().replace(/[^A-Z0-9]+/g, '_');
	}

	return null;
};

const convertMotivoBoletoAgrupado = (valor: number | string): string | null => {
	const motivoBoletoAgrupado = data.motivosBoletoAgrupado.find(
		item => item.value.toString() === valor.toString(),
	);
	if (motivoBoletoAgrupado) {
		const removerAcentos = (texto: string): string => {
			return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		};

		const textoSemAcento = removerAcentos(motivoBoletoAgrupado.label);
		return textoSemAcento.toUpperCase().replace(/[^A-Z0-9]+/g, '_');
	}
	return null;
};

export function treatValues(
	formValues: IFormServicosMutuario,
	dataFormValuesSolicitante: IFormDadosSolicitante,
	dataConsultarMutuario: ConsultarMutuarioData,
	dataConsultarSolicitante: ConsultarSolicitanteData | null,
	tipoVinculo: number | string,
): GerarOcorrenciaRequest {
	const tipoVinculoFormatted = convertTipoVinculo(tipoVinculo);

	const motivoBoletoAgrupadoFormatted = convertMotivoBoletoAgrupado(
		formValues.motivoBoletoAgrupado,
	);

	const servicoEnum = handleServicoEnum(formValues.servico);
	const descricao = handleServicoDescricao(formValues.servico);

	const {
		componenteContrato,
		contratoCDHU,
		celular,
		telefone1,
		telefone2,
		numeroContrato,
	} = dataConsultarMutuario;

	const dataSolicitante = handleDadosSolicitante(dataConsultarSolicitante);

	return {
		servicoEnum,
		tipoVinculoCDHU: tipoVinculoFormatted,
		descricao,
		tipoOcorrencia: 'INFORMACAO',
		origem: 'PRESENCIAL_POUPATEMPO',
		formaRetorno: 'PRESENCIAL_POUPATEMPO',
		// Dados Mutuário
		componenteContratoId: componenteContrato,
		contratoCDHUId: contratoCDHU,
		numeroContrato,
		mutuarioId: dataConsultarMutuario.id,
		// mutuarioSolicitanteId,
		emailMutuario:
			dataFormValuesSolicitante.email || dataConsultarMutuario.email || '',
		telCelularMutuario: dataFormValuesSolicitante.celular || celular || '',
		telResidencialMutuario:
			dataFormValuesSolicitante.telefoneResidencial || telefone1 || '',
		telComercialMutuario:
			dataFormValuesSolicitante.telefoneComercial || telefone2 || '',

		// Dados Solicitante
		alterarDadosSolicitante: false,
		solicitanteId: dataSolicitante.solicitanteId || '',
		emailSolicitante: dataSolicitante.emailSolicitante || '',
		telCelularSolicitante: dataSolicitante.telCelularSolicitante || '',
		telResidencialSolicitante: dataSolicitante.telResidencialSolicitante || '',
		telComercialSolicitante: dataSolicitante.telComercialSolicitante || '',
		telContatoSolicitante: dataSolicitante.telContatoSolicitante || '',

		// Motivo
		motivoSolicitar2ViaBoleto: null,
		motivoSolicitarBoletoAgrupado: motivoBoletoAgrupadoFormatted,
		motivoSolicitarCarne: null,
	};
}

export const getEnumInfoEventsPPT = (servicoEnum: string) => {
	switch (servicoEnum) {
		case 'INFO_TRANSFERENCIA_TITULARIDADE':
			return INFORMACAO_TRANSF_TITULARIDADE;

		case 'INFO_SEGURO_HABITACIONAL':
			return INFORMACAO_SEGURO_HABITACIONAL;

		case 'INFO_INSCRICOES_PROGRAMAS_HABITACIONAIS':
			return INFORMACAO_INSCRICOES_PGMS_HABITACIONAIS;

		case 'INFO_SITUACAO_FINANCEIRA':
			return INFORMACAO_SITUACAO_FINANCEIRA;

		case 'INFO_SALDO_DEVEDOR':
			return INFORMACAO_SALDO_DEVEDOR;

		default:
			return '';
	}
};
