import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarSufixosRequest } from './types';

export function consultarSulfixosRequest(
	token: string,
	data: ConsultarSufixosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SUFIXO,
		token,
		payload: data,
	};
}
export function consultarSulfixosSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SUFIXO_SUCCESS,
		payload,
	};
}
export function consultarSulfixosFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SUFIXO_FAILURE,
		payload: null,
	};
}

export function consultarSulfixosClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SUFIXO_CLEAR,
		payload: null,
	};
}
