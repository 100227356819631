import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarParametroSistemaRequest } from './types';

export function atualizarParametroSistemaRequest(
	token: string,
	data: AtualizarParametroSistemaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA,
		token,
		payload: data,
	};
}
export function atualizarParametroSistemaSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function atualizarParametroSistemaFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function atualizarParametroSistemaClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
