import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarStatusRequest } from './types';

// ACTIONS
import { cadastrarStatusSuccess, cadastrarStatusFailure } from './actions';

function* cadastrarStatus(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarStatusRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`status`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarStatusSuccess(response));
	} else {
		yield put(cadastrarStatusFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_STATUS, cadastrarStatus)]);
