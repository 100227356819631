import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, solicitarReconhecimentoPaternidade } from './types';

export const INITIAL_STATE: solicitarReconhecimentoPaternidade = {
	status: 0,
	data: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): solicitarReconhecimentoPaternidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_REQUEST: {
				draft.data = INITIAL_STATE.data;
				draft.status = 100;
				break;
			}

			case Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.request = action.payload.request;
				break;
			}

			case Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.request = null;
				break;
			}
			case Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = null;
				break;
			}

			default:
		}
		return draft;
	});
}
