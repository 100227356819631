export enum Types {
	CONSULTAR_DETALHE_SOLICITACAO_REQUEST = '@MP/CONSULTAR_DETALHE_SOLICITACAO_REQUEST',
	CONSULTAR_DETALHE_SOLICITACAO_SUCCESS = '@MP/CONSULTAR_DETALHE_SOLICITACAO_SUCCESS',
	CONSULTAR_DETALHE_SOLICITACAO_FAILURE = '@MP/CONSULTAR_DETALHE_SOLICITACAO_FAILURE',
	CONSULTAR_DETALHE_SOLICITACAO_CLEAR = '@MP/CONSULTAR_DETALHE_SOLICITACAO_CLEAR',
}

export interface consultarDetalheSolicitacao {
	status: number;
	data: ConsultarDetalheSolicitacaoData | null;
}

export interface ConsultarDetalheSolicitacaoRequest {
	cpfUsuario: string;
	cpfCidadao: string;
	idSolicitacao: string;
}

interface EnderecoResidencia {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	siglaUF: string;
}

interface Telefones {
	tipo: string;
	ddd: string | number;
	numero: string | number;
}

export interface ConsultarDetalheSolicitacaoData {
	numeroMP: string;
	dataDaSolicitacao: string;
	andamentoDaSolicitacao: {
		situacaoAtual: string;
		localizacaoAtual: string;
	};
	filho: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
	};
	supostoGenitor: {
		nome: string;
		dataDeNascimento: string;
		cidadeDeNascimento: string;
		siglaUfDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		nomeDaMae: string;
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
	};
	responsavelLegal: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		email: string;
	};
}
