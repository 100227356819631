import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarOrgaosRequest, Types } from './types';

export function consultarOrgaoRequest(
	token: string,
	payload: ConsultarOrgaosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ORGAOS_REQUEST,
		token,
		payload,
	};
}
export function consultarOrgaoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ORGAOS_SUCCESS,
		payload,
	};
}
export function consultarOrgaoFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ORGAOS_FAILURE,
		payload: null,
	};
}
export function consultarOrgaoClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_ORGAOS_CLEAR,
		payload: null,
	};
}
