import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMotivosRequest } from './types';

export function consultarMotivosRequest(
	token: string,
	payload: ConsultarMotivosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MOTIVOS,
		token,
		payload,
	};
}
export function consultarMotivosSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MOTIVOS_SUCCESS,
		payload,
	};
}
export function consultarMotivosFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MOTIVOS_FAILURE,
		payload: null,
	};
}
export function consultarMotivosClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MOTIVOS_CLEAR,
		payload: null,
	};
}
