import { RecuperarAtendimento } from 'store/modules/api/sgu/atendimento/recuperar/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import {
	ILogin,
	IParametrosLocalidade,
} from 'store/modules/api/sgu/loginUnico/types';

import { validacaoData, verificarMaioridade } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface SguData {
	recuperarAtendimento: RecuperarAtendimento;
	salvarAtendimento: SalvarAtendimento;
	login: ILogin;
	user: {
		cpf: string;
		nome: string;
		orgaos: any;
		login?: boolean;
		parametrosSistema: IParametrosLocalidade[];
		perfil: any;
	};
}

interface EnderecoResidencia {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	siglaUF: string;
}

interface Telefones {
	tipo: string;
	ddd: string | number;
	numero: string | number;
}

interface DocumentosPessoais {
	nomeArquivo: string;
	extensaoArquivo: string;
	urlArquivo: string;
	tipoDoDocumento: string;
}

export interface DocumentosAnexos {
	nomeArquivo: string;
	extensaoArquivo: string;
	urlArquivo: string;
	tipoDoDocumento: string;
}
export interface ICadastroSolicitante {
	tipoSolicitante: number;
	cpfUsuario: string;
	documentosAnexos: DocumentosAnexos[] | [];
	documentoAnexo: string;
	podeArcarComCustosDoProcesso: string;
	comoFezOPedido: string;
	quemFezOPedido: string;
	dataDaSolicitacao: string;
	outrasInformacoesParaLocalizacaoDoSupostoGenitor: string;
	numeroMP: string;
	andamentoDaSolicitacao: {
		situacaoAtual: string;
		localizacaoAtual: string;
	};
	// Filho ou Filha
	filho: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		telefoneResidencial?: string;
		telefoneCelular?: string;
		email: string;
		documentosPessoais: DocumentosPessoais[] | [];
	};
	supostoGenitor: {
		nome: string;
		dataDeNascimento: string;
		cidadeDeNascimento: string;
		siglaUfDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		nomeDaMae: string;
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		telefoneResidencial?: string;
		telefoneCelular?: string;
		email: string;
		documentosPessoais: DocumentosPessoais[] | [];
	};
	responsavelLegal: {
		nome: string;
		dataDeNascimento: string;
		sexo: string;
		cpf: {
			numero: string;
		};
		rg: {
			numero: string;
			siglaUF: string;
		};
		enderecoDeResidencia: EnderecoResidencia;
		telefones: Telefones[];
		telefoneResidencial?: string;
		telefoneCelular?: string;
		email: string;
		documentosPessoais: DocumentosPessoais[] | [];
	};
}

interface Anexos {
	nome: string;
	tipo: string;
	conteudo: string;
}

export const initialValues: ICadastroSolicitante = {
	tipoSolicitante: 1,
	documentosAnexos: [],
	documentoAnexo: '',
	cpfUsuario: '',
	podeArcarComCustosDoProcesso: '',
	comoFezOPedido: '',
	quemFezOPedido: '',
	dataDaSolicitacao: '',
	outrasInformacoesParaLocalizacaoDoSupostoGenitor: '',
	numeroMP: '',
	andamentoDaSolicitacao: {
		situacaoAtual: '',
		localizacaoAtual: '',
	},
	filho: {
		nome: '',
		dataDeNascimento: '',
		sexo: '',
		cpf: {
			numero: '',
		},
		rg: {
			numero: '',
			siglaUF: '',
		},
		enderecoDeResidencia: {
			logradouro: '',
			numero: '',
			complemento: '',
			bairro: '',
			cep: '',
			cidade: '',
			siglaUF: '',
		},
		telefones: [
			{
				tipo: '',
				ddd: '',
				numero: '',
			},
		],
		email: '',
		documentosPessoais: [
			{
				nomeArquivo: '',
				extensaoArquivo: '',
				urlArquivo: '',
				tipoDoDocumento: '',
			},
		],
	},
	supostoGenitor: {
		nome: '',
		dataDeNascimento: '',
		cidadeDeNascimento: '',
		siglaUfDeNascimento: '',
		sexo: '',
		cpf: {
			numero: '',
		},
		rg: {
			numero: '',
			siglaUF: '',
		},
		nomeDaMae: '',
		enderecoDeResidencia: {
			logradouro: '',
			numero: '',
			complemento: '',
			bairro: '',
			cep: '',
			cidade: '',
			siglaUF: '',
		},
		telefones: [
			{
				tipo: '',
				ddd: '',
				numero: '',
			},
		],
		email: '',
		documentosPessoais: [
			{
				nomeArquivo: '',
				extensaoArquivo: '',
				urlArquivo: '',
				tipoDoDocumento: '',
			},
		],
	},
	responsavelLegal: {
		nome: '',
		dataDeNascimento: '',
		sexo: '',
		cpf: {
			numero: '',
		},
		rg: {
			numero: '',
			siglaUF: '',
		},
		enderecoDeResidencia: {
			logradouro: '',
			numero: '',
			complemento: '',
			bairro: '',
			cep: '',
			cidade: '',
			siglaUF: '',
		},
		telefones: [
			{
				tipo: '',
				ddd: '',
				numero: '',
			},
		],
		email: '',
		documentosPessoais: [
			{
				nomeArquivo: '',
				extensaoArquivo: '',
				urlArquivo: '',
				tipoDoDocumento: '',
			},
		],
	},
};

const schemaRGFilho = Yup.object().shape({
	numero: Yup.string().test({
		name: 'rg-numero-condicional',
		message:
			'Campo Obrigatório: Interessado: RG Número, quando a UF estiver preenchida.',
		test: function validateNumero(value) {
			const { siglaUF } = this.parent;
			if (siglaUF) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se siglaUF não estiver preenchido
		},
	}),
	siglaUF: Yup.string().test({
		name: 'rg-siglaUF-condicional',
		message:
			'Campo Obrigatório: Interessado: RG UF, quando o RG número estiver preenchido.',
		test: function validateSiglaUF(value) {
			const { numero } = this.parent;
			if (numero) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se numero não estiver preenchido
		},
	}),
});

const schemaRGSupostoGenitor = Yup.object().shape({
	numero: Yup.string().test({
		name: 'rg-numero-condicional',
		message:
			'Campo Obrigatório: Genitor: RG Número, quando a UF estiver preenchida.',
		test: function validateNumero(value) {
			const { siglaUF } = this.parent;
			if (siglaUF) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se siglaUF não estiver preenchido
		},
	}),
	siglaUF: Yup.string().test({
		name: 'rg-siglaUF-condicional',
		message:
			'Campo Obrigatório: Genitor: RG UF, quando o RG número estiver preenchido.',
		test: function validateSiglaUF(value) {
			const { numero } = this.parent;
			if (numero) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se numero não estiver preenchido
		},
	}),
});

const schemaRGResponsavel = Yup.object().shape({
	numero: Yup.string().test({
		name: 'rg-numero-condicional',
		message:
			'Campo Obrigatório: Responsável Legal: RG Número, quando a UF estiver preenchida.',
		test: function validateNumero(value) {
			const { siglaUF } = this.parent;
			if (siglaUF) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se siglaUF não estiver preenchido
		},
	}),
	siglaUF: Yup.string().test({
		name: 'rg-siglaUF-condicional',
		message:
			'Campo Obrigatório: Responsável Legal: RG UF, quando o RG número estiver preenchido.',
		test: function validateSiglaUF(value) {
			const { numero } = this.parent;
			if (numero) {
				return !!value; // Deve estar preenchido
			}
			return true; // Não obrigatório se numero não estiver preenchido
		},
	}),
});

export const schemaInteressadoFilho = Yup.object<ICadastroSolicitante>().shape({
	filho: Yup.object().shape({
		nome: Yup.string().required(
			`Campo Obrigatório: Interessado: Nome Completo.`,
		),
		dataDeNascimento: Yup.string()
			// eslint-disable-next-line func-names
			.test(
				'test-name',
				() =>
					`Atenção, reconhecimento de paternidade solicitante filho/filha só é permitida para maiores de 18 anos!`,
				(value: any) =>
					value === '' || !validacaoData(value)
						? true
						: verificarMaioridade(value),
			)
			.test(
				'test-name',
				value => `Data de Nascimento: '${value.value}' não é uma data válida.`,
				(value: any) => (value === '' ? true : validacaoData(value)),
			),
		sexo: Yup.string().required(`Campo Obrigatório: Interessado: Sexo.`),
		cpf: Yup.object().shape({
			numero: Yup.string().required(`Campo Obrigatório: Interessado: CPF.`),
		}),
		rg: schemaRGFilho,
		email: Yup.string().required(`Campo Obrigatório: Interessado: E-mail.`),
		enderecoDeResidencia: Yup.object().shape({
			logradouro: Yup.string().required(
				`Campo Obrigatório: Interessado: Logradouro.`,
			),
			numero: Yup.string().required(`Campo Obrigatório: Interessado: Número.`),
			bairro: Yup.string().required(`Campo Obrigatório: Interessado: Bairro.`),
			cep: Yup.string().required(`Campo Obrigatório: Interessado: CEP.`),
			cidade: Yup.string().required(`Campo Obrigatório: Interessado: Cidade.`),
			siglaUF: Yup.string().required(`Campo Obrigatório: Interessado: UF.`),
		}),
		telefoneCelular: Yup.string().required(
			`Campo Obrigatório: Interessado: Telefone Celular.`,
		),
		documentosPessoais: Yup.array().test(
			'validacao-tipos-obrigatorios',
			'DOCUMENTO_PESSOAL_CERTIDAO é obrigatório.',
			value =>
				!!(
					value &&
					value.length > 0 &&
					JSON.stringify(value).includes('DOCUMENTO_PESSOAL_CERTIDAO')
				),
		),
	}),
	supostoGenitor: Yup.object().shape({
		nome: Yup.string().required(`Campo Obrigatório: Genitor: Nome Completo.`),
		sexo: Yup.string().required(`Campo Obrigatório: Genitor: Sexo.`),
		rg: schemaRGSupostoGenitor,
		dataDeNascimento: Yup.string().test(
			'test-name',
			value => `Data de Nascimento: '${value.value}' não é uma data válida.`,
			(value: any) => (value === '' ? true : validacaoData(value)),
		),
	}),
});

export const schemaInteressadoRespLegal =
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado: Nome Completo.`,
			),
			dataDeNascimento: Yup.string().test(
				'test-name',
				value => `Data de Nascimento: '${value.value}' não é uma data válida.`,
				(value: any) => (value === '' ? true : validacaoData(value)),
			),
			rg: schemaRGFilho,
			sexo: Yup.string().required(`Campo Obrigatório: Interessado: Sexo.`),
		}),
		supostoGenitor: Yup.object().shape({
			nome: Yup.string().required(`Campo Obrigatório: Genitor: Nome Completo.`),
			sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
			rg: schemaRGSupostoGenitor,
		}),
		responsavelLegal: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Responsável: Nome Completo.`,
			),
			sexo: Yup.string().required(`Campo Obrigatório: Responsável: Sexo.`),
			email: Yup.string().required(
				`Campo Obrigatório: Responsável Legal: E-mail.`,
			),
			cpf: Yup.object().shape({
				numero: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: CPF.`,
				),
			}),
			rg: schemaRGResponsavel,
			enderecoDeResidencia: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: Logradouro.`,
				),
				numero: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: Número.`,
				),
				bairro: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: Bairro.`,
				),
				cep: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: CEP.`,
				),
				cidade: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: Cidade.`,
				),
				siglaUF: Yup.string().required(
					`Campo Obrigatório: Responsável Legal: UF.`,
				),
			}),
			telefoneCelular: Yup.string().required(
				`Campo Obrigatório: Responsável Legal: Telefone Celular.`,
			),
			documentosPessoais: Yup.array().test(
				'validacao-tipos-obrigatorios',
				'Os documentos obrigatórios DOCUMENTO_PESSOAL_RG_RESPONSAVEL e DOCUMENTO_PESSOAL_CERTIDAO precisam estar presentes.',
				value =>
					!!(
						value &&
						value.length > 0 &&
						JSON.stringify(value).includes('DOCUMENTO_PESSOAL_CERTIDAO') &&
						JSON.stringify(value).includes('DOCUMENTO_PESSOAL_RG_RESPONSAVEL')
					),
			),
		}),
	});

export const schemaInteressadoGenitor =
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado: Nome Completo.`,
			),
			dataDeNascimento: Yup.string().test(
				'test-name',
				value => `Data de Nascimento: '${value.value}' não é uma data válida.`,
				(value: any) => (value === '' ? true : validacaoData(value)),
			),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado: Sexo.`),
			rg: schemaRGFilho,
		}),
		supostoGenitor: Yup.object().shape({
			nome: Yup.string().required(`Campo Obrigatório: Genitor: Nome Completo.`),
			cpf: Yup.object().shape({
				numero: Yup.string().required(`Campo Obrigatório: Genitor: CPF.`),
			}),
			rg: schemaRGSupostoGenitor,
			sexo: Yup.string().required(`Campo Obrigatório: Genitor: Sexo.`),
			email: Yup.string().required(`Campo Obrigatório: Genitor: E-mail.`),
			enderecoDeResidencia: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Genitor: Logradouro.`,
				),
				numero: Yup.string().required(`Campo Obrigatório: Genitor: Número.`),
				bairro: Yup.string().required(`Campo Obrigatório: Genitor: Bairro.`),
				cep: Yup.string().required(`Campo Obrigatório: Genitor: CEP.`),
				cidade: Yup.string().required(`Campo Obrigatório: Genitor: Cidade.`),
				siglaUF: Yup.string().required(`Campo Obrigatório: Genitor: UF.`),
			}),
			telefoneCelular: Yup.string().required(
				`Campo Obrigatório: Genitor: Telefone Celular.`,
			),
			documentosPessoais: Yup.array().test(
				'validacao-tipos-obrigatorios',
				'DOCUMENTO_PESSOAL_RG_GENITOR é obrigatório.',
				value =>
					!!(
						value &&
						value.length > 0 &&
						JSON.stringify(value).includes('DOCUMENTO_PESSOAL_RG_GENITOR')
					),
			),
		}),
	});

function hasValue(obj: any, key: string) {
	if (!obj) return false;
	return (
		Object.prototype.hasOwnProperty.call(obj, key) &&
		obj[key] !== '00000000-0000-0000-0000-000000000000'
	);
}

export const getIdData = (data: SguData) => {
	const { salvarAtendimento, recuperarAtendimento } = data;
	const idData: any = {
		idCidadao: '',
		idAtendimento: '',
	};
	if (hasValue(recuperarAtendimento.data, 'idCidadao')) {
		idData.idCidadao = recuperarAtendimento.data?.idCidadao;
	}
	if (hasValue(recuperarAtendimento.data, 'idAtendimento')) {
		idData.idAtendimento = recuperarAtendimento.data?.idAtendimento;
	}
	if (
		idData.idCidadao === '' &&
		hasValue(salvarAtendimento.data, 'idCidadao')
	) {
		idData.idCidadao = salvarAtendimento.data?.idCidadao;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(salvarAtendimento.data, 'idAtendimento')
	) {
		idData.idAtendimento = salvarAtendimento.data?.idAtendimento;
	}
	return idData;
};
