import { ReducerActionRotaSP } from 'store/modules/types';

import { UploadArquivoRequest, Types } from './types';

export function uploadArquivoRequest(
	token: string,
	data: UploadArquivoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.UPLOAD_ARQUIVO_REQUEST,
		token,
		payload: data,
	};
}
export function uploadArquivoSuccess(payload: any): ReducerActionRotaSP {
	return {
		type: Types.UPLOAD_ARQUIVO_SUCCESS,
		payload,
	};
}
export function uploadArquivoFailure(payload: any): ReducerActionRotaSP {
	return {
		type: Types.UPLOAD_ARQUIVO_FAILURE,
		payload,
	};
}

export function uploadArquivoClear(): { type: string } {
	return {
		type: Types.UPLOAD_ARQUIVO_CLEAR,
	};
}
