// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { store } from 'store';
import { IncluirEstatisticasRequest, Types } from './types';

export function incluirEstatisticasSalvarAtendimentoRequest(
	token: string,
	payload: IncluirEstatisticasRequest,
) {
	store.dispatch({
		type: Types.INCLUIR_ESTATISTICAS_REQUEST,
		token,
		payload,
	});
}

export function incluirEstatisticasRequest(
	token: string,
	payload: IncluirEstatisticasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.INCLUIR_ESTATISTICAS_REQUEST,
		token,
		payload,
	};
}
export function incluirEstatisticasSuccess(
	payload: string,
): ReducerActionRotaSP {
	return {
		type: Types.INCLUIR_ESTATISTICAS_SUCCESS,
		payload,
	};
}
export function incluirEstatisticasFailure(): ReducerActionRotaSP {
	return {
		type: Types.INCLUIR_ESTATISTICAS_FAILURE,
		payload: null,
	};
}
export function incluirEstatisticasClear(): ReducerActionRotaSP {
	return {
		type: Types.INCLUIR_ESTATISTICAS_CLEAR,
		payload: null,
	};
}
