import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirSufixoRequest } from './types';

export function excluirSufixoRequest(
	token: string,
	data: ExcluirSufixoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SUFIXO,
		token,
		payload: data,
	};
}
export function excluirSufixoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SUFIXO_SUCCESS,
		payload,
	};
}
export function excluirSufixoFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SUFIXO_FAILURE,
		payload: null,
	};
}
export function excluirSufixoClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SUFIXO_CLEAR,
		payload: null,
	};
}
