import { Row } from 'antd';
import styled from 'styled-components';

import QRCode from './assets/qr_code_mp.png';
import LogoMP from './assets/logo_ministerio_publico.png';

interface BoldTextProps {
	size?: string;
}

export const Container = styled.div`
	margin: 20px 0;
`;

export const BoxConfirmation = styled(Row)`
	width: 100%;
	border 1px solid black;
	border-radius: 10px;
	display: flex;
	align-items: 'center';
	justify: 'center';
	margin-bottom: 8px;

	.ant-row {
		:not(:last-child) {
			border-bottom: 1px solid black;
		}

		.ant-col {
			:not(:first-child) {
				border-left: 1px solid black;
			}

			padding: 10px;
		}
	}

`;

export const BoxConfirmationQRCode = styled(Row)`
	width: 100%;
	border 1px solid black;
	border-radius: 10px;
	padding: 8px;
	display: flex;
	align-items: 'center';
	justify: 'center';
	margin-bottom: 10px;
`;

export const StyledUl = styled.ul`
	padding: 0 0 0 20px;
	margin-bottom: 0;
`;

export const BoldText = styled.p<BoldTextProps>`
	font-weight: 600;
	font-size: ${props => `${props.size}em`};
	display: contents;
`;

export const QRCodeBox = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const QRCodeImg = styled.div`
	background-image: url(${QRCode});
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-print-color-adjust: exact !important;
`;

export const LogoMPBox = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LogoMPImg = styled.div`
	background-image: url(${LogoMP});
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	-webkit-print-color-adjust: exact !important;
`;
