import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMenusRequest } from './types';

export function excluirMenusRequest(
	token: string,
	data: ExcluirMenusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENUS,
		token,
		payload: data,
	};
}
export function excluirMenusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENUS_SUCCESS,
		payload,
	};
}
export function excluirMenusFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENUS_FAILURE,
		payload: null,
	};
}
export function excluirMenusClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MENUS_CLEAR,
		payload: null,
	};
}
