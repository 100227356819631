import { v4 } from 'uuid';
import { SalvarAtendimentoData, SalvarAtendimentoRequest } from './types';
import { IncluirEstatisticasRequest } from '../../estatisticas/incluir/types';

export const getMessage = (
	values: SalvarAtendimentoRequest,
	treatedSalvaAtendimentoData: SalvarAtendimentoData,
): string => {
	const { situacao, motivoJustificativa, justificativa } = values;

	const { msg, idAtendimento, idCidadao } = treatedSalvaAtendimentoData;

	const treatedMsg = msg?.substring(0, 500) || '';
	const treatedJusticativa = justificativa?.substring(0, 500) || '';

	let observacao = '';

	switch (situacao) {
		case 'OK':
			observacao = `200 OK - Id_atendimento: ${
				idAtendimento || ''
			} - Id_cidadao: ${idCidadao || ''}`;
			break;

		case 'SEM_DADOS':
			observacao = `Segue sem dados do Atendimento - ${treatedMsg}`;
			break;

		case 'RECUSADO':
			observacao = `Atendimento Recusado - Id_atendimento: ${
				idAtendimento || ''
			} - Id_cidadao: ${idCidadao || ''}. ${motivoJustificativa || ''}`;

			if (motivoJustificativa === 'OUTROS') {
				observacao += ` - ${treatedJusticativa || ''}`;
			}
			break;

		default:
			break;
	}

	return observacao;
};

export const treatResponseSalvarAtendimento = (
	values: SalvarAtendimentoRequest,
): SalvarAtendimentoData => {
	const { situacao, atendimento, cpfUsuario, motivoJustificativa } = values;
	const idCidadaoDefault = '00000000-0000-0000-0000-000000000000';

	let response: SalvarAtendimentoData = {
		idCidadao: atendimento.idCidadao || idCidadaoDefault,
		idAtendimento: atendimento.idAtendimento || '',
		cpf: cpfUsuario || '',
		msg: atendimento.msg || '',
		rg: atendimento.rg || '',
	};

	switch (situacao) {
		case 'OK':
			break;

		case 'SEM_DADOS':
			response = {
				...response,
				idCidadao: idCidadaoDefault,
				idAtendimento: v4(),
			};
			break;

		case 'RECUSADO':
			if (motivoJustificativa !== 'AGENDADO_POR_OUTRO') {
				response = {
					...response,
					idAtendimento: v4(),
				};
			}

			response = {
				...response,
				idCidadao: idCidadaoDefault,
			};

			break;

		default:
			break;
	}
	return response;
};

export const treatIncluirEstatisticasSguRequest = (
	values: SalvarAtendimentoRequest,
	treatedSalvaAtendimentoData: SalvarAtendimentoData,
): IncluirEstatisticasRequest => {
	const treatedValues = { ...values, ...treatedSalvaAtendimentoData };

	const { cpfUsuario, codLocalidade, orgao } = treatedValues;

	const payload: IncluirEstatisticasRequest = {
		idCidadao: treatedSalvaAtendimentoData.idCidadao || '',
		descricaoSistemaOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: `RG: ${treatedSalvaAtendimentoData.rg || ''} - CPF: ${
			treatedSalvaAtendimentoData.cpf
		}`,
		numeroDocumento: `Id_cidadao: ${
			treatedSalvaAtendimentoData.idCidadao || ''
		}`,
		codigoLocalidade: codLocalidade,
		idOrgao: Number(orgao.id),
		cpf: cpfUsuario,
		descricaoServico: 'RECUPERA_ATENDIMENTO',
		flagConfronto: '',
		descricaoDedosEnviados: '',
		idMotivo: 16,
		observacao: getMessage(values, treatedSalvaAtendimentoData),
		numeroVia: 0,
		numeroProcesso: `Id_atendimento: ${treatedSalvaAtendimentoData.idAtendimento}`,
		totalizador: '',
		userIdLogin: values.userIdLogin,
	};

	return payload;
};
