import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarOrgaoRequest } from './types';

export function cadastrarOrgaoRequest(
	token: string,
	data: CadastrarOrgaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_ORGAO,
		token,
		payload: data,
	};
}
export function cadastrarOrgaoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_ORGAO_SUCCESS,
		payload,
	};
}
export function cadastrarOrgaoFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_ORGAO_FAILURE,
		payload: null,
	};
}
export function cadastrarOrgaoClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_ORGAO_CLEAR,
		payload: null,
	};
}
