import * as Yup from 'yup';

export interface IFormCalculo {
	prds: boolean;
	parcelasEmAtraso: boolean;
	dataVencimento: string;
}

export const initialValues: IFormCalculo = {
	prds: false,
	parcelasEmAtraso: false,
	dataVencimento: '',
};

function validaPeriodo15Dias(data: string): boolean {
	const date = new Date();

	const dataHoje = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
	);

	const dataSplitted = data.split('/');
	const dataVencto = new Date(
		Number(dataSplitted[2]),
		Number(dataSplitted[1]) - 1,
		Number(dataSplitted[0]),
	);

	const dataLimite = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
	);
	dataLimite.setDate(dataLimite.getDate() + 90);

	if (dataVencto < dataHoje) return false;
	if (dataVencto > dataLimite) return false;
	if (!(dataVencto >= dataHoje && dataVencto <= dataLimite)) return false;

	return true;
}

export const schema = Yup.object<IFormCalculo>().shape({
	tipoParcela: Yup.string().when(['prds', 'parcelasEmAtraso'], {
		is: (prds, parcelasEmAtraso) =>
			prds === false && parcelasEmAtraso === false,
		then: Yup.string().required(`Selecione PRD's e/ou Parcelas em atraso.`),
	}),
	dataVencimento: Yup.string()
		.required('Campo Obrigatório: Data de vencimento.')
		.test({
			name: 'validaPeriodo15Dias',
			exclusive: true,
			message: 'Campo Inválido: Data de vencimento.',
			test: value => (value ? validaPeriodo15Dias(value) : true),
		}),
});
