import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IRequestWithHeaderLocalidadeDelete } from './types';

// ACTIONS
import { excluirLocalidadeSuccess, excluirLocalidadeFailure } from './actions';

function* excluirLocalidade(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRequestWithHeaderLocalidadeDelete } = request;

	const { body } = { ...payload };

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`localidades/${body.id}`,
	);

	if (response.status === 200) {
		yield put(excluirLocalidadeSuccess(response));
	} else {
		yield put(excluirLocalidadeFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_LOCALIDADE, excluirLocalidade)]);
