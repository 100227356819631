import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

import data from 'store/modules/api/mp/enum';

import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';

import Radio from 'components/Common/Form/Radio';
import hasError from 'utils/getFormErrors';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import { onlyLetters } from 'utils/genericFunctions';
import { ICadastroSolicitante } from '../form';
import DadosSolicitante from '../DadosSolicitante';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const DadosResponsavel: React.FC<Props> = ({ formik, step, formDisabled }) => {
	return (
		<>
			<FormBox
				title={`Dados da mãe, pai ou Responsável legal ${
					formik.values.tipoSolicitante === 2 ? ' - Solicitante' : ''
				}`}
			>
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Field
							as={Input}
							title="Nome Completo"
							name="responsavelLegal.nome"
							required={formik.values.tipoSolicitante === 2}
							error={hasError(formik.errors, 'responsavelLegal.nome')}
							readOnly={step === 2}
							maxLength={60}
							value={onlyLetters(formik.values.responsavelLegal?.nome!)}
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={10}>
						<Field
							title="Data de Nascimento"
							name="responsavelLegal.dataDeNascimento"
							type="text"
							mask="99/99/9999"
							subtitle={step === 2 ? '' : '(DD/MM/AAAA)'}
							size={60}
							as={ValidDataInput}
							error={hasError(
								formik.errors,
								'responsavelLegal.dataDeNascimento',
							)}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('responsavelLegal.dataDeNascimento', v)
							}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
					<Col span={6}>
						{step === 1 && (
							<Field
								required={formik.values.tipoSolicitante === 2}
								as={Radio}
								titleSize="sm"
								options={data.sexo}
								title="Sexo"
								defaultValue={formik.values.responsavelLegal.sexo}
								name="responsavelLegal.sexo"
								error={hasError(formik.errors, 'responsavelLegal.sexo')}
								disabled={formDisabled}
							/>
						)}
						{step === 2 && (
							<Field
								required={formik.values.tipoSolicitante === 2}
								as={Input}
								title="Sexo"
								titleSize="sm"
								name="responsavelLegal.sexo"
								disabled={formDisabled}
								value={
									formik.values.responsavelLegal.sexo === 'Masculino'
										? 'MASCULINO'
										: 'FEMININO'
								}
								readOnly={step === 2}
							/>
						)}
					</Col>

					<Col span={8}>
						<Field
							required={formik.values.tipoSolicitante === 2}
							title="CPF"
							name="responsavelLegal.cpf.numero"
							as={InputMask}
							mask="999.999.999-99"
							error={hasError(formik.errors, 'responsavelLegal.cpf.numero')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>
				<DadosSolicitante
					formik={formik}
					step={step}
					solicitante="responsavelLegal"
					formDisabled={formDisabled}
				/>
				{/* )} */}
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosResponsavel;
