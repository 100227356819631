import { takeLatest, call, put, all } from 'redux-saga/effects';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { ApiResponse, postApi } from 'services/rotasp/api';
import {
	SolicitarReconhecimentoPaternidadeRequest,
	Telefones,
	Types,
} from './types';
import {
	solicitarReconhecimentoPaternidadeSuccess,
	solicitarReconhecimentoPaternidadeFailure,
} from './actions';

function* solicitarReconhecimentoPaternidadeRequest(request: any) {
	const {
		token,
		payload,
	}: {
		token?: string;
		payload: SolicitarReconhecimentoPaternidadeRequest;
	} = request;

	const removeEmptyPropertyTephoneObject = (telephoneList?: Telefones[]) => {
		let newTelephoneList = telephoneList;
		if (telephoneList && telephoneList.length) {
			newTelephoneList = telephoneList.filter(item => {
				if (item.numero) {
					return {
						...item,
					};
				}
				return null;
			});
		}
		return newTelephoneList;
	};

	const removeEmptyValues = (
		objParams: SolicitarReconhecimentoPaternidadeRequest,
	) => {
		const obj = { ...objParams };

		Object.keys(obj).forEach(key => {
			const newKey = key as keyof typeof obj;
			if (
				obj[newKey] === null ||
				obj[newKey] === undefined ||
				obj[newKey] === ''
			) {
				delete obj[newKey];
			}
		});

		if (obj.filho) {
			const keyDefault = 'filho';

			Object.keys(obj[keyDefault]).forEach(newKey => {
				const newKeyChild = newKey as keyof typeof obj.filho;
				if (!obj[keyDefault][newKeyChild]) {
					delete obj[keyDefault][newKeyChild];
				}
			});

			if (!obj[keyDefault].cpf?.numero) {
				delete obj[keyDefault].cpf;
			}
			if (!obj[keyDefault].rg?.numero) {
				delete obj[keyDefault].rg;
			}
			if (!obj[keyDefault].enderecoDeResidencia?.cep) {
				delete obj[keyDefault].enderecoDeResidencia;
			}

			obj[keyDefault].telefones = removeEmptyPropertyTephoneObject(
				obj[keyDefault].telefones,
			);

			if (!obj[keyDefault].telefones?.length) {
				delete obj[keyDefault].telefones;
			}
		}

		if (obj.responsavelLegal) {
			const keyDefault = 'responsavelLegal';

			Object.keys(obj[keyDefault]).forEach(newKey => {
				const newKeyChild = newKey as keyof typeof obj.responsavelLegal;
				if (!obj[keyDefault][newKeyChild]) {
					delete obj[keyDefault][newKeyChild];
				}
			});

			if (!obj[keyDefault].cpf?.numero) {
				delete obj[keyDefault].cpf;
			}
			if (!obj[keyDefault].rg?.numero) {
				delete obj[keyDefault].rg;
			}
			if (!obj[keyDefault].enderecoDeResidencia?.cep) {
				delete obj[keyDefault].enderecoDeResidencia;
			}

			obj[keyDefault].telefones = removeEmptyPropertyTephoneObject(
				obj[keyDefault].telefones,
			);

			if (!obj[keyDefault].telefones?.length) {
				delete obj[keyDefault].telefones;
			}
		}

		if (obj.supostoGenitor) {
			const keyDefault = 'supostoGenitor';

			Object.keys(obj[keyDefault]).forEach(newKey => {
				const newKeyChild = newKey as keyof typeof obj.supostoGenitor;
				if (!obj[keyDefault][newKeyChild]) {
					delete obj[keyDefault][newKeyChild];
				}
			});

			if (!obj[keyDefault].cpf?.numero) {
				delete obj[keyDefault].cpf;
			}
			if (!obj[keyDefault].rg?.numero) {
				delete obj[keyDefault].rg;
			}
			if (!obj[keyDefault].enderecoDeResidencia?.cep) {
				delete obj[keyDefault].enderecoDeResidencia;
			}

			obj[keyDefault].telefones = removeEmptyPropertyTephoneObject(
				obj[keyDefault].telefones,
			);

			if (!obj[keyDefault].telefones?.length) {
				delete obj[keyDefault].telefones;
			}
		}

		delete obj.cpfUsuario;

		return obj;
	};

	const body = removeEmptyValues(payload);

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		'v3/solicitacoes',
		body,
		{ cpfUsuario: payload.cpfUsuario },
	);

	if (response.status === 200 || response.status === 201) {
		yield put(
			solicitarReconhecimentoPaternidadeSuccess({
				response,
				request: body,
			}),
		);
	} else {
		yield put(solicitarReconhecimentoPaternidadeFailure(response));
	}
}

export default all([
	takeLatest(
		Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_REQUEST,
		solicitarReconhecimentoPaternidadeRequest,
	),
]);
