import { RecuperarAtendimentoData } from 'store/modules/api/sgu/atendimento/recuperar/types';
import { SalvarAtendimentoRequest } from 'store/modules/api/sgu/atendimento/salvar/types';

export interface DataArrayProps {
	cpfUsuario: string;
	codLocalidade: number;
	descricaoServico: string | null;
	orgao: {
		id: string;
		nome: string;
	};
	atendimento?: RecuperarAtendimentoData | null;
}

export const initialValues: SalvarAtendimentoRequest = {
	cpfUsuario: '',
	motivoJustificativa: '',
	justificativa: '',
	codLocalidade: 0,
	situacao: 'SEM_DADOS',
	descricaoServico: '',
	atendimento: {
		idCidadao: '',
		idAtendimento: '',
		cpf: '',
		msg: '',
	},
	orgao: {
		id: '',
		nome: '',
	},
	userIdLogin: 0,
};

export function treatValuesNoData(
	values: DataArrayProps | null,
	ErrorMessageRecoverAttendancy: string[] | null,
	userIdLogin: string | number,
): SalvarAtendimentoRequest {
	const errorMessages: string[] = (ErrorMessageRecoverAttendancy &&
		ErrorMessageRecoverAttendancy?.length > 0 &&
		ErrorMessageRecoverAttendancy) || [''];

	const formattedData = {
		...initialValues,
		cpfUsuario: values?.cpfUsuario || initialValues.cpfUsuario,
		motivoJustificativa: null,
		justificativa: null,
		codLocalidade: values?.codLocalidade || initialValues.codLocalidade,
		situacao: 'SEM_DADOS' as 'SEM_DADOS',
		descricaoServico: values?.descricaoServico || '',
		atendimento: {
			idCidadao: null,
			idAtendimento: null,
			cpf: null,
			msg: errorMessages.toString(),
		},
		orgao: values?.orgao || initialValues.orgao,
		userIdLogin: Number(userIdLogin) || 0,
	};

	return formattedData;
}

export function treatValues(
	values: DataArrayProps | null,
	userIdLogin: number | string,
): SalvarAtendimentoRequest {
	const formattedData = {
		...initialValues,
		cpfUsuario: values?.cpfUsuario || initialValues.cpfUsuario,
		motivoJustificativa: null,
		justificativa: null,
		codLocalidade: values?.codLocalidade || initialValues.codLocalidade,
		situacao: 'OK' as 'OK',
		descricaoServico:
			values?.descricaoServico || initialValues.descricaoServico,
		atendimento: {
			idCidadao:
				values?.atendimento?.idCidadao || initialValues.atendimento.idCidadao,
			idAtendimento:
				values?.atendimento?.idAtendimento ||
				initialValues.atendimento.idAtendimento,
			cpf: values?.atendimento?.cpf || initialValues.atendimento.cpf,
			msg: values?.atendimento?.msg || initialValues.atendimento.msg,
		},
		orgao: values?.orgao || initialValues.orgao,
		userIdLogin: Number(userIdLogin) || 0,
	};

	return formattedData;
}
