import { ReducerActionRotaSP } from 'store/modules/types';
import { SolicitarReconhecimentoPaternidadeRequest, Types } from './types';

export function solicitarReconhecimentoPaternidadeRequest(
	token: string,
	payload: SolicitarReconhecimentoPaternidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_REQUEST,
		token,
		payload,
	};
}
export function solicitarReconhecimentoPaternidadeSuccess(payload: object) {
	return {
		type: Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_SUCCESS,
		payload,
	};
}
export function solicitarReconhecimentoPaternidadeFailure(
	payload: object,
): any {
	return {
		type: Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_FAILURE,
		payload,
	};
}

export function solicitarReconhecimentoPaternidadeClear(): any {
	return {
		type: Types.SOLICITAR_RECONHECIMENTO_PATERNIDADE_CLEAR,
	};
}
