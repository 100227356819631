import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirOrgaoRequest } from './types';

export function excluirOrgaoRequest(
	token: string,
	data: ExcluirOrgaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_ORGAO,
		token,
		payload: data,
	};
}
export function excluirOrgaoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_ORGAO_SUCCESS,
		payload,
	};
}
export function excluirOrgaoFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_ORGAO_FAILURE,
		payload: null,
	};
}
export function excluirOrgaoClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_ORGAO_CLEAR,
		payload: null,
	};
}
