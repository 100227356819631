import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_DOMINIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirMensagemRequest } from './types';

// ACTIONS
import { excluirMensagemSuccess, excluirMensagemFailure } from './actions';

function* excluirMensagem(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ExcluirMensagemRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`mensagens/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirMensagemSuccess(response));
	} else {
		yield put(excluirMensagemFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_MENSAGEM_REQUEST, excluirMensagem),
]);
