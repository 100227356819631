import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ExcluirMenusRequest } from './types';

// ACTIONS
import { excluirMenusSuccess, excluirMenusFailure } from './action';

function* excluirMenus(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: ExcluirMenusRequest } =
		request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`menu/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirMenusSuccess(response));
	} else if (response.error) {
		yield put(excluirMenusFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_MENUS, excluirMenus)]);
