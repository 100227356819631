// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { RequestEncerrarAtendimento, Types } from './types';

export function encerrarAtendimentoRequest(
	token: string,
	payload: RequestEncerrarAtendimento,
): ReducerActionRotaSP {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_REQUEST,
		token,
		payload,
	};
}

export function encerrarAtendimentoSuccess(payload: {
	mensagemRetorno: string;
}): ReducerActionRotaSP {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}

export function encerrarAtendimentoFailure(): ReducerActionRotaSP {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_FAILURE,
		payload: null,
	};
}

export function encerrarAtendimentoClear(): ReducerActionRotaSP {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
