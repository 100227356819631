import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarPerfilRequest } from './types';

export function cadastrarPerfilRequest(
	token: string,
	data: CadastrarPerfilRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PERFIL,
		token,
		payload: data,
	};
}
export function cadastrarPerfilSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PERFIL_SUCCESS,
		payload,
	};
}
export function cadastrarPerfilFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PERFIL_FAILURE,
		payload: null,
	};
}
export function cadastrarPerfilClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_PERFIL_CLEAR,
		payload: null,
	};
}
