export enum Types {
	EXCLUIR_FUNCIONALIDADE = '@SGU/EXCLUIR_FUNCIONALIDADE',
	EXCLUIR_FUNCIONALIDADE_SUCCESS = '@SGU/EXCLUIR_FUNCIONALIDADE_SUCCESS',
	EXCLUIR_FUNCIONALIDADE_FAILURE = '@SGU/EXCLUIR_FUNCIONALIDADE_FAILURE',
	EXCLUIR_FUNCIONALIDADE_CLEAR = '@SGU/EXCLUIR_FUNCIONALIDADE_CLEAR',
}

export interface ExcluirFuncionalidade {
	status: number;
	data: null | IExcluirFuncionalidade;
}

export interface IExcluirFuncionalidade {
	status: string;
	statusMensagem: string;
	id?: number;
}

export interface ExcluirFuncionalidadeRequest {
	id?: number | string;
}
