import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarStatusRequest } from './types';

export function atualizarStatusRequest(
	token: string,
	data: AtualizarStatusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_STATUS,
		token,
		payload: data,
	};
}
export function atualizarStatusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_STATUS_SUCCESS,
		payload,
	};
}
export function atualizarStatusFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_STATUS_FAILURE,
		payload: null,
	};
}
export function atualizarStatusClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_STATUS_CLEAR,
		payload: null,
	};
}
