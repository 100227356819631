import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarParametroLocalidadeRequest } from './types';

// ACTIONS
import {
	cadastrarParametroLocalidadeSuccess,
	cadastrarParametroLocalidadeFailure,
} from './actions';

function* cadastrarParametroLocalidade(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarParametroLocalidadeRequest } = request;

	const body = {
		...payload,
	};

	delete body.id;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`parametros-localidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarParametroLocalidadeSuccess(response));
	} else {
		yield put(cadastrarParametroLocalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.CADASTRAR_PARAMETRO_LOCALIDADE,
		cadastrarParametroLocalidade,
	),
]);
