import { ReducerActionRotaSP } from 'store/modules/types';

import {
	Types,
	ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
} from './types';

export function excluirFuncionalidadesRelacionadasLocalidadeRequest(
	token: string,
	data: ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE,
		token,
		payload: data,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_CLEAR,
		payload: null,
	};
}
