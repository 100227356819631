// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_PARAMETROS_SISTEMA = '@SGU/CONSULTAR_PARAMETROS_SISTEMA',
	CONSULTAR_PARAMETROS_SISTEMA_SUCCESS = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_SUCCESS',
	CONSULTAR_PARAMETROS_SISTEMA_FAILURE = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_FAILURE',
	CONSULTAR_PARAMETROS_SISTEMA_CLEAR = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_CLEAR',
}

export interface ConsultarParametrosSistema {
	status: number;
	data: ParametrosSistemaData | null;
	totalRegistrosConsulta: number;
}

export interface ParametrosSistemaData {
	id?: number | string;
	sigla: string;
	descricao: string;
	valor: string;
}

export interface ConsultarParametrosSistemaRequest {
	registroInicial?: number;
	limite?: number;
	page?: number;
	size?: number;
	current?: number;
	id?: number | string;
	sigla?: string;
	descricao?: string;
	valor?: string;
}
