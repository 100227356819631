import produce from 'immer';

import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, ConsultarStatus } from './types';
import { ReducerActionRotaSP } from '../../../../../types';

export const INITIAL_STATE: ConsultarStatus = {
	status: 0,
	data: null,
	totalRegistrosConsulta: 0,
	enum: [],
};

export default function consultarStatusReducer(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): ConsultarStatus {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_STATUS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_STATUS_SUCCESS: {
				draft.enum = toSelect(action.payload.data, 'descricao', 'id');
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta = action.payload.headers['x-total-count'];
				break;
			}

			case Types.CONSULTAR_STATUS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_STATUS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
