export enum Types {
	CONSULTAR_MENU = '@sgu/CONSULTAR_MENU',
	CONSULTAR_MENU_SUCCESS = '@sgu/CONSULTAR_MENU_SUCCESS',
	CONSULTAR_MENU_FAILURE = '@sgu/CONSULTAR_MENU_FAILURE',
	CONSULTAR_MENU_CLEAR = '@sgu/CONSULTAR_MENU_CLEAR',
}

export interface ConsultarMenu {
	status: number;
	data: null | MenuData;
}

interface MenuData {
	id: number;
	descricao: string;
	nrOrdem: number;
	siglaMenu: string;
	orgao: {
		id: number;
		descricao: string;
	};
	localidadeMenu: LocalidadeMenu[];
}

export interface LocalidadeMenu {
	idLocalidadeMenu: number;
	dtCadastro: string;
	idUsuarioCadastro: number;
	localidade: Localidades;
}

export interface RequestConsultarMenu {
	id: number;
}

export interface Localidades {
	id: number;
	codigo: number;
	nome: string;
	tipo: string;
}
