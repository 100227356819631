// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { RequestEmissaoAtestados, EmissaoAtestados, Types } from './types';

export function emissaoAtestadosRequest(
	payload: RequestEmissaoAtestados,
): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADOS_REQUEST,
		payload,
	};
}

export function emissaoAtestadosSuccess(
	payload: EmissaoAtestados,
): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADOS_SUCCESS,
		payload,
	};
}

export function emissaoAtestadosFailure(): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADOS_FAILURE,
		payload: null,
	};
}

export function emissaoAtestadosClear(): ReducerActionRotaSP {
	return {
		type: Types.EMISSAO_ATESTADOS_CLEAR,
		payload: null,
	};
}
