import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarMotivosRequest, Types } from './types';

// ACTIONS
import { consultarMotivosSuccess, consultarMotivosFailure } from './actions';

function* consultarMotivos(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarMotivosRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMotivosRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`motivos`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarMotivosSuccess(response));
	} else {
		yield put(consultarMotivosFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_MOTIVOS, consultarMotivos)]);
