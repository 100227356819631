import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarParametrosLocalidade } from './types';

export const INITIAL_STATE: ConsultarParametrosLocalidade = {
	status: 0,
	data: null,
	enum: [],
	totalRegistrosConsulta: 0,
};

export default function consultarParametrosLocalidadeReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarParametrosLocalidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_PARAMETROS_LOCALIDADE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_LOCALIDADE_SUCCESS: {
				draft.enum = toSelect(action.payload.data, 'descricao', 'codigo');
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}

			case Types.CONSULTAR_PARAMETROS_LOCALIDADE_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_LOCALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.enum = INITIAL_STATE.enum;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
