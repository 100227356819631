import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarLocalidadesRequest, Types } from './types';

export function consultarLocalidadesRequest(
	token: string,
	payload: ConsultarLocalidadesRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_REQUEST,
		token,
		payload,
	};
}
export function consultarLocalidadesSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_SUCCESS,
		payload,
	};
}
export function consultarLocalidadesFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadesClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_LOCALIDADES_CLEAR,
		payload: null,
	};
}
