import { ReducerActionRotaSP } from 'store/modules/types';

import { RequestConsultarMenu, Types } from './types';

export function consultarMenuRequest(
	token: string,
	payload: RequestConsultarMenu,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENU,
		token,
		payload,
	};
}
export function consultarMenuSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENU_SUCCESS,
		payload,
	};
}
export function consultarMenuFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENU_FAILURE,
		payload: null,
	};
}
export function consultarMenuClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MENU_CLEAR,
		payload: null,
	};
}
