export enum Types {
	EMISSAO_ATESTADOS_REQUEST = '@aac/EMISSAO_ATESTADOS_REQUEST',
	EMISSAO_ATESTADOS_SUCCESS = '@aac/EMISSAO_ATESTADOS_SUCCESS',
	EMISSAO_ATESTADOS_FAILURE = '@aac/EMISSAO_ATESTADOS_FAILURE',
	EMISSAO_ATESTADOS_CLEAR = '@aac/EMISSAO_ATESTADOS_CLEAR',
}

export interface emissaoAtestados {
	status: number;
	data: null | EmissaoAtestados;
}

export interface EmissaoAtestados {
	codigo: string;
	mensagem: string;
	nomeSocial: string;
	pdfBase64: string;
	atestadoEmitido: boolean;
	bairro: string;
	celular: string;
	cep: string;
	codigoRetorno: string;
	complementoEndereco: string;
	dataGeracao: string;
	endereco: string;
	mensagemRetorno: string;
	municipio: string;
	numEndereco: number;
	pagto2Via: string;
}

export type RequestEmissaoAtestados = {
	nome: string;
	nomeMae: string;
	nomePai: string;
	numeroRg?: string;
	digitoRg?: string;
	dataNascimento: string;
	sexo: string;
	resultado: string;
	codigoValidacao: string;
	codigoRetorno: string;
	tipoLiberacao: string;
	origem: string;
	tipoDocApresentado: string;
	numeroDocumento?: string;
	cpf?: string;
	dcCpf?: string;
	positivaExecucao?: string;
	positivaInicio?: string;
	positivaTermino?: string;
	positivasObservacao?: string[];
	positivaRegime?: string;
	exigenciaBODelegacia?: string;
	exigenciaBO?: boolean;
	exigenciaBONumero?: string;
	exigenciaBaixaDesaparecido?: boolean;
	exigenciaCertidaoCriminais?: boolean;
	exigenciasCertidaoCriminais?: string[];
	exigenciaCertidaoDistribuidor?: boolean;
	exigenciasCertidaoDistribuidor?: string[];
	exigenciaCertidaoObjeto?: boolean;
	exigenciaCopiaApresentado?: boolean;
	exigenciasDelegacia?: string[];
	exigenciasInstauracao?: string[];
	exigenciaObservacao?: boolean;
	exigenciasObservacao?: string[];
	exigenciasProcesso?: string[];
	exigenciasVara?: string[];
	exigenciaEvadido?: boolean;
	exigenciasIP?: string[];
	exigenciaPreso?: boolean;
	exigenciaProcurado?: boolean;
	id?: string;
	tipoAA?: string;
	status?: string;
	usuarioCentral?: string;
};
