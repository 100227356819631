import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirStatusRequest } from './types';

export function excluirStatusRequest(
	token: string,
	data: ExcluirStatusRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_STATUS,
		token,
		payload: data,
	};
}
export function excluirStatusSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_STATUS_SUCCESS,
		payload,
	};
}
export function excluirStatusFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_STATUS_FAILURE,
		payload: null,
	};
}
export function excluirStatusClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_STATUS_CLEAR,
		payload: null,
	};
}
