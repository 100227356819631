// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	RequestConsultaUsuarioPendente,
	ConsultaUsuarioPendente,
	Types,
} from './types';

export function consultaUsuarioPendenteRequest(
	token: string,
	payload: RequestConsultaUsuarioPendente,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE,
		token,
		payload: { usuario: payload.identifUsuario, posto: payload.numeroPosto },
	};
}

export function consultaUsuarioPendenteSuccess(
	payload: ConsultaUsuarioPendente,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE_SUCCESS,
		payload,
	};
}

export function consultaUsuarioPendenteFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE_FAILURE,
		payload: null,
	};
}

export function limpaUsuarioPendente(): ReducerActionRotaSP {
	return {
		type: Types.LIMPA_USUARIO_PENDENTE,
		payload: null,
	};
}
