import produce from 'immer';

// TYPES
import { Types, ConsultarMensagens } from './types';
import { ReducerAction } from '../../../../../../types';

export const INITIAL_STATE: ConsultarMensagens = {
	status: 0,
	data: null,
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarMensagens {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MENSAGENS_REQUEST: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.CONSULTAR_MENSAGENS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}

			case Types.CONSULTAR_MENSAGENS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_MENSAGENS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
