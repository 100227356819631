import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EstatisticasState } from './types';

import incluirEstatisticas from './incluir/reducer';

const allReducers = combineReducers({
	incluirEstatisticas,
});

const combineReducer = (state: EstatisticasState, action: ReducerAction) => {
	if (action.type === Types.ESTATISTICAS_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
