import produce from 'immer';
// import { ReducerAction } from 'store/modules/types';

// TYPES
// import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarParametrosSistema } from './types';
import { ReducerAction } from '../../../../../../types';

export const INITIAL_STATE: ConsultarParametrosSistema = {
	status: 0,
	data: null,
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarParametrosSistema {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_PARAMETROS_SISTEMA: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
